.connectionForm {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
	.ant-input-affix-wrapper .ant-input-suffix {
		right: 22px;
	}
	.ant-input-affix-wrapper .ant-input {
		height: 28px;
		border: 0px;
		border-radius: 0px;
		box-shadow: none;
	}
}

.connection-modal-subtitle {
	display: flex;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 14px;
	color: #c7c7cd;
}

.addEditConnectionModal {
	:global(.modal__header) {
		padding: 16px 16px 0px 24px;
		background-color: #3e4161 !important;
		text-align: start;
		position: relative;
	}
	:global(.modal__body) {
		background-color: #3e4161;
		font-weight: 500;
		font-size: 12px;
	}
	:global(.modal__title) {
		font-weight: 700;
		font-size: 16px;
	}
}

.delConnectionTitleContainer {
	display: flex;
	flex-direction: column;
}

.delConnectionTitle {
	margin-top: 12px;
	font-weight: 500px;
	font-size: 12px;
}

.delConnectionSubTitle {
	margin-top: 8px;
	font-weight: 500px;
	font-size: 12px;
	color: "#C7C7CD";
}
.addEditConnectionModal__innerContainer {
	padding: 16px 24px;
	color: white;
	width: 552px;
}

.addEditConnectionModal__actionBtns {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 24px;
}

.connection-delete-btn {
	min-width: 96px;
	height: 26px;
}

._connection-delete-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.auto {
	width: auto !important;
	height: auto !important;
}

.connection-edit-btn {
	min-width: 96px;
	height: 26px;
}

.connection-cancel-btn {
	min-width: 96px;
	height: 26px;
}

.deleteConnectionModal {
	:global(.modal__header) {
		padding: 16px 16px 0px 24px;
		background-color: #3e4161;
		text-align: start;
		position: relative;
	}
	:global(.modal__body) {
		background-color: #3e4161;
		font-weight: 500;
		font-size: 12px;
	}
	:global(.modal__title) {
		font-weight: 700;
		font-size: 16px;
	}
	:global(.modalBtns__box) {
		padding: 16px 32px 0px;
	}
}
