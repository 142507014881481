.profile{
    margin-top: var(--header-margin);
    line-height: 100%;
    width: calc(100% - var(--sidebar-width));
    height: calc(100vh - 55px);
    background: #030613;

    .profile-row{
        height: 100%;

        .buttons{
            display: flex;
            justify-content: flex-end;

            .ant-btn{
                margin-left: 10px;
            }
        }
    }
}