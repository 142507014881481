.fileBrowser__container {
	display: flex;
	color: #ffffff;
	font-size: 12px;
	height: 100vh;
	overflow: hidden;
	.left_container {
		width: 345px;
		padding: 32px 12px;
		background-color: #212233;
	}
	.right_container {
		width: 100%;
		padding: 24px;
		background-color: #3e4161;
		margin:0;
	}
	.page_left_title {
		color: #ffffff;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin-bottom: 4px;
	}
	.page_right_title {
		@extend .page_left_title;
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
	}
	.subtitle {
		color: #c7c7cd;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}
	.page_right_title_header {
		display: flex;
	}
	.newBtn__box {
		display: flex;
		flex-direction: row-reverse;
		padding-top: 16px;
	}
	.list_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.list_item_avatar {
		width: 48px;
		display: flex;
		justify-content: center;
	}
	.file_container {
		padding-top: 16px;
		height: 100%;
	}

	.create-new-connection-text {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 2em;
		text-decoration: underline;
		color: #fcad01;
	}
	.no-active-connection-text {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 2em;
		color: #fcad01;
	}
	.more_icon {
		margin-left: 18.5px;
		width: 24px;
		height: 24px;
		&:hover {
			background: #3e4161;
			border-radius: 4px;
		}
	}

	.ant-checkbox-inner{
		background: #3e4161;;
		border: 1px solid #c7c7cd;
	}
	
	.ant-checkbox-checked .ant-checkbox-inner {
		background: #fcad01;
		border: 1px solid #fcad01;
	
		&::after{
			border-color: #3e4161;;
		}
	}
	
	.ant-checkbox-indeterminate .ant-checkbox-inner::after{
		background-color: #fcad01;
	}
}

.list_container {
	padding-top: 12px;
	width: 321px;
	height: 80vh;
	overflow-y: auto;
	overflow-x: hidden;
	.ant-list-split .ant-list-item {
		margin-top: 8px;
		padding: 8px;
		border-bottom: none;
	}
	
	.ant-list-item-meta-title {
		margin-bottom: 4px;
		color: #ffffff;
		max-width: 122px;
		word-wrap: break-word;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
	}
	
	.ant-list-item-meta-description {
		color: #c7c7cd;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
	}
	.ant-list-empty-text {
		display: none;
	}
}

.secure_input_field{
	.ant-input-affix-wrapper .ant-input-suffix {
		right: 8px;
	}
	.ant-input-affix-wrapper .ant-input {
		height: 28px;
		border: 0px;
		border-radius: 0px;
		box-shadow: none;
	}
}

.more-action-container{
	.ant-dropdown-menu {
		background: #0f121f;
		padding: 8px;
		border: 1px solid rgba(255, 255, 255, 0.32);
		border-radius: 4px;
	}
	.ant-dropdown-menu-item {
		font-weight: 500;
		font-size: 12px;
		color: #ffffff;
		padding-left: 8px;
	}
	.ant-dropdown-menu-item-divider {
		height: 1px;
		width: 173px;
		margin: 8px 10px 8px;
		overflow: hidden;
		line-height: 0;
		background: rgba(255, 255, 255, 0.08);
	}
}

.clusterDropdown {
	width: 150px;
}

.in_center{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}