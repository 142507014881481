// .DataTransformationAzure svg {
//     width: 16px;
//     height: 16px;
// }

// .DataTransformationGoogleCloud svg {
//     width: 16px;
//     height: 16px;
// }

// .DataTransformationapis svg {width: 16px;height: 16px;}
// .DataTransformationAws svg {width: 16px;height: 16px;}
// .DataTransformationAzure svg {width: 16px;height: 16px;}
// .DataTransformationGoogleCloud svg {width: 16px;height: 16px;}
// .DataTransformationsap svg {width: 16px;height: 16px;}
// .DataTransformationfile_format_conversion svg {width: 16px;height: 16px;}
// .DataTransformationfiledataframes svg {width: 16px;height: 16px;}
// .DataTransformationfileMatch svg {width: 16px;height: 16px;}
// .DataTransformationTimeseries_DB svg {width: 16px;height: 16px;}
// .DataTransformationOther svg {width: 16px;height: 16px;}
// .DataTransformationCheckpointing svg {width: 16px;height: 16px;}
// .DataTransformationRelationalSources svg {width: 16px;height: 16px;}
// .DataTransformationPartition svg {width: 16px;height: 16px;}
// .DataTransformationcdc svg {width: 16px;height: 16px;}
// .DataTransformationcdc svg {width: 16px;height: 16px;}
// .DataTransformationNoSQL svg {width: 16px;height: 16px;}
// .DataTransformationHadoop svg {width: 16px;height: 16px;}
// .DataTransformationTransform svg {width: 16px;height: 16px;}
// .DataTransformationdatabricks svg {width: 16px;height: 16px;}
// .DataTransformationFiles svg {width: 16px;height: 16px;}
// .StructuredAnalyticsImputation svg {width: 16px;height: 16px;}
// .StructuredAnalyticsdimensionality_reduction svg {width: 16px;height: 16px;}
// .StructuredAnalyticscustom_model svg {width: 16px;height: 16px;}
// .StructuredAnalyticsStatistics svg {width: 16px;height: 16px;}
// .StructuredAnalyticsCorrelation svg {width: 16px;height: 16px;}
// .StructuredAnalyticsClustering svg {width: 16px;height: 16px;}
// .StructuredAnalyticsRegression svg {width: 16px;height: 16px;}
// .StructuredAnalyticsOptimizers svg {width: 16px;height: 16px;}
// .TimeSeriesAnalyticsPatternSearch svg {width: 16px;height: 16px;}
// .TimeSeriesAnalyticsOutlierDetection svg {width: 16px;height: 16px;}
// .TimeSeriesAnalyticsSmoothData1 svg {width: 16px;height: 16px;}
// .TimeSeriesAnalyticsForecast svg {width: 16px;height: 16px;}
// .UnstructuredAnalyticsTextAnalytics svg {width: 16px;height: 16px;}
// .StreamingAnalytics svg {width: 16px;height: 16px;}
// .Custom_Component svg {width: 16px;height: 16px;}


.compoennt_icon_cls svg {
    width: 16px;height: 16px;
}

.content-through-tooltip {
    pointer-events: none;
}
