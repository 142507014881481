.workspaceForm {
	display: flex;
	flex-direction: column;
	row-gap: 12px;
}

.workspace-modal-subtitle {
	display: flex;
	justify-content: flex-start;
	font-weight: 500;
	font-size: 14px;
	color: #c7c7cd;
}

.addEditWorkspaceModal_ {
	:global(.modal__header) {
		padding: 16px 16px 0px 24px;
		padding-bottom: 12px;
		background-color: #3e4161;
		text-align: start;
		position: relative;
	}
	:global(.modal__body) {
		padding: 0px;
		background-color: #3e4161;
		font-weight: 500;
		font-size: 12px;
	}
	:global(.modal__title) {
		font-weight: 700;
		font-size: 16px;
	}
}

.addEditWorkspaceModal__innerContainer {
	padding: 16px 24px;
	color: white;
	width: 552px;
}

.addEditWorkspaceModal__actionBtns {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 24px;
}

.auto {
	width: auto !important;
	height: auto !important;
}

.workspace-edit-btn {
	min-width: 96px;
	height: 26px;
}

.workspace-cancel-btn {
	min-width: 96px;
	height: 26px;
}
