.table_metadata {
    .ant-table-row:nth-child(even) td{
        background: #212233;
    }
    .ant-table-row:nth-child(odd) td{
        background: #14182A;
    }
    input {
        background-color: #000000 !important;
    }
    .monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
        background: #000000 !important;
    }
    .ant-table-container, .ant-table-wrapper{
        background: #14182A;
    }
}