// nodes: z-index 1
// svg box: z-index 0 

.ds__node {
	background-color: #3a3f54;
	border-radius: 4px;
	min-height: 64px;
	min-width: 166px;
	font-weight: 600;
	position: relative;
	border: 2px solid transparent;
	// transition: border 0.25s;
	width: intrinsic; /* Safari/WebKit uses a non-standard name */
	width: -moz-max-content; /* Firefox/Gecko */
	width: -webkit-max-content; /* Chrome */
	width: max-content;
	z-index: 1;

	.show {
		display: block;
	}

	&.ds__node__increaseHeight{
		min-height: 80px;

		.ds__node__info__container{
			height: 80px;
		}

		.ds__node__port{
			top: 32px;
		}

		.svg__externalPortsBox{
			// top: 80px;
			// transform: translate(-82px, -81px);
			// height: 80px;
			transform: translate(-82px, -98px);
			height: 115px;
		}

		.ds__node__ports--out .svg__externalPortsBox{
			transform: translate(82px, -98px) rotate(180deg);
		}

		.offsetPath__duringIncreasedHeight path{
			transform: translate(0, 8px);
		}
	}

	

	&:hover{
		border-color: #fcad01;

		.ds__node__port{
			background-color: #D4D6E0;

			&:before{
				opacity: 1;
				height: 20px;
			}
			&.ds__node__port--dummy  {
				background-color: #9495A0;
			}

			// &.port__selected {
			// 	background-color: #fcad01;
			// }
		}
	}

	&.ds__node--analytics{
		background-color: #1f2235 !important;

	}

	&.ds__node__invalidProps{

		&:hover{
			border-color: #FF3D4F;

			.ds__node__port:before{
				border-color: #FF3D4F;
			}
		}

		&.ds__node--active {
			border-color: #FF3D4F;
			box-shadow: 1px 1px 32px 0 #FF1046;

			.ds__node__port:before{
				border-color: #FF3D4F;
			}

		}
	}	
	
	.invalidPropsIcon{
		cursor: default;
		position: absolute;
		top: 2px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		right: 2px;
		z-index: 1;
		text-align: center;
		display: none;

		svg {
			width: 10px;
			height: 10px;
			position: absolute;
			top: 3.5px;
			right: 3.5px;
		}
		

		&.invalidPropsIcon--invalid{
			display: block;
			background-color: #FFE2D8;

			#Combined-Shape{
				fill: #B01900;
			}

			svg{
				right: 4px;
			}
		}

		&.invalidPropsIcon--invalid.invalidPropsIcon--unTouched{
			display: block;
			background-color: #1E2038;

			#Combined-Shape{
				fill: #FFE2D8;
			}
		}
	}


	&.ds__node__analyticsPage{
		&:hover, &.ds__node--active{
			box-shadow: none;
			background-color: #055d91;

			.ds__node__info__container{
				opacity: 0.1;
			}

			.selectToViewMsg{
				display: flex;
			}

		}

		&.ds__node__selectedComponentForAnalytics{
			border-color: rgba(5, 43, 84, 0.5);

			.selectedComponentForAnalytics{
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgb(0, 51, 102);
				opacity: 0.72;
				z-index: 1;
			}

			.line1 {
				margin-bottom: 6px;
				letter-spacing: 0.18px;
			}

			.line2{
				letter-spacing: 0.18px;
				text-decoration: underline;
			}

			.ds__node__port:before{
				border-color: transparent;
			}

			// .ds__node__port{
			// 	// background-color: rgba(5, 43, 84, 0.5);
			// 	border-color: rgb(31, 53, 84);
			// }

			.ds__node__ports--in, .ds__node__ports--out {
				.ds__node__port.port__connected:first-child{
					border-color: #14182a;

				}

				.ds__node__port.ds__node__port--dummy {
					border-color: #14182a;
				}
			}

			&:hover{
				box-shadow: none;
				border-color: rgba(0, 97, 152, 0.90);
				opacity: 1;

				.selectedComponentForAnalytics{
					opacity: 1;
					background-color: rgba(0, 97, 152, 0.90);
				}

				.selectToViewMsg{
					display: none;

					&.addPlotMsg{
						display: flex;
						flex-direction: column;
					}
				}


			}
		}

		&.ds__node__plottingInProgressSpinner{
			border-color: #2ea3c5;

			.selectToViewMsg{
				display: none;
			}

			.ds__node__ports--in, .ds__node__ports--out {
				.ds__node__port:first-child{
					background-color: #6d707e;;

					&.port__connected {
						border-color: #2ea3c5;
						background-color: rgba(255, 255, 255, 0.7);
					}


					

					.port__innerDot{
						opacity: 0.5;
					}
				}

				.ds__node__port.ds__node__port--dummy {
					border-color: #2ea3c5;
				}
			}

			.ds__node__info__container{
				opacity: 1;
			}

			.ds__node__port:before{
				border-color: transparent;
			}

			.ds__node__port.ds__node__port--dummy{
				background-color: rgba(255, 255, 255, 0.7);
			}
		}

		.plottingInProgressSpinner{
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: #2ea3c5;
			opacity: 0.9000000357627869;
			box-shadow: 1px 1px 32px 0 #00AED4;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.ds__node__disabledForAnalytics{
			// opacity: 0.2;
			cursor: default;
			background-color: #1e2038;
			.ds__node__info__innercontainer{
				opacity: 0.5;
			}

			&:hover{
				border-color: #003366;

				.ds__node__port:before {
					opacity: 0;
					// height: 20px;
				}

				.selectToViewMsg{
					display: flex;
					justify-content: center;
					color: rgba(255, 255, 255, 0.7);
					background-color: #003366;
					height: 100%;
					padding-left: 15px;
				}
			}
			
		}
	}

	&.ds_node__previewDisabled {
		&:hover, &.ds__node--active{
			box-shadow: none;
			background-color: #3A3F54;
			border-color: transparent;

			.ds__node__info__container{
				opacity: 0.1;
			}

			.selectToViewMsg{
				display: flex;
			}

			.previewDisabledMessage{
				flex-direction: column;

				svg{
					margin-bottom: 4px;
				}
			}


			.ds__node__port:before{
				border-color: transparent;
			}
		}
	}



	
}

.ds__node.ds__node--active {
	background-color: #3a3f54;
	border-color: #fcad01;
	border-radius: 4px;
	box-shadow: 1px 1px 32px 0 #fcad01;
	z-index: 2;

	.ds__node__port {
		background-color: #D4D6E0;

		&:before{
			opacity: 1;
			height: 20px;
		}

		&.ds__node__port--dummy  {
			background-color: #9495A0;
		}
	}

	.port__bg{
		border-color: #3a3f54;
		border-left: 10px solid #3a3f54;
		border-radius: 10px;
		left: -12px;
	  }
	  
	  
}

.ds__node.ds__node--options-dropdown-visible {
	z-index: 3;
}

.ds__node__info__container,
.ds__node__info__innercontainer {
	display: flex;
	align-items: center;
	// transition: all 0.5s;
	height: 64px;
}

.ds__node__info__innercontainer{
	& > svg {
		height: 30px;
		width: 30px;
		margin-right: 10px;
	}


}

.ds__node__title {
	font-size: 14px;
	letter-spacing: 0.25px;

	margin-bottom: 4px;
	line-height: 21px;

	.inputfield{
		// background: rgba(58, 63, 84, 0.1);
		border-color: #FCAD01;
	}

	span{
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 180px;
	}
}

.ds__node__subtitle {
	font-size: 12px;
	letter-spacing: 0.25px;
	line-height: 18px;
	display: flex;
	color: #9495A0;
	font-weight: 600;

	.portMgmt__icon{
		display: flex;
		margin-left: 5px;
		align-items: center;
		position: relative;
		cursor: default;
		// padding-top: 1px;
		span {
			position: absolute;
			bottom: 3px;

		}
		svg {
			margin-left: 4px;
		}
	}
	
}



.ds__node__ports--out{
	.ds__node__port{
		left: auto;
		right: -8px;

		&:before{
			transform: translate(-9px,0) scale(-1, 1);
		}

		&.port__connected:before{
			transform: translate(1px, 0);
			width: 10px;
		}
	}

	.svg__externalPortsBox{
		width: 100%;
		// 50px + 2px accounts for the border width
		transform: translate(82px, -64px) rotate(180deg);
	}
}


.ds__node__port {
	--portscale: 1;
	position: absolute;
	// 32px half of component height - 8px half of height
	left: -8px;
	top: 24px;
	// border: 10px solid #030613;
	border-radius: 100%;
	width: 16px;
	height: 16px;
	background-color: #6D707E;
	transition: background-color 0.25s, transform 0.25s ease-in-out;
	border: 4px solid;
	border-color: #030613;
	border-radius: 100%; 
	z-index: 1;

	&:hover{
		--portscale: 1.6;
		opacity: 0.76;
	}


	&.ds__node__two__ports:first-child{
		top: 16px;
	}

	&.ds__node__two__ports:last-child{
		top: 50px;
	}

	// .port__innerDot {
	// 	background-color: #6D707E;
	// 	border-radius: 100%;
	// 	width: 8px;
	// 	height: 8px;
	// 	position: absolute;
	// 	right: 4px;
	// 	top: 4px;
	// 	transition: border-color 4s linear;
	// }

	&.port__connected .port__innerDot{
		background-color: #ffffff;
	}

	&.ds__node__port--dummy  {
		background-color: #2A2C42;
	}

	&.multiplePorts{
		// transform: scale(0.65);
		width: 12px;
		height: 12px;
		background-color: #6D707E;
		border: 2px solid #232531;
		border-radius: 100%;
		z-index: 1;

		&:before{
			content: none;
		}

		&:hover{
			--portscale: 2;
		}
	}

	&.ds__node__variablePort{
		border-color: #6E88FF;
		border-radius: 0%;
		border-width: 2.5px;

		&:not(.multiplePorts) {
			left: -9px;
		}
		
		&.port__connected{
			border-color: #6E88FF;
		}

		&::before{
			content: none;
		}

		&:hover{
			--portscale: 1.3;
		}
		// transform: ;
	}

	&.port__connected{
		background-color: #ffffff;
		border-color: #3A3F54;

		&:hover{
			background-color: #ffffff;
		}

		&:before{
			transform: scale(-1, 1) translate(9px, 0);
			width: 10px;
		}
	}

	&:before{
		// transition: opacity 0.25s;
		opacity: 0;
		content: '';
		position: absolute;
		top: -6.5px;
		left: 3px;
		width: 11px;
		height: 20px;
		// border-radius: 50%;
		border: 2px solid #fcad01;
		border-top-right-radius:11px;
		border-bottom-right-radius: 11px;
		border-left: 0;
	}

	&.port__selected{
		background-color: #fcad01 !important;
	}


	&.portDisabled__preview {
		background-color: #252835 !important;
		border-color: #030613;
	}

}


.svg__externalPortsBox{
	// 50px + 2px accounts for the border width
	// top: 64px;	
	transform: translate(-82px, -64px);
	cursor: default;
	position: absolute; 
	width: 80px;
	height: 64px; 
	z-index: 0;  
	opacity: 0.72;

	&.svg__portsCircle{
		width: 94px;

		circle{
			fill: transparent
		}
	}
}



.ds__node__options__toggle {
	margin-left: 12px;
	background-color: transparent;
	padding: 0 7.5px;

	&:focus {
		outline: none;
	}
}
.apexcharts-toolbar {
	display: none !important;
}

.ds__node__analytics__header{
	padding: 15px 20px;
	background-color: #14182A;
	// margin: -1px 0;
}

.ds__node__analyticsHeader__RHS{
	float: right;
	display: flex;
	column-gap: 8px;
}

#btnNodePreview{
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		margin-right: 4px;
	}
}

.ds__node__analytics {
	padding: 12px 20px;
	background-color: #2a2c42;
	cursor: default;

	& > div {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.noDataMessage__streamingPreview{
		font-weight: normal;
		font-size: 14px;
	}
}

.ds_node__runUntilHereText{
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	height: 26px;
	line-height: 26px;
	color: white;
	position: absolute;
	background-color:  #007DB1;
	// padding: 3px 6px;
	padding: 0 6px;
	border-radius: 2px;
	right: 0px;
	top: -24px;
	z-index: -1;
}


.eye__div {
	color: #ffffff;
	font-size: 10px;
	font-weight: 500;
	line-height: 33px;
	opacity: 0.7199999690055847;
	margin-right: 8px;
	display: flex;
	margin-top: 2px;

	& img {
		margin-right: 5px;
	}
}
.ds__node__graph__title {
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.2px;
	line-height: 19px;
	width: 70px;
}

.ds__node__graph__dropdown__box {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	.select-field {
		margin-bottom: 0 !important;
	}
}

.activeOutputSelectionField{
	position: absolute;
	left: 110px;
	top: 73px;
	
	.select-field-box .select-field-value{
		border-color: green;
	}
}


.css-11pm632 {
	stroke: #ffffff;
}

.ds__node__icon {
	width: 24px;
	line-height: 20px;
	text-align: center;
	margin-right: 10px;
}


.ds__node__info__container{
	padding: 0 10px;
	justify-content: space-between;
	position: relative;


	&.ds__node--extraPadding{
		padding-left: 14px;
	}

	.dropdown__master{
		align-items: center;
		display: flex;
		z-index: 1;
	}

    .dropdown__menu{
        padding: 6px 0;
        width: 105px;
		color: #FFFFFF;
		font-size: 11px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 12px;
        left: auto;
		right: 7px;
		top: 45px;
		z-index: 20000;

    }

    .dropdown__menu__item{
        padding: 6px 0;
		height: auto;
        min-height: 24px;
		text-align: center;
		

        &:hover{
            background-color: #FCAD01;
			color: #030613;
			font-weight: 400;
			letter-spacing: 0.25px;
        }
    }

    .dropdown__menu__item:nth-child(11){
        color: #FF3E3E;
    }
    
}

.ds__node__preview__status{
	height: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	position: absolute;
	top: -2px;
	left: -2px;
	background-color: transparent;
	transition: width 2s linear;
	opacity: 0;
	width: 0px;

	.showProgress {
		display: none;
	}

	.progress__box, .progress {
		width: 100%;
		display: block;     
		height: 100%;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}


	@mixin gradient-striped($color: rgba(3, 6, 19, 0.4), $angle: 135deg) {
		background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
		background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
		background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
		transition: background-size 4s linear;

	  }
	  
	@keyframes progress-bar-stripes {
	from  { background-position: 0px 0; }
	to    { background-position: 10px 0; }
	}

	&.status--initialized{
		background-color: #D4D6E0;
		// width: calc(100% + 4px);
		transition: none;
		transition: opacity 0.5s linear;

	}

	&.status--running {
		background-color: #D09C30;
		// transition: background-color 2s;
		transition: width 2s linear 2s;

		.progress{
			@include gradient-striped;
			animation: progress-bar-stripes 0.5s linear infinite;
			background-size: 10px 10px;
		}
	}

	&.status--success {
		background-color: #8FFF73;

		.progress{
			// @include gradient-striped;
			background-size: 10px 10px;
			transition: background-size 2s;
		}

		.removeGradient--success{
			background-size: 0px;
		}

	}

	

	&.status--failed{
		background-color: #FF3D4F;
		transition: none;
		transition: opacity 0.5s linear;
					
	}

	&.active{
		opacity: 1;
		width: calc(100% + 4px);
	}

}



.ds__node.progress--success {
    .ds__node__port__whitedot{
        border-color: #8FFF73;
    }
}

.componentLink__outer {
	pointer-events: all;

	&:hover .componentLink{
		stroke: #FCAD01;
	}

	&.unConnectedLink {
		.componentLink {
			stroke: #FF3D4F;
		}

		&:hover .componentLink{
			stroke: #FCAD01;
		}
	} 

	circle{
		filter: url(#circleShadow);
	}
}


.componentLink{
    stroke : white;
	transition: stroke 0.25s linear;
	fill: none;
	pointer-events: all;

	&.dottedLink {
		stroke-dasharray: 2px;
	}

	&:hover{
		stroke: #FCAD01;
	}

	@keyframes linkHoverAnimation{
		from {
			stroke-dashoffset: 24;
			stroke: rgb(0, 192, 255);
		}
		to {
			stroke-dashoffset: 0;
			stroke: rgb(0, 192, 255);
		}
	}

	&.selected{
		// stroke-dasharray: 10, 2;
		// animation: linkHoverAnimation 1s linear infinite;
		stroke: #FCAD01;
		stroke-width: 4px;
		filter: drop-shadow(0 0 16px 0 #FCAD01);

	}

    

    &.success{
        stroke: #8FFF73;
    }

	&.streamingLink{
		stroke: #C7C7CD;
	}

	&.steamingPreview{
		stroke: #6D707E;
	}

	&.previewDisabled{
		stroke: #2A2C42;
	}
}

.componentLink__dummy{
	pointer-events: all;
	// stroke: rgb(0, 192, 255);
	opacity: 0;

	&.selected{
		opacity: 0.1;
		stroke-width: 5px;
	}
	

}

.ds__node__graph__container{
	border-top: 1px solid #6D707E;
	padding: 15px 0;
	min-width: 500px;

	.ds__node__fields__box{
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		margin-bottom: 10px;

		h4{
			color: #fff;
			opacity: 0.6;
		}

		.select-field{
			margin-bottom: 0;
			// margin-right: 20px;
		}
	}


	.inputfield__container{
		width: 80%;
		margin-top: 15px;
		display: flex;
		justify-content: space-between;

		& > div{
			width: 20%;
		}
	}
}

.ds__node__fieldsList{
	border: 1px solid grey;
	list-style-type: none;
	max-width: 200px;

	li{
		cursor: pointer;
	}

	.disableDrag {
		cursor: default;
		opacity: 0.3;
		
	}
}

.Axis__box{
	border: 1px solid grey;
	max-width: 200px;
	padding: 0.3em;
	min-height: 30px;
	min-width: 100px;
}

.analyticsTable{
	// border: 1px solid #d4d6e0;
	color: #d4d6e0;
	font-size: 12px;
	min-width: 100px;
	min-height: 50px;
	max-height: fit-content;
	max-width: fit-content;
	display: block;
	overflow: auto;
	

	tr, td, th{
		border: 0.8px solid #d4d6e0;
		text-align: center;
	}

	td{
		text-align: start;
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	th, td{
		padding: 0.5em;
	}
	
}

.analyticsCustomizationOptions__box{
	display: flex;
	justify-content: space-between;

	.inputfield__container{
		display: flex;
		width: 240px;
		justify-content: space-between;

		& > div{
			display: flex;
			align-items: center;
			width: 110px;
		}

		label{
			margin-right: 8px;
			margin-bottom: 0;
		}
	}

	&.editorMode{
		flex-direction: column;
	}

	.analyticsOption{
		display: flex;
		align-items: center;
		width: 170px;
    	margin-right: 20px;
	}
}

.editor__box{
	margin-top: 20px;

	section {
		margin-left: 0;
		height: calc(100% - 40px) !important;
	}
}

.analyticsTable__header{
	color: white;
}

.summaryTable {
	.analyticsTable__header th:first-child{
		text-transform: capitalize;

	}

	tr td:first-child{
		color: white;
		text-transform: capitalize;
	}
}

.modalSummary__tables__box{
	display: flex;
	
	& > div{
	margin-right: 50px;	
	}
}
.modalSummary__graphs__box{
	clear: right;
	display: flex;
    flex-wrap: wrap;
	width: 600px;
	justify-content: center;	
	.featureImportanceGraph{
		width: 100%;
		// display: flex;
		justify-content: flex-end;

		// .echarts-for-react {
		// 	width: 100%;
		// 	display: flex;
		// 	justify-content: center;
		// }
	}
	.gaugeGraphType{
		width: 300px;
		text-align: center;
	}
}
.modelSummaryTable{
	margin-bottom: 20px;

	._keys{
		color: white;
		text-transform: capitalize;

		&._upperCase{
			text-transform: uppercase !important;
		}
	}
}

.modalSummary__btn{
	float: right;
	margin-bottom: 10px;
	text-decoration: underline;
}

.analyticsTable.captializeFirstColumn{
	tr td:first-child{
		text-transform: uppercase !important;
	}
}

.selectToViewMsg{
	display: none;
    width: 100%;
    align-items: center;
    justify-content: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	letter-spacing: 0.18px;
	line-height: 17px;
	svg {
		margin-right: 6px;
	}

	.line1, .line2{
		font-size: 14px;
		font-weight: 600;
		text-align: center;
	}
}

.ds__nodeVariable{
	width: 100px;
	height: 90px;

	#ds__nodeVariablePath{
		fill: #1E2038;
	}

	.ds__nodeVariablePortBorder {
		fill:#1e2038;
	}

	

	.ds__nodeVariable__portsBox{
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		right: 0;
		bottom: 0;
		position: absolute;
	}

	.inputfield__container{
		bottom: 27px;
		left: 25px;
		position: absolute;


		.inputfield{
			width: 50px;
			font-size: 10px;
			letter-spacing: 0.15px;
			line-height: 14px;			
			height: 14px;
			padding: 2px;
			text-align: center;
		}
	}

	.nodeVariable__title{
		font-size: 12px;
		letter-spacing: 0.15px;
		line-height: 14px;
		position: absolute;
		opacity: 0.72;
		bottom: 26px;
		left: 20px;
		width: 60px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 2px;
	}

	.ds__nodeVariable__port {
		position: absolute; 
		width: 6px;
		height: 6px; 
		background-color: #9495A0;


		border-radius: 50%;

		&.ds__nodeVariable__port--connected{
			background-color: white;
		}

		&:hover{
			transform: scale(4);
			opacity: 0.76;
		}
	}

	&:hover{
		#ds__nodeVariablePath, .ds__nodeVariablePortBorder {
			stroke-width: 2;
			stroke: #FCAD01;
		}

		.ds__nodeVariablePortBorder {
			stroke-dasharray: 17.5;
    		stroke-dashoffset: 25;
			
			&:last-of-type{
				stroke-dashoffset: 44;
				stroke-dasharray: 18;
			}
		}
	}

	&.ds__nodeVariable--selected{
		.ds__nodeVariablePortBorder {
			stroke-width: 2;
			stroke: #fcad00;
			stroke-dasharray: 17.5;
    		stroke-dashoffset: 25;
			  
			&:last-of-type{
				stroke-dashoffset: 44;
				stroke-dasharray: 18;
			}
		}


		#ds__nodeVariablePath {
			stroke-width: 2px;
			// box-shadow: 1px 1px 24px 0 #FCAD01;
			filter: url(#ds__nodeVariablePath__shadow);
			stroke: #FCAD01;
		}
	}

	.canvasContextMenu{
		width: 80px;
	}
}

#ds__nodeVariable__icon{
	position: absolute;
    left: 42px;
    top: 24px;
}

 .dropDown_Run_show_until_here {
    height: 34px !important;
 }

 .dropDown_Disable_run_show_until_here {
    height: 34px !important;
 }