.workflowAnalytics__container {
    margin-left: var(--sidebar-width);
    background-color: #030613;
    height: 100vh;
    color: #fff;
    display: flex;

    .treeview__container,
    .treeview__inner {
        background-color: #2a2c42;
    }

    .LHS__title,
    .LHS__subtitle {
        // text-align: center;
        // padding-left: 22px;
    }

    .LHS__subtitle {
        // margin-bottom: 0;
        padding-left: 20px;
    }

    .WorkflowLHS__selection__box {
        padding-top: 16px;
        padding-left: 22px;
    }

    .treeview__inner {
        overflow: visible;
    }

    .jobStatusIndicator {
        background-color: #2a2c42;
        margin: 0;
    }

    .openWorkflowMsg {
        color: #ffffff;
        font-size: 21px;
        letter-spacing: 0.33px;
        line-height: 95vh;
        opacity: 0.72;
        font-weight: 600;
        height: 100%;
        text-align: center;
    }

    .canvasGrid__outer {
        width: 100%;
        height: calc(100vh - var(--workflowtabs-height));
        // padding: 20px;
        padding-bottom: 20px;
        overflow-y: auto;

        &.fullScreenMode{
            overflow-y: hidden;

            .workflowCanvas__header{
                height: 24px;
            }
        }
    }

    .workflowCanvas__outer {
        background-color: #1e2038;
        padding: 12px 16px 100px;
        border-radius: 4px;
        transition: height 0.5s !important;
        position: relative;

        .zoom_icons_div {
            bottom: 70px;
        }

        .canvas,
        .zoom_icons_div,
        .selectAComponentMsg {
            opacity: 1;
            transition: opacity 0.25s !important;
        }

        .canvas .node {
            cursor: pointer;
        }

        &.minimized {
            padding-bottom: 35px;
            // margin-bottom: 20px;

            .canvas,
            .zoom_icons_div,
            .selectAComponentMsg {
                opacity: 0;
            }
        }

        .spinnerDiv {
            position: absolute;
            background-color: rgba(102, 106, 141, 0.56);
            // width: 100%;
            // height: 76.5%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }
    }

    svg:not(:root) {
        overflow: visible;
    }

    .canvas,
    .spinnerDiv {
        border-radius: 8px;
    }

    .workflowCanvas__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 18px;
        }

        .separator {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            border-top: 1px solid #393e53;
            height: 9px;
            margin-top: 8px;
        }

        img {
            transition: transform 0.25s;
        }
    }

    .selectAComponentMsg {
        margin-top: 12px;
        // margin-bottom: 24px;
        color: #a1a2ab;
    }



    .grid-graph {
        border-radius: 4px;
        background-color: #2a2c42;
        position: relative;
        padding: 50px 16px 24px 24px;

        .workflowCanvas__header{
            position: absolute;
            width: 100%;
            top: 12px;
            left: 0;
            padding-left: 24px;
            padding-right: 16px;
            height: 18px;
            // align-items: initial;

            .workflowName{
                margin-left: 0;
            }

            .separator{
                // height: 9px;
                // margin-top: 8px;
                margin-left: 8px;
            }
        }

        .resizeIcon-Graph {
            margin-right: 12px;
        }

        .skeletonIcon-Graph{
            margin-left: 4px;
            margin-right: 0;
        }

        .colorPickerIcon-Graph svg{
            width: 13px;
            height: 13px;
        }

        .spinnerDiv, .inPageSpinner {
            height: 100%;
            align-items: center;
        }
    }

    
    // .bk, .bk-root, :not(.bk.bk-shading){
    //     width: 100% !important;
    //     height: calc(100% - 15px) !important;
    // }

    // [class="bk"],
    // [class="bk-root"],
    // [class="bk bk-canvas"] {
    //     width: 100% !important;
    //     height: calc(100% - 15px) !important;
    // }

    // .bk.bk-canvas-events > .bk {
    //     width: 30px !important;
    //     height: unset !important;
    //     left: auto !important;
    //     right: 0 !important;
    // }

    .reverse_img {
        transform: rotate(180deg);
    }

    .react-resizable-handle.react-resizable-handle-se{
        padding-right: 8px;
        padding-bottom: 8px;
        width: 26px;
        height: 26px;

        &::after{
            width: 20px;
            height: 20px;
            border-color: #9495A0;
            right: 10px;
            bottom: 10px;
        }
    }

    .innerPlotBox{
        height: 100%;

        &.enableScroll{
            overflow: auto;
        }
    }

    button:disabled{
        cursor: default;
    }

    .graphCardIcon{
        margin-right: 12px;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
      

        &:hover{
            .hover-graph-icon {
                fill : #D4D6E0;
            }
        }

        &:last-of-type{
            margin-right: 0;
        }

        &.disabled {
            opacity: 0.56;
        }
    }

}

.btn-goBackWithArrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
    line-height: 24px;

    // span {
    //     color: #262231 !important;
    //     font-size: 33px;
    //     font-weight: 700;
    //     width: 15px;
    //     overflow: hidden;
    //     margin-left: 0;
    //     margin-bottom: 7px;
    // }    
}

.canvas__analytics {
    .ds__node__options__toggle {
        opacity: 0.2;
        pointer-events: none;
    }
}

.fullModal__container {
    width: 800px;
    .modal__header {
        position: absolute;
        width: 100%;
    }
    .modal__body {
        padding: 0;
    }
}

.plotSelectionModal__container {
    width: 559px;

    .modal__header {
        background-color: rgba(0, 0, 0, 0.24);
        text-align: left;
        position: relative;
    }

    .modal__title {
        margin-bottom: 8px;
    }

    .modal__subtitle {
        color: #ffffff;
        font-size: 12px;
        opacity: 0.72;
    }

    .modal__body {
        padding: 16px 24px 24px;
    }

    .columnsList {
        background-color: #14182a;
        padding: 6px;
        border-radius: 2px;
        width: 135px;
        height: 320px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-bottom: 0;
    }

    .inPageSpinner {
        height: 538px;
        align-items: center;
    }
}

.columnSelection__box {
    display: flex;
    border-top: 1px solid #6d707e;
    border-bottom: 1px solid #6d707e;
    padding: 16px 0;

    label {
        color: #ffffff;
        letter-spacing: 0.25px;
        margin-bottom: 4px;
        display: block;
        font-size: 12px;
    }

    .columnDrop__box {
        background-color: #14182a;
        border-radius: 2px;
        // width: 360px;
        height: 84px;
        margin-bottom: 16px;
        padding: 6px;
        overflow-y: auto;
        display: flex;
        border: 1px solid transparent;
        flex-wrap: wrap;

        & > div{
            height: 20px;
        }

        &.showDropMessage{
            background-color: #666A8D;
            cursor: grabbing;
            position: relative;

            &:active{
                cursor: grabbing;
            }

            &::before{
                content: 'RELEASE TO ADD';
                font-weight: 600;
                color: rgba(255, 255, 255, 0.31);
                display: flex;
                font-size: 12px;
                text-align: center;
                // line-height: 72px;
                position: absolute;
                width: 90%;
                align-items: center;
                justify-content: center;
                height: 90%;
            }
        }

        &.showEmptyError{
            border-color: #FF3D4F;
        }

        &.columnDrop__boxDisabled{
            opacity: 0.5;
        }
    }
}

.columnSelection__LHS {
    margin-right: 16px;
    width: 135px;

    .multiple-select-tags {
        background-color: #34acd0;
        color: #2a2c42;
        margin-bottom: 6px;
        width: fit-content;
        font-weight: 600;
        cursor: grab;

        &:active{
            cursor: grabbing;
        }

        &.disableDrag {
            cursor: default;
            opacity: 0.3;
        }
    }
}

.columnSelection__RHS {
    flex-grow: 1;
}

.columnSelection__inner{
    width: 100%;
    position: relative;

    .inputfield__errormessage{
        margin-top: 0;
        position: absolute;
        left: 0;
        bottom: -18px;
    }

    &.columnSelection__inner__disabled{
        opacity: 0.56;
    }
}

.dragDropMsg {
    color: #d4d6e0;
    font-size: 10px;
    letter-spacing: 0.25px;
    line-height: 16px;
    display: flex;
    margin-top: 4px;
 

    svg {
        width: 13px;
        height: 13px;
        margin-top: 1px;
        margin-right: 2px;
    }
}

.plotOptionsForm {
    color: white;

    .disableSelection{
        .columnSelection__RHS{
            opacity: 0.6;

            button {
                cursor: default;
            }
        }
    }


    // margin-bottom: 24px;
    .select-field:first-child {
        margin-bottom: 20px;
    }

    .btns__box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .split__fields--halfwide {
        padding-top: 0;

        .select-field{
            margin-bottom: 20px;
        }
    }

    .dynamicKeyValueGenBox{
        padding-top: 15px;
        max-height: 146px;
        overflow-y: auto;
    }

    .columnLabelBox{
        display: flex;
        color: #ffffff;
        align-items: center;
        height: 21.5px;
        justify-content: space-between;
    }

    .columnActions__box{
        margin-bottom: 4px; 

        button{
            font-size: 12px;
            text-decoration: underline;
            height: 21px;
            letter-spacing: 0.25px;

            &:first-of-type{
                margin-right: 8px;
            }

            &:disabled{
                opacity: 0.4;
                cursor: default;
            }
        }

    }

    .multiBandCol__outerSelectionBox{
        display: flex;
        column-gap: 14px;

        .columnLabelBox{
            text-transform: capitalize;
        }
    }

    .multiBandCol__selectionBox{
        flex-shrink: 0;
        width: 30%;
    }

    .multiBandCol__selectionBox:nth-child(2){
        width: 33%;
    }
}

.bk-root {
    background-color: #2a2c42;
}

.hide_graph {
    display: none;
}

.graph_error_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    height: 100%;
    width: 100%;
    
    .error-link {
        margin-left: 15px;
        cursor: pointer;
    }

    img {
        width: 32px;
        height: 36.57px;
    }

    .raster__errorMessage{
        margin-top: 16px;
    }
}

.graph_error {
    z-index: 1;
    margin-bottom: 10px;
}

.error__container,
.errorLogsModal__container,
.errorLogsModal__containerLarge {
    .modal__body {
        flex-direction: column;
        justify-content: space-between;
    }

    .spinnerDiv {
        height: 88%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btns__box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        height: 32px;
    }
    
    .log_wrapper {
        background-color: #14182a;
        height: 88%;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 15px;
    }

    .output__text {
        height: 100%;
    }
}

.errorLogsModal__container {
    width: 500px;

    .modal__body {
        height: 400px;
    }
}

.error__container {
    width: 700px;

    .modal__body {
        height: 400px;
    }
}

.viewLogsModal__container {
    width: 700px;

    .modal__body {
        height: 400px;
    }

    .log_wrapper {
        background-color: #14182a;
        height: 88%;
        font-size: 12px;
        padding: 5px;
        margin-bottom: 15px;
    }
}

.errorLogsModal__containerLarge {
    width: 950px;
    padding-bottom: 20px;
    .modal__body {
        height: 400px;
    }
}

.progressModal__containerLarge {
    width: 700px;

    .modal__body {
        height: 550px;
    }
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center; 
}


.plot_selection_selectField_wrapper {
    position: absolute;
    z-index: 99999;
    right: 23px;
    top: 35px;
    color: white;
    display: inline-flex;
    .plot_selection_selectField_input {
        width: 130px;
        margin-top: -17px;
    }

    span {
        color: #ffffff;
    font-size: 12px;
    opacity: 0.72;
    padding: 7px 14px 0 0;
    }
}

.showEmptyComponentMsg {
    padding: 24px 23px 0 23px;
    line-height: 20px;
}

.workflow__analytics__tabs{
    .user__dropdown{
        position: unset;
        padding-right: 16px;
        padding-top: 7px;
        padding-left: 3px;
        background-color: #2a2c42;
    }

    .user__name{
        display: none;
    }

    .user__initials{
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 18px;
    }
}

.leaflet-control-attribution .leaflet-control {
display: none;
}


.btn-minimise{
    display: flex;
    align-items: center;
    svg {
        transition: transform 0.25s;
    }

    &.reverse svg{
        transform: rotate(180deg);
    }

    &.disabled {
        opacity: 0.30;
        cursor: default;
    }
}


.colorPickerModal__container{
    color: white;
    

    .colorPreview__outer{
        display: flex;
        column-gap: 20px;
        margin-bottom: 16px;

        span {
            font-size: 12px;
        }
    }
    
    .select-field, .inputfield__container, .radiofield__inner__container{
        margin-bottom: 16px;
    }

    .colorPreview{
        height: 36px;
        width: 120px;
        border-radius: 8px;
    }

    .btns__box{
        display: flex;
        justify-content: flex-end;
    }
    
}

.raster-leaflet-map{
    width: 100%;
    height: 100%;
}

.colorModeSelection{
    justify-content: flex-start;

    .inputfield{
        width: 100px;

    }
}

.map_outer.tiffWithoutMap{
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #0B0E1C;
}

.loseUnsavedFormChangesInPlotSelection{
    width: 400px;

    p{
        font-size: 14px;
        margin-bottom: 24px;
    }

    button{
        min-width: 120px;
    }
}

.leaflet-control-attribution.leaflet-control{
    display: none;
}

.drag{
    top:auto !important;
    left: auto !important;
}

.attributes-table-select .ant-select-selection {
    border: 1px solid #000;
    border-radius: 0px;
}
.ant-select-disabled .ant-select-selection {
    background-color: #2d3347 !important;
    cursor: not-allowed;
}

.attributes-table-select .ant-select-selection:hover,
.attributes-table-select .ant-select-selection-focused {
    border-color: #fcad01 !important;
    box-shadow: none !important;
}

.attributes-table-switch.ant-switch {
    background: #000;
    border-radius: 20px;
}
  
.attributes-table-switch.ant-switch:hover {
    border-color: #fcad01 !important;
    box-shadow: none !important;
}
  
.attributes-table-switch.ant-switch-checked {
    background: #fcad01 !important;
    border-color: #fcad01 !important;
}
  
.tree-container {
    background-color: #2a2d43;
    border: 1px solid #dcdcdc; 
    border-radius: 8px;
    min-width: 300px;
    padding: 12px;
    margin-top: 20px;
  }

.custom-tree {
    background-color: #2a2d43;
    height: 68vh;
    overflow: auto;
  }
  
  .custom-tree .ant-tree-treenode {
    margin: 4px 0;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
  }

  .custom-tree .ant-tree-node-selected {
    background-color: #3e4161 !important;
    color: #fcad01;
    font-weight: bold;
  }

  .custom-tree .ant-tree-switcher {
    font-size: 16px;
    color: #ffa940;
    transition: transform 0.3s;
  }
  
  .custom-tree .ant-tree-switcher_open {
    transform: rotate(90deg); 
  }
  
  .custom-tree .ant-tree-switcher_close {
    transform: rotate(0deg);
  }
  
  .custom-tree .ant-tree-title {
    font-size: 14px;
    font-family: Arial, sans-serif;
  }
  
.model-tree-select .ant-select-selector {
    background-color: #13182a !important;
    color: #fff !important;   
    border:none !important;  
    border-radius: 0; 
  }
  
  .model-tree-select .ant-select-selection-item {
    background-color: #fcad01 !important; 
    color: #13182a !important;          
  }
  
  .model-tree-select .ant-select-dropdown {
    background-color: #13182a !important;
    color: #fff !important; 
  }
  
  .model-tree-select .ant-select-tree {
    background-color: #13182a !important;
    color: #fff !important; 
  }
  
.model-tree-select .ant-select-tree-node-selected {
    background-color: #fcad01 !important;
    color: #000 !important;
}

.model-tree-select .ant-select-tree-treenode-disabled{  
    color: gray !important;
    background-color: #fff !important;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
.ant-select-dropdown {
	background-color: #13182a; 
	
}

.ant-empty-normal .ant-empty-description{
    color: #fff;
}