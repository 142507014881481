.ant-tabs {
    .ant-tabs-nav {
        border-bottom: 2px solid rgba(255, 255, 255, 0.32);

        .ant-tabs-tab {
            color: #93949e;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.25px;
            padding: 5px 10px;
            padding-left: 8px;
            padding-top: 0;
            &:hover {
                color: #fff;
            }
        };

        .ant-tabs-tab-active {
            color: #fff;
        };

        .ant-tabs-ink-bar {
            height: 2px;
            bottom: -1px;
        };
    };

    .ant-tabs-large-bar .ant-tabs-tab {
        font-size: 16px;
        height: 45px;
        line-height: 45px;
    };

    .ant-tabs-bar {
        border-bottom: 1px solid #393E53;
    };
};