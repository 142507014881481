.z100 {
    z-index:100;
    opacity: 0;
}
.z1000 {
    z-index:1000;
    opacity: 1;
}

.menu_item{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: white;
    padding-left: 12px;

    &:hover{
        color: black;
        background-color: white;

        svg path{
            fill: black;
        }
    }
 }

 .placeholder_menu_item{
    width: 173px;
    color: #9495A0;
    font-weight: 700;
    font-size: 10px;
    text-align: end;
    margin-right: 12px;
 }

.btn-editor {
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.25px;
	border-radius: 2px;
	text-align: center;
    &:hover {
        border-color: #FFFFFF ;
        color:  #212233
    }
    &:focus {
        border-color: #FFFFFF ;
        color: #212233
    }
	height: 24px;
	min-width: 72px;
	font-weight: 700;
	color: #212233;
}

.editor-dropdown-menu{
    background-color: #212233;
    width: 173px;

    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
        height: 26px;
        padding-left: 0;
    }

    .zeppelin-sub-menu{
        padding-right: 18px;
    }

    .zeppelin-sub-menu-popup{
        background-color: #212233;
    }

    .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title{
        background-color: transparent;
    }
}

.confirmModal {
    .ant-modal-content {
        margin-top: 20px;
        background: #2a2c42;
        border-color: #2a2c42;
        border-radius: 5px;
        padding:0px;
        .ant-modal-close {
            color: white;
        }
  
    .ant-modal-header {
        background: #2a2c42;
        border-color: #2a2c42;
        padding:0px;
        .ant-modal-title {
          color: white;
        }
      }
      .ant-modal-footer {
        border-color: #0b0e1c;
      }
      .ant-btn {
        background: #2a2c42;
        color: #fff;
        border-width: 0;
        font-size: 14px;
        font-weight: 600;
      }
      .ant-btn-primary {
        color: #262231;
        background: #fcad01;
        border-color: #fcad01;
        box-shadow:0 1px 4px 0 rgba(252, 173, 1, 0.72);
      }
    }
}

.btn-cursor{
    cursor: pointer;
}