
.workflowDetails__RHS{
	padding: 16px 12px;

	.workflowName__title{
		font-size: 14px;
		margin-bottom: 16px;
	}
}

.workflowMetaData{
    margin-bottom: 16px;

    .label, .value {
        font-size: 12px;
    }

    .label {
        margin-bottom: 4px;
        color: #C7C7CD;
    }
}

.workflowConfig__container{
    padding: 16px 8px;
}

.workflowConfig__form, :global(.properties__container) .workflowConfig__form{
    border: 1px solid rgba(256, 256, 256, 0.08);
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 40px;

    :global(.inputfield__container), :global(.select-field), :global(.radiofield__container) {
        padding: 0;
        border: none;
        margin-bottom: 8px;
    }

    button{
        margin-top: 4px;
    }
}

.workflowConfig__list__container {

    &.workflowConfigList__insideComponent{
        .workflowConfig__list_header{
            min-height: 20px;
            padding-left: 12    px;
        }


        thead tr {
            height: 20px;
        }
    }

    
}

.workflowConfig__list_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    min-height: 32px;
}

.workflowConfig__actionBtns{
    display: flex;
    column-gap: 12px;
}

.deleteConfigItemsButton{
    display: flex;
    align-items: center;
    min-height: 32px;
    height: auto;


    img, svg{
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
}

@media screen and (max-width: 1400px) {
    .deleteConfigItemsButton {
        padding: 0 3px;
    }
}

.downloadConfigItemsButton{
    width: 28px;
    min-width: auto;
    padding: 0;

    img{
        width: 16px;
        height: 16px;
    }
}

.workflowConfig__list__title{
    color: #C7C7CD;
    font-size: 14px;
    margin-bottom: 0;
    // max-width: 103px;
}

.workflowConfig__table{
    width: 100%;
    // table-layout: fixed;
    
    th{
        color: #C7C7CD;
        font-weight: 400;
        position: sticky;
        top: 0;
        background-color: #2a2c42;
        z-index: 1;
    }

    thead tr {
        background-color: #2a2c42;
        height: 30px;
    }

    tbody tr{
        min-height: 24px;
    }

    td, th{
        font-size: 12px;
        padding-left: 12px;
    }

    td{
        font-weight: 500;
    }

    .checkboxColumn{
        width: 20px;
        padding-left: 4px;
        background-color: #2a2c42;
    }

    button:disabled {
        opacity: 0.32;
        cursor: default;
    }

    &.workflowConfig__table__globalPipeline{
        .tableText{
            width: 90px;
        }
    }
    
}

.workflowConfig__tableWrapper{
    max-height: 40vh;
    overflow-y: auto;
    position: relative;
    
}


.tableText{
    overflow-wrap: break-word;
    width: 110px;
}


@media screen and (max-width: 1400px) {
    .tableText{
        width: 65px;
    }

    .workflowConfig__table.workflowConfig__table__globalPipeline .tableText{
        width: 55px;

    }
}

.typeColumn{
    width: 25%;
}


.editColumn{
    width: 30px;
    padding-right: 8px;
}

.globalColumn{
    width: 20px;
    padding-right: 8px;
}

.emptyKVMessage{
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.48);
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 40px;
}

:global(.workflowConfigList__insideComponent) .emptyKVMessage{
    margin-top: 24px;
}

.globalIconBtn{
    display: flex;
    align-items: center;
}

.configImportModal{
    background-color: #3E4161 !important;
    border-radius: 8px !important;

    :global(.modal__header), :global(.modal__body) {
        padding: 16px;
    }

    :global(.modal__title){
        font-size: 16px;
    }

   :global(.modal__subtitle) {
        color: #C7C7CD;
    }

    &.processing{
        :global(.modal__body){
            display: none;
        }

        :global(.modal__title){
            margin-bottom: 16px;
        }
    }

    &.importedConfigItemsSelection{
        width: 570px;
        :global(.modal__title){
            margin-bottom: 8px;
        }

        :global(.modal__header){
            padding-bottom: 0;
        }
    }

    .formInnerContainer {
        background-color: #14182A;
        width: 540px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        height: 60vh;
    }

    :global(.modalBtns__box){
        margin-top: 16px;
        column-gap: 8px;
    }
}
.importConfigItemsTableRow{
    background-color: #212233;

    .tableCellField{
        padding-left: 0;
        padding-right: 16px;
        // align-items: flex-start;
    }
    
    .tableCellHeaderField{
        padding-left: 0;
    }
    
}

:global(.ReactVirtualized__Table__headerRow).importConfigItemsTableRow{
    padding-right: 10px !important;
}

.importConfigTable :global(.ReactVirtualized__Grid) {

    .importConfigItemsTableRow{
        background-color: #14182A;

        :global(.inputfield), :global(.select-field-value) {
            background-color: rgba(0, 0, 0, 0.32);
            border-radius: 4px;
            height: 36px;
            font-weight: 500;
            position: relative;

            
        }

        :global(.inputfield):read-only{
            color: rgba(255, 255, 255, 0.48);
            background-color: rgba(0, 0, 0, 0.32);
            border-color: transparent;
        }

        :global(.inputfield__label) {
            margin: 0;
        }

        :global(.select-field) {
            margin-bottom: 0;
        }

        :global(.inputfield__errormessage) {
            margin-top: 2px;
        }

        .configTypeText{
            font-weight: 500;
            font-size: 12px;
            text-transform: capitalize;
            color: white;
        }

        &.importConfigItemsTableRowExistingKeys{
            border: 1px solid #FF9A82;
        }
    }

}

.identicalKeysMessage{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #FF7766;
}

.existingKeysHeader{
    background-color: transparent;
}

.configTable__container{
    border-bottom-left-radius: 0 !important;
}