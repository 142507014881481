.pagination__main{
    display: flex;
    // font-size: 12px;
    // opacity: 0.72;
    // height: 25px;
    // align-items: center;
    // justify-content: flex-end;

    
    .disable__hover{
        cursor: default;
    }

    &.showArrows{
        font-size: 12px;
        opacity: 0.72;
        height: 25px;
        align-items: center;
        justify-content: flex-end;

        button {
            height: 16px;
            width: 16px;
            margin-bottom: 10px;
        }
    }

    &.showButtonsForPageNav{
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.pageNumbersBox{
    display: flex;
    margin-right: 0;

    button {
        margin-right: 8px;
    }

    .btn-grey{
        border: 2px solid transparent;
    }

    .btn-pageNum{
        min-width: 20px;
    }
}

.chevron{
    opacity: 0.2;
    fill: #C7C7CD;

    &.chevron__active{
        fill: #C7C7CD;
        opacity: 1;
    }
}

.reverse__chevron{
    margin-right: 8px;

    svg {
        transform: rotate(180deg);
    }
}