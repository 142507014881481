.landingPage__container{
    width: 891px;
    height: 580px;
    

    .modal__header {
        display: none;
    }

    .modal__body{
        padding: 0px;
        display: flex;
        flex-flow: row wrap;
    }

    .modal__title {
        display: none;
    }

    & > div {
        height: 100%;
    }

    .landingPage__LHS{
        background-color: rgba(3, 6, 19, 0.3199999928474426);
        width: 215px;

        & > div {
            border-bottom: 1px solid #393E53;

            button:last-child{
                margin-bottom: 0;
            }
        }

        div:last-child {
            border-bottom: none;
        }

        button, .__title, .adminSettings__box a{
            margin-bottom: 10px;
            color: #D4D6E0;
            font-size: 14px;
            letter-spacing: 0.1px;
            line-height: 22px;
            text-align: left;
            font-weight: 600;
            opacity: 0.72;
            // display: block;

            &:last-of-type{
                margin-bottom: 0;
            }
        }

    }

    
    .logo__box{
        padding: 16px;
    }

    .recents__box{
        padding: 20px 20px 20px 25px;
        height: 400px;
    }


    .adminSettings__box{
        height: 77px;
        padding: 11px 25px;
        a {
            display: block;
        }

    }
    .userInfo__box {
        padding-top: 15px;
        padding-left: 25px;
        
        .user__dropdown{
            position: initial;
            flex-direction: row-reverse;
            margin-bottom: 10px;
        }

        .user__name{
            margin-left: 5px;
        }
    }

    .landingPage__RHS__title{
        padding-left: 32px;
        padding-top: 24px;
        padding: 24px 24px 0 32px;
        color: #FFFFFF;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 32px;

        // .userName{
        //     display: inline-block;
        //     max-width: 120px;
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        // }

        .lastLoginMsg{
            color: #D4D6E0;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            opacity: 0.5600000023841858;
        }

        img{
            height: 16px;
            width: 16px;
            float: right;
            cursor: pointer;
        }
    }

    .recentWorkflows__list{
        display: flex;
        flex-direction: column;
        padding-top: 13.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        button{
            margin-bottom: 9px;
            color: #ffffff;
            font-size: 14px;
            letter-spacing: 0.25px;
            text-align: left;
            height: 24px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
                overflow: visible;
            }
        }
    }

    

}

.landingPage__cardsBox{
    padding: 40px 0 40px 32px;
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    width: 676px;

    .pageCard{
        height: 204px;
        width: 290px;
        margin-right: 32px;
        margin-bottom: 32px;
        padding-top: 30px;
        // padding: 36px 32px;
        background-color: #030613;
        border-radius: 2px;
        box-shadow: 2px 4px 12px 0 rgba(3, 6, 19, 0.32);
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        svg {
            height: 64px;
            width: 64px;
            margin-bottom: 8px;
        }
    }

    .pageCard__title{
        color: #D4D6E0;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 32px;
        text-align: center;
    }

    .pageCard__buttons{
        margin-top: 8px;
        .btn-md{
            min-width: 50px;
            margin-right: 20px;

            &:last-child{
                margin-right: 0px;
            }
        }
        a{
            display: inline-flex;
            height: 32px;
            align-items: center;

           
        }
    }
}