.modal{
    padding: 1.25rem 1.5rem;
    width: 430px;

    :global(.modal__header){
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 0.5rem;
        margin-bottom: 8px;
    }

    :global(.modal__title){
        color: #FBC441;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
    }

    :global(.modal__subtitle) {
        font-size: 12px;
        line-height: 18px;
        color: #C7C7CD;
    }

    :global(.modal__body){
        padding: 0;
    }

    :global(.close__icon__modal){
        display: none;
    }
}

.activeUsersBox{
    padding: 4px 8px 4px 4px;
    background: #212233;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;

    p{
        font-size: 14px;
        color: rgba(255, 255, 255, 0.48);
        margin-bottom: 6px;
    }

    ul{
        margin-bottom: 0;
        display: flex;
        list-style-type: none;
        color: #C7C7CD;
        flex-direction: column;
        row-gap: 8px;
    }
}

.willBeLoggedOutMessage{
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;

}