.file-upload-modal {
	width: 880px;

	.modal__header {
		background-color: rgba(0, 0, 0, 0.24);
	}
}

.file-browser-modal {
	width: 880px;
	height: 600px;
	padding: 0;

	.modal__header {
		display: none;
	}

	.modal__body {
		padding: 0;
		height: 100%;
	}
}

.file-upload-modal__cancel-btn {
	color: white;
	&:disabled {
		color: rgba(255, 255, 255, 0.5);
	}
}