.rasterPlot__valuesOverlay{
    min-width: 100px;
    width: 350px;
    background: #2A2C42;
    border-radius: 6px;
    position: absolute;
    z-index: 1000;
    bottom: 20px;
    left: 20px;
    padding: 17.5px 0;
    margin-bottom: 0;
    color: white;

    h4{
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 105px;
    }

    .rasterConfig__item__multiBand h4{
        max-width: 150px;
    }

    .valueColorRange{
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        font-size: 14px;
        font-weight: 500;
        // color: black;
        // color: w;

        .colorBox{
            width: 16px;
            height: 16px;
            display: block;
            margin-right: 8px;
        }
    }

    .rasterPlot__heading{
        color: white;
        font-weight: 700;
        font-size: 16px;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 0;
        max-width: fit-content;
    }

    :global(.ant-collapse-item) {
        padding: 4px 0;

    }
    &.minimized{
        width: 200px;
        padding: 8px 0;


        :global(#add-raster), .rasterConfig__actionBtns {
            display: none;
        }

        .rasterPlot__headingBox{
            margin-bottom: 8px;
        }

        .rasterPlot__heading{
            font-size: 14px;
        }

        .rasterConfig__item h4{
            max-width: 130px;
        }
    }
}

.rasterConfig__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
    height: 24px;
    padding-left: 16px;
    padding-right: 16px;

    &:last-of-type:not(:first-child) {
        margin-bottom: 10px;
    }

    .rasterConfig__actionBtns, .rasterConfig__item__LHS{
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

    button {
        display: flex;
        align-items: center;
    }

    
}


.rasterPlot__valuesBox{
    max-height: 380px;
    overflow-y: auto;
}

.chevronIcon{
    transform: rotate(90deg);
}

:global(.ant-collapse-item-active) {
    .chevronIcon__active{
        transform: rotate(180deg);
    }
}

.rasterConfig__collapseContent{
    margin-top: 12px;
    padding-left: 64px;
}

.rasterPlot__headingBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    padding-right: 16px;
    margin-bottom: 12px;

    :global(#add-raster){
        display: flex;
        align-items: center;

        
        img {
            margin-right: 0px;
        }

    }
}

.raster__canvas{
    position: absolute;   
    top: 0;
    // left: 0;
    bottom: 0;
    // width: 100%;
    height: 100%;
}

.alphaSlider{
    width: 50%;
    margin-bottom: 12px;
}

.rasterConfig__tabsOuter{
    background: rgb(0, 0, 0, 0.24);
    color: rgba(255, 255, 255, 0.56);
    font-weight: 700;
    font-size: 12px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    margin-top: -16px;
    margin-bottom: 24px;
    display: flex;
    column-gap: 32px;

    .rasterConfig__tab{
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
        width: max-content;
        cursor: pointer;
    
        &.rasterConfig__tab--active{
            color: white;
            border-bottom: 2px solid rgba(255, 255, 255, 0.9);
            cursor: default;
        }
    }

}

:global(#modal-add-new-raster){
    font-weight: 700;
    color: #FCAD01;
    padding-bottom: 10px;
    display: flex;
    align-items: center;

    svg{
        margin-right: 2.5px;
        margin-top: -2px;
    }
}

.selectCluster__modal{
    padding-left: 0;
    padding-right: 0;
    padding-top: 16px;
    width: 396px;

    :global(.modal__header){
        display: none;
    }

    :global(.modal__body){
        padding-top: 0;

    }

    &.selectCluster__error{
        .selectCluster__header{
            margin-bottom: 16px;
        }
    }

    .title {
        color: white;
        display: block;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .subtitle{
        display: block;
        color: rgba(255, 255, 255, 0.72);
    }

    .modalBtns__box{
        display: flex;
        justify-content: flex-end;
    }

    .btn-cancel{
        color: white;
        margin-right: 18px;
    }

    .selectCluster__header{
        margin-bottom: 24px;
    }
}

.projectionField{
    position: relative;

    // :global(.checkboxfield__container) :global(.radiofield__inner__container){
    //     margin-bottom: 0;
    // }

}

.customProjectionCodeCheckbox{
    position: absolute;
    right: -10px;
    z-index: 2;

    :global(.radiofield__inner__container){
        margin-bottom: 0;
    }

}