

.editorHeader{
    background-color: #1E2038;
    border-bottom: 2px solid #2A2C42;
    width: 100%;
    height: 27px;
    border-radius: 4px 4px 0 0;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #9495A0;
    column-gap: 24px;

    button {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        svg {
            width: 14px;
            height: 14px;
            margin-right: 6px;
        }

    }
    
}

.editor {
    position: sticky;
}
