.ReactModal__Overlay {
    background-color: rgba(0, 0,0, 0.72) !important;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    // z-index: 1005;
    z-index:10;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  
  .ReactModal__Content{
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom:auto;
    transform: translate(-50%, -50%);
    background-color: #2A2C42 !important;
    border-radius: 4px !important;
    border: none !important;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48), inset 0 0 4px 0 rgba(255, 255, 255, 0.1) !important;
  }

  .modal__header{
    padding: 16px 16px 16px 24px;
    display: flex;
    align-items: center;
    position: relative;

    .close__icon__modal{
      position: absolute;
      width: 10px;
      height: 10px;
      top: 16px;
      right: 16px;

    }
  }
  
  .modal__title{
    color: #FFFFFF;
    font-family: 'DM Sans';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 17px;
    display: block;
  }

  .modal__body{
    padding: 12px 24px 16px;
  }

  .modalBtns__box{
    display: flex;
    flex-flow: row-reverse wrap;
  }

  .modal__header img {
    width: 25px;
  }

  .context-view li.action-item:nth-child(n + 7) {
    display: none !important;
}

.context-view li.action-item:nth-child(n + 8) {
  display: none !important;
}
.context-view li.action-item:nth-child(n + 6) {
  display: none !important;
}

.modal__header img {
  // display: none;
}

.modal__header .show_modal_img {
  display: inline-block ;
}