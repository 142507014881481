.contextMenuBox {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: all;
    z-index: 2;
    &.show {
		display: block;
	}
}