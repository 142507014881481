.dataexplorer__container {
    margin-left: 0;
    height: calc(100vh - 280px);
    color: #fff;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;

    .react-grid-layout {
        position: relative;
        transition: none;
    }

    .grid-graph {
        border-radius: 4px;
        position: relative;
        background-color: #212233;
        margin: 1rem 0;
        padding-bottom: 0.5rem;
        ;
    }

    button {
        font-size: 14px;
        font-weight: 800;
        text-decoration: underline;
        height: 21px;
        letter-spacing: 0.25px;
    }

    .workflowCanvas__header {
        width: 100%;
        left: 0;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 16px;
        height: 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 18px;
        }

        .separator {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            border-top: 1px solid #393e53;
            height: 9px;
            margin-top: 8px;
        }

        img {
            transition: transform 0.25s;
        }
    }

    .innerPlotBox {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
    }

    .echarts-for-react {
        height: 88% !important;
        margin-bottom: 0.5rem;
    }

    .graphCardIcon {
        margin-right: 12px;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }


        &:hover {
            .hover-graph-icon {
                fill: #D4D6E0;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.disabled {
            opacity: 0.56;
        }
    }
}

.dataexplorer__model {
    position: absolute;
    top: 10px;
    left: 30%;
    height: 50rem;
    width: 60rem;
    z-index: 1001;
    display: none;
    background: rgba(33, 34, 51, 1);
    border-radius: 0.25rem;

    &.show {
        display: block;
    }

    .workflowCanvas__header {
        width: 100%;
        left: 0;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 16px;
        height: 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 18px;
        }

        .separator {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            border-top: 1px solid #393e53;
            height: 9px;
            margin-top: 8px;
        }

        img {
            transition: transform 0.25s;
        }
    }

    .innerPlotBox {
        padding: 1rem;
        height: 50rem;
        width: 60rem;

        .echarts-for-react {
            height: 44rem !important;
        }
    }
}

.dataexplorer__backdrop {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);

    &.show {
        display: block;
    }
}

.history-listing {
    .ant-list-split .ant-list-item {
        border: 1px solid #5B608F;
    }

    .ant-list-lg .ant-list-item {
        padding: 0.5rem;
    }

    .ant-avatar>img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        font-size: 0.75rem;
    }

    .ant-avatar-string {
        font-size: 1rem;
    }
}

.editor {
    font-size: 1.5rem;

    .monaco-editor,
    .monaco-editor-background,
    .monaco-editor .inputarea.ime-input {
        background: rgba(0, 0, 0, 0);
    }

    ;
}

#dataexplorer {
    .iconButton {
        margin-top: 5px;
    }

    .iconButton>svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .ant-tabs {
        background-color: rgb(62, 65, 97);
        width: 100%;
    }

    .ant-tabs-bar {
        padding: 1rem 2rem 0;
    }

    .ant-tabs-tab {
        font-size: 1.2rem;
    }
}

.thinking-color {
    color: #fcad01;
    font-size: 1rem;
}

.full {
    width: 80vw;

    .layout,
    .dataexplorer__container {
        height: 90vh !important;
    }

    >div:not(.dataexplorer__container) {
        display: none;
    }

    .grid-graph {
        width: 78vw !important;
    }

    .layout>div {
        display: none;
    }

    .show {
        display: block !important;
        height: 90vh !important;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.centerh {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.row-col-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-failed-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #FF7766;
    font-weight: 500;
    cursor: auto
}

.retry-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: #C7C7CD;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
}

.radio-field {
    margin-bottom: -30px;
}

.metadata {
    .select-field-box .select-field-value {
        background-color: rgba(0, 0, 0, 0.32);
    }

    .monaco-editor,
    .monaco-editor-background,
    .monaco-editor .inputarea.ime-input {
        background: rgba(0, 0, 0, 0.32);
        border-radius: 0.25rem;
        ;
    }
}

.container {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 0fr 1fr;
    background-color: #030613;
}

section {
    background-color: #212233;
}

.sidebar {
    background-color: #212233;
    transition: all 0.3s ease;

    &.open {
        width: 0;
        padding: 0;
        opacity: 0;
        box-shadow: 0px 0px 14px #ececf1;
        filter: blur(14px);
    }

    &-header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.8rem 1rem;
        border-radius: 0.3rem;
        border: 0.05rem solid rgba(255, 255, 255, 0.5);
        cursor: pointer;

        &:hover {
            background-color: #212233;
        }
    }

    &-history {
        width: 100%;
        height: 100vh;
        overflow: auto;

        p {
            position: sticky;
            top: 0;
            background-color: rgb(32, 33, 35);
            padding: 0.4rem;
            color: #8e8fa1;
            font-size: 0.8rem;
            font-weight: 600;
            z-index: 1;
        }

        li {
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0.5rem;
            white-space: nowrap;

            &.li-overflow-shadow:not(:hover)::after {
                content: "‎";
                position: absolute;
                bottom: 0.5rem;
                right: -5px;
                padding: 0 5px;
                left: calc(100% - 50px);
                border-radius: 0.3rem;
                background: linear-gradient(to right, rgba(0, 0, 0, 0), #202123 100%, rgba(0, 0, 0, 0));
                pointer-events: none;
                z-index: 1;
            }
        }
    }

    &-info {
        width: 100%;
        padding-top: 1rem;
        border-top: 0.05rem solid rgba(255, 255, 255, 0.5);
    }

    &-info-upgrade,
    &-info-user {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;
    }

    &:hover {
        background-color: #343541;
        border-radius: 0.3rem;
        cursor: pointer;
    }

    &-info-upgrade:hover,
    &-info-user:hover {
        cursor: not-allowed;
    }
}

.main {
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    // padding: 1rem;
    position: relative;
    overflow: hidden;
    background: #030613;
    margin-left: 0;

    h1 {
        font-size: 2rem;
    }

    &-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 1rem;
        overflow: auto;

        li {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            padding: 1rem;
            margin: 1rem 0;
            border-radius: 0.3rem;

            &:nth-child(odd) {
                background-color: unset;
            }

            &:nth-child(even) {
                background-color: #444655;
            }

            img:not(:nth-child(even)) {
                display: block;
                border-radius: 0.3rem;
                width: 1.8rem;
                height: 1.8rem;
            }
        }
    }

    &-bottom {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        p:first-child {
            padding-top: 0.5rem;
        }

        p {
            font-size: 0.8rem;
            text-align: center;
            color: #c3c3d1;
        }
    }
}

.burger {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-25%, -50%);
    fill: #ececf1;
    cursor: pointer;
}

.custom-collapse .ant-collapse-header {
    margin-top: 1rem;
    font-size: 1rem !important;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    right: 6px;
    left: auto;
}

.collapseIcon.ant-collapse-arrow {
    position: initial;
    left: auto;
    right: 0px;
    transform: none;
    display: none;

    &.rotate {
        svg {
            transform: rotate(-180deg);
        }

    }

}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 0px;
    padding-right: 40px;
}

.ant-collapse-content-box {
    padding: 0;
    max-height: 50vh;
    overflow: auto;
}

.ant-collapse-item-disabled.configurePipeline__RHS__innerBox .ant-collapse-header {
    cursor: default !important;
}

.custom-collapse .ant-collapse-item:last-child {
    border-bottom: none !important;
}

.text-info {
    color: #C0C0C6;
    font-size: 1.2rem;
    font-weight: 800;
}

.text-configure {
    color: #C0C0C6;
    font-size: 1rem;
    font-weight: 400;
}

#dataexplorerNew {
    .iconButton {
        margin-top: 0px;
    }

    .iconButton>svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .ant-tabs {
        // background-color: #3a3b46;
        width: 30rem;
    }

    .ant-tabs-bar {
        padding: 0;
    }

    .ant-tabs-tab {
        font-size: 1.2rem;

        &:hover {
            color: #ffffff;
        }
    }
}

.ant-switch-checked {
	background-color:#3a3b46;
  }

.ant-switch-checked .ant-switch-inner {
	color: #ffffff; 
  }