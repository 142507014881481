.tableContainer{
    border-radius: 8px;
    overflow: hidden;
    font-size: 12px;
    letter-spacing: 0.25px;
    width: max-content;
}

.tableHeaderCell{
    text-transform: capitalize;
    color: #FFFFFF;
    height: 100%;
    margin: 0;

    &:first-of-type {
        margin: 0;
        border-top-left-radius: 4px;

    }

    // .tableR
}

.dataexplorer_table {
    height: 35vh;
    overflow: auto;
}

.tableRow{
    border: 0px solid transparent;
    border-left-width: 1.5px;
    background-color: #212233;


    &.alternative {
        background-color: #14182A;
    }

    &:nth-child(n):hover:not(.tableHeaderRow) {
        border: 1.5px solid #FCAD01;
        background-color: #666A8D;
        border-radius: 2px;
    }
}

.tableHeaderRow {
    border-top-left-radius: 4px;
    padding-right: 18px !important;
    
    &.tableRow{
        background-color: #212233;
    }
}



.tableColumn{
    height: 100%;
    margin: 0;

    &:first-of-type{
        margin: 0;
    }
}



.tableCell {
    // padding: 0;
    text-align: left;
    height: 100%;
    // background-color: #0B0E1C;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    font-weight: 700;
    overflow: hidden;

    &:global {
        &.tableCell--headerHover{
            color: #FEC954;
            border-left: 1px solid #494C6E;
            border-right: 1px solid #494C6E;
        }

        &.tableCell__firstColumn{
            border-left-color: transparent;
        }

        &.tableCell__lastColumn{
            border-right-color: transparent;
        }

        // &.tableCell--headerHover--firstCell{
        //     // background-color: #666A8D;
        //     // border: 1.5px solid #FCAD01;
        //     // // border-bottom-width: 4px;
        //     // border-radius: 1px;
        //     // color: #ffffff;
        //     // height: 30px !important;
        //     // line-height: 26px;
        //     // border-left-color: transparent;
        //     // margin-top: -4px;
        //     // display: flex;
        //     // align-items: center;
        // }
    }

    & > span{
        padding-top: 4px;
        padding-bottom: 4px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    
}

.tableHeaderCellText{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    padding-left: 24px;

    &:not(.disableHoverState):hover{
        color: #FEC954;
        border-left-color: #494C6E;
        border-right-color: #494C6E;

        &.tableHeaderCellText__firstColumn{
            border-left-color: transparent;
        }

        &.tableHeaderCellText__lastColumn{
            border-right-color: transparent;
        }
    }
}

.tableCheckbox{
    padding-left: 0;
    justify-content: center;
}

.reverseSortingIcon{
    transform: rotate(183deg);
}

.tableSpinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.tableHeaderInnerCell{
    display: flex;
}

.DragHandleIcon{
    font-size: 20px;
    display: block;
    width: 20px;
    text-align: center;
    z-index: 1;
    position: relative;
}