.executionEnv__outerBox{
    position: relative;
    padding-left: 20px;
    width: 130px;
    cursor: pointer;
    // border: 1px solid #666A8D;
    // border-radius: 24px;
    // padding: 4px;
    // width: 194px;
    // margin: 0 auto;
    // // height: 40px;
    // display: flex;

    // div{
    //     background: transparent;
    //     border: 1px solid transparent;
    //     border-radius: 16px;
    //     width: 50%;
    //     line-height: 28px;
    //     text-align: center;
    //     font-weight: bold;
    //     font-size: 14px;
    //     text-align: center;
    //     letter-spacing: 0.25px;
    //     color: #9B9FC6;
    //     height: 32px;
    //     cursor: pointer;

    //     &.activeExecutionEnv{
    //         background: #C9CDEC;
    //         border-color: #C9CDEC;
    //         color: #2A2C42;
    //         cursor: default;
    //     }
    // }

    .executionEnv__dropdown{
        width: 100px;
        margin-bottom: 0;
        font-weight: 700;
        font-size: 14px;

        .select-field-value{
            height: 30px;
        }

        .select-field-option, .select-field-value{
        font-weight: 700;
        }
    }

    .executionEnv__options{
        position: absolute;
        top: 0;
        background-color: #1E2038;
        width: 150px;
        z-index: 1;
        padding-top: 4px;

        .executionEnv__optionItem{
            margin-bottom: 4px;

            &:hover {
                background: rgba(102, 106, 141, 0.5);
            }
        }
    }

    .executionEnv__optionItem{
        height: 24px;
        font-size: 14px;
        letter-spacing: 0.25px;
        font-weight: 700;
        padding-left: 8px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 8px;
            // width: 18px;
            // height: 18px;
        }

        &.activeExecutionEnv{
            background: #666A8D !important;
        }

    }

    .activeExecutionEnv__optionItem{
        background: #1E2038;
        border-radius: 2px;
        width: 150px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .switchIcon{
        position: absolute;
        right: 8px;
        top: 3px;
    }

    .activeExecutionEnv__optionItem__inner{
        display: flex;
        align-items: center;
    }
}