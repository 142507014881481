.deepiq-portal{
    position: absolute;
    top: 0;
    left: 0;
    z-index:1000;
}
.deepiq-search {
    position: absolute;
    top: 100%;
    left: 0;
    width: 285px;
    color: white;
    font-size: 12px;
    font-family: 'DM Sans', Arial, Helvetica, sans-serif;
    border-collapse: collapse;

    .category {
        display: table-row;
        background: #14182a;

        >.name {
            display: table-cell;
            text-overflow: ellipsis;
            width: 100px;
            white-space: nowrap;
            padding: .4em 1em;
            color: white;
            border-bottom: 1px solid #393e53;
            border-right: 1px solid #393e53;
        }

        .results {
            display: table-cell;
            border-bottom: 1px solid #393e53;
        }

        .result {
            cursor: pointer;
            position: relative;
            display: block;
            overflow: hidden;
            padding: .4em 1em;
            width: 180px;
            border-bottom: 1px solid #393e53;
            &:last-child{
                border-bottom: none;
            }
            &:hover {
                background-color: #2a2c42;
            }
        }

		.content {
            color: #fff;
            padding-left: 10px;
        }

        .result-icon{
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translate(0%, -50%);
        }
    }
}