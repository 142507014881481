.workflow__container {
	display: flex;
	color: #ffffff;
	position: relative;

	.executionEnv__form{
		position: absolute;
		top: 20px;
		width: 70px;
		z-index: 2;
	}

	.LHS__title{
		// margin-bottom: 16px;
	}

	
}

.treeview__container {
	font-size: 12px;
	transition: width 0.5s;
	width: 235px;
	letter-spacing: 0.25px;

	&.treeview--minimized {
		width: 0;
		overflow: hidden;

		.executionEnv__form{
			display: none;
		}
	}
}

.css-12q0bj3{
	min-height: 100%;
}

.treeview__master {
	position: relative;
}

.treeview__inner {
	display: flex;
	position: absolute;
	left: 0px;
	transition: left 0.25s;
	overflow: hidden;

	& > div {
		width: 220px;
		margin-left: 10px;
	}

	&.showWorkflows {
		left: -235px;
	}

	&.showProfiles {
		left: -470px;
	}

	&.showClusters {
		left: -705px;
	}
}

.treeview {
	box-sizing: border-box;
	opacity: 90%;
	color: #ffffff;
	position: relative;
	letter-spacing: 0.15px;
	line-height: 14px;
	// transition: opacity 0.25s ease-in;
	opacity: 1;

	.search_reset{
		top: 26px;
	}
}

.hide__treeview {
	opacity: 0 !important;
}

.treeview-item__center {
	display: flex;
	justify-content: center;
	align-items: center;
}



.tree-item {
	border-left: 2px solid transparent;
}

.tree-item--active {
	background-color: rgba(255, 228, 194, 0.2) !important;
	text-decoration: none;
	border-left: 2px solid;
	border-color: rgb(255, 211, 153) !important;
}

.treeview__item--draggable {
	cursor: pointer;
}

.minimize-arrow {
	height: 18px;
}

.minimize-arrow img {
	width: 18px;
	height: 18px;
	transition: transform 0.5s;
	cursor: pointer;
}

.reverse__img {
	transform: rotate(180deg);
}

.WorkflowLHS__selection__box {
	font-size: 12px;
	letter-spacing: 0.15px;
	height: 42px;
	padding: 16px 12px 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #393e53;

	.dropdown__title {
		margin-top: -2px;

		img {
			opacity: 0.7;
		}
	}

	.dropdown--active img {
		opacity: 1;
	}

	.dropdown__menu {
		padding: 4px 0;
		top: 0px;
		left: 28px;
		background-color: #2a2c42;
		border-radius: 1px;
		box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
			2px 4px 8px 0 rgba(0, 0, 0, 0.32);
	}

	.dropdown__menu__item:hover {
		background-color: #0b0e1c;
		color: #ffffff;
	}
}

.WorkflowLHS__selectionOptions__outer {
	position: relative;
	width: 190px;
	height: 100%;
	overflow: hidden;
}

.WorkflowLHS__selectionOptions {
	display: flex;
	list-style: none;
	height: 100%;
	position: absolute;
	left: 1px;
	transition: left 0.25s;

	&.showProfile {
		left: -94px;
	}

	&.showClusters {
		left: -200px;
	}

	&.dagOptions{
		padding-left: 15px;

		.switchable__tab:first-of-type {
			margin-right: 33px;
		}
	}
}

.rstm-tree-item {
	display: flex;
	align-items: center;
	min-height: 18px;
	
	.tree-item-title {
		line-height: 18px;
		letter-spacing: 0.25px;
		margin-top: 0.5px;
		overflow-wrap: anywhere;

	}
}

.arrow {
	width: 16px;
	height: 16px;
	margin-right: 7px;
	display: inline-block;
	transition: transform 0.25s;
}

.arrow--active {
	transform: rotate(90deg);
}

.treeview__componentIcon {
	width: 14px;
	height: 18px;
	margin-right: 11px;
}

.treeview__list {
	clear: both;
	list-style: none;
	// change it to 230px after moving view as visual editor button
	height: calc(100vh - 310px);
	// padding-bottom: 100px;
	overflow: auto;
	margin-bottom: 0;
}

.tree-item {
	padding: 5px 0;
}

.treeitem__container {
	padding: 4px 0;
	font-weight: 400;

	&.treeitem__heading {
		font-weight: 600;
	}
}
.rstm-toggle-icon {
	display: inline-block;
}

.toggle-icon {
	display: inline-block;
}

.savedworkflows__box {
	/* width: 450px;   */
	text-align: center;
	padding: 5px;
	transition: width 0.5s;
	position: relative;
}

.saved__workflows__container {
	opacity: 1;
	.inPageSpinner {
		height: 494px;
		align-items: center;
	}

	.search__input__container {
		padding-top: 12px;
	}

	.userFiltering__box {
		padding: 12px 0;
		margin: 0 16px;
		font-size: 12px;
		border-bottom: 1px solid #393e53;
		letter-spacing: 0.25px;
		line-height: 14px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		& > span {
			letter-spacing: 0px;
		}

		form {
			width: 42%;
		}
	}

	.search__input__container {
		padding-bottom: 16px;
	}

	.innerWorkflowsTabs{
		.ant-tabs-bar{
			margin-bottom: 0;
		}
		.ant-tabs-nav{
			padding-left: 20px !important;
		}

		.directoryWorkflow__nav{
			.ant-tabs-bar{
				display: none;
			}
		}

		.ant-tabs-ink-bar{
			transition: none !important;
		}
		// padding-left: 10px;
		.ant-tabs-tab-prev-icon, .ant-tabs-tab-next-icon{
			color: white;
		}
	}
     
	.twoInnerTabs {
		margin-right: 15px !important;
	}
	
	.innerWorkflowsTabss{
		.ant-tabs-bar{
			margin-bottom: 0;
		}
		.ant-tabs-nav{
			padding-left: 7px !important;
		}

		.directoryWorkflow__nav{
			.ant-tabs-bar{
				display: none;
			}
		}

		.ant-tabs-ink-bar{
			transition: none !important;
		}
		// padding-left: 10px;
		.ant-tabs-tab-prev-icon, .ant-tabs-tab-next-icon{
			color: white;
		}
	}
}

.usersList__dropdown{
	.select-field-options-outer  {
		width: 150px !important;
		right: 0px;
		left: auto;
	}
	&.select-field {
		margin-top: -2px;
		margin-bottom: 0;
		// width: 40%;
	}

	.select-field-value {
		background-position: calc(100% - 2px);
		padding-right: 20px;
	}

	.select-field-option {
		overflow: hidden;
	}
}
.savedworkflows__box--minimized {
	width: 80px;
}
.saved__workflows__innerContainer,
.saved__profiles__innerContainer {
	.checkmark {
		opacity: 0.56;
		transform: scale(0.83);
	}

	.checkbox__container input:checked ~ .checkmark {
		opacity: 1;
	}

	.noWorkflows__msg{
		color: white;
		text-align: center;	
		margin-top: 150px;
	}
}
.zoom_icons_div {
	position: absolute;
	bottom: 24px;
	right: 24px;
	display: flex;
	background-color: transparent;
	flex-direction: column;
	border-radius: 12px;
	width: 36px;
	justify-content: space-between;
	align-items: center;
	padding: 0 8px;

	text-align: center;
	.upperBox {
		background-color: #2a2c42;
		border-radius: 8px;
		height: 36px;
		width: 36px;

		margin-bottom: 6px;
	}

	.lowerBox {
		background-color: #2a2c42;
		border-radius: 8px;
		width: 36px;
	}

	.separator {
		height: 1px;
		background-color: rgba(109, 112, 126, 0.32);
		width: 28px;
		margin-left: 4px;
	}

	.zoom_icon {
		height: 36px;
		// background: transparent;
	}
	.zoom_icon:focus {
		outline: none;
	}
}

.save__button {
	float: left;
}

.reset__button {
	float: right;
}

.workflow__master__container {
	width: calc(100% - 300px - 400px);
	flex-grow: 1;
	height: 100vh;
}

.workflow__tabs__container__outer {
	width: 100%;
	height: var(--workflowtabs-height);
	list-style: none;
	background-color: var(--workflow--tabs-bg);
	display: flex;
	justify-content: space-between;
}

.workflow__tabs__container {
	--workflow--tabs-bg: #2a2c42;
	--workflow--tab--active-bg: #030613;
	width: 100%;
	height: var(--workflowtabs-height);
	list-style: none;
	background-color: var(--workflow--tabs-bg);
	margin-bottom: 0;
	overflow: hidden;
	display: inline-block;
	white-space: nowrap;
	&.reduce__width {
		.workflow__tab {
			width: 5% !important;
		}
	}
}

.workflow__tabs__container:hover {
	overflow: auto;
	overflow-y: hidden;
}

.workflow__tabs__container li:hover {
	cursor: pointer !important;
}

.workflow__tabs__container ::-webkit-scrollbar {
	width: 10px;
}

.workflow__tab {
	// width: 15%;
	min-width: 72px;
	max-width: 160px;
	height: 100%;
	// float: left;
	display: inline-block;
	font-size: 12px;
	letter-spacing: 0.2px;
	font-weight: 500;
	padding: 6px 10px 6px 0;
	position: relative;
	color: #c7c7cd;
	flex-grow: 1;

	&:first-child .workflow__tab__inner {
		border-left-color: transparent;
	}

	&:last-child .workflow__tab__inner {
		border-right: 1px solid #6d707e;
		padding: 0 10px 0 0;
	}

	&:after,
	&:before {
		// https://css-tricks.com/tabs-with-round-out-borders/ - for explanation
		// for rectangle
		display: none;
		content: '';
		height: 4px;
		border: 4px solid transparent;
		position: absolute;
		right: -8px;
		z-index: 0;
		bottom: 0px;
	}

	&:before {
		left: -8px;
	}

	&:after {
		right: -8px;
	}

	&:first-child {
		// hide left side outward curve for first workflow tab

		&:before {
			border-color: transparent;
		}

		.workflow__tab__inner:before {
			border-color: transparent;
		}

		&:hover .workflow__tab__inner {
			border-left-color: rgba(109, 112, 126, 0.32);
		}
	}

	&:hover {
		background-color: #4f5263;

		&:after,
		&:before {
			display: block;
			border-color: #4f5263;
		}

		&:after {
			z-index: 1;
		}

		.workflow__tab__inner {
			// hide left side white border
			border-left-color: transparent;

			&:after,
			&:before {
				display: block;
				border-color: var(--workflow--tabs-bg);
			}

			&:after {
				z-index: 2;
			}
		}

		& + .workflow__tab .workflow__tab__inner {
			// hide right side white border
			border-left-color: transparent;
		}

		& + .workflow__tab--active {
			// this is to hide hover's state border on hover
			border-right: none !important;
			.workflow__name__box svg {
				z-index: 3;
				position: relative;
			}

			.workflow__tab__inner:before {
				// display: ;
				// left: -1px;
				z-index: 2;
				border-color: #4f5263;
				// transform: scale(-1, 1);
				// border-color: #030613;
				// border-color: green;
			}

			.hide__hoverCurve {
				position: absolute;
				bottom: 0px;
				left: 0px;
				height: 10px;
				border-top-right-radius: 6px;
				width: 20px;
				z-index: 2;
				background-color: var(--workflow--tab--active-bg);
			}
		}
	}

	&:first-child:hover {
		&:before {
			display: none;
		}

		.workflow__tab__inner::before {
			display: none;
		}
	}
}

.workflow__tab__inner {
	width: 100%;
	height: 100%;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	border-left: 1px solid rgba(109, 112, 126, 0.32);

	&:after,
	&:before {
		// for circle
		display: none;
		content: '';
		border: 10px solid transparent;
		border-radius: 10px;
		border-top: 0px;
		position: absolute;
		bottom: -6px;
		z-index: 1;
	}

	&:before {
		left: -21px;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
		// border-right: 0px;
	}

	&:after {
		right: -30px;
		border-top-left-radius: 0;
		border-bottom-right-radius: 0;
		// border-top-right-radius: 0;
	}
}

.workflow__state {
	border-radius: 100%;
	width: 8px;
	height: 8px;
	box-sizing: content-box;
	margin-right: 2px;

	&.--unsaved {
		background-color: #ffffff;
	}

	&.--saved {
		border: 2px solid #2a2c42;
		background-color: transparent;
	}
}

// .tab__toolTip {
// 	.ant-tooltip-arrow::before {
// 		background-color: rgba(255, 255, 255, 0.2);
// 	}
// 	.ant-tooltip-inner {
// 		font-size: 12px;
// 		background-color: rgba(255, 255, 255, 0.2);
// 	}
// }

.workflow__name__box {
	margin-left: 10px;
	overflow: hidden;
	// max-width: 92px;
	// text-overflow: ellipsis;
	white-space: nowrap;
    display: flex;
    align-items: center;
	flex-wrap: wrap;
	margin-right: 5px;

	svg {
		// to hide the outward curve
		z-index: 2;
		position: relative;
		opacity: 0.56;
	}
}
.workflow__name {
	margin-left: 5px;
	line-height: 24px;
	max-width: 75px;
}

.workflow__tab--active .workflow__tab__inner {
	border-right: none !important;
}

.workflow__tab--active,
.workflow__tab--active:hover {
	// padding: 6px 0;
	// active state on hover should show the active state
	color: #ffffff;

	background-color: var(--workflow--tab--active-bg);
	// background-image: url(../../assets/background-pattern.png);
	// background-repeat: no-repeat;
	// background-size: auto;
	&:after,
	&:before {
		display: block;
		border-color: #030613;
	}

	&::after {
		z-index: 1;
	}

	.workflow__tab__inner {
		// height: 36px;
		border-left-color: transparent;

		img {
			opacity: 1;
		}

		&:after,
		&:before {
			display: block;
			border-color: var(--workflow--tabs-bg);
		}

		&:after {
			z-index: 2;
		}
	}

	& + .workflow__tab .workflow__tab__inner {
		border-left-color: transparent;
	}

	& + .workflow__tab:hover {
		cursor: pointer;
		&:before {
			display: none;
		}

		.workflow__tab__inner::before {
			// display: none;
			left: -1px;
			z-index: 2;
			transform: scale(-1, 1);
			border-color: #4f5263;
		}
	}
}

.close_tab_icon {
	padding: 0.5px;
	cursor: pointer;

	svg {
		height: 9px;
		width: 9px;
	margin-top: 6px;

	}
	// &:hover{
	// 	height: 12px;
	// 	width: 12px;
	// }
}

.workflow__add__tab {
	// height: 100%;
	// margin-top: 6px;
	// margin-left: 11px;
	// margin-right: 10px;
	border-left: 1px solid rgba(109, 112, 126, 0.32);
	margin-top: 6px;
	display: inline-block;
	position: relative;
	img {
		margin-left: 10px;
		padding: 3px;
		height: 24px;
		width: 24px;
		font-size: 18px;
		cursor: pointer;
		position: relative;

		&:hover {
			z-index: 1;
		}
	}
}

.workflow__add__tab img:hover,
.close_tab_icon:hover,
.workflow__tab__util button img:hover {
	border-radius: 50%;
	background-color: lightslategrey;
	box-shadow: 0 0 0 3px lightslategrey;
}

.savedworkflows__buttonsbox {
	display: flex;
	margin-bottom: 10px;
	flex-wrap: wrap;
	justify-content: space-between;
}

.canvas__master {
	position: relative;
	display: flex;
	height: calc(100vh - var(--workflowtabs-height));
	flex-flow: wrap column;
}

.workflowCanvas__outer {
	height: 100%;
	width: 100%;
	circle {
		fill: #ffffff;
	}
}
.canvas {
	height: 100%;
	position: relative;
	transition: width 0.5s;
	width: 100%;
	background-size: 50px 50px;
	display: flex;
	overflow: hidden;
	background-color: #030613;
	// background-image: url(../../assets/background-pattern.png);
	// background-image: url(../../assets/BG-Dot-Pattern.png);
	background-image: linear-gradient(
			0deg,
			transparent 24%,
			rgba(255, 255, 255, 0.05) 25%,
			rgba(255, 255, 255, 0.05) 26%,
			transparent 27%,
			transparent 74%,
			rgba(255, 255, 255, 0.05) 75%,
			rgba(255, 255, 255, 0.05) 76%,
			transparent 77%,
			transparent
		),
		linear-gradient(
			90deg,
			transparent 24%,
			rgba(255, 255, 255, 0.05) 25%,
			rgba(255, 255, 255, 0.05) 26%,
			transparent 27%,
			transparent 74%,
			rgba(255, 255, 255, 0.05) 75%,
			rgba(255, 255, 255, 0.05) 76%,
			transparent 77%,
			transparent
		);
	background-size: 50px 50px;

	// background-repeat: repeat;
	// background-size: auto;
	cursor: default !important;

	&:active {
		cursor: move;
	}

	& > svg {
		overflow: visible;
		// z-index: 2;
	}

	& > div {
		// z-index: 1;
	}
}

.canvasDummy {
	position: absolute;
	display: none;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 3;

	&.disableActions {
		display: block;
	}

	&.spinnerDiv{
		height: 100%;
		background-color: rgba(102, 106, 141, 0.1);

		.inPageSpinner{
			height: 100%;
			align-items: center;
		}

		.lds-ring div {
			border-width: 4px;
			border-color: #6D707E transparent transparent transparent;
		}
	}
}

.noworkflows__msg {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;

	.workflow__actions {
		cursor: pointer;
		text-decoration: underline;
		color: #fcad01;
	}
}

.canvas > * {
	height: 100%;
	min-height: 100%;
	width: 100%;
}
.sql_editor{
	width: 370px;
}

.properties__container {
	width: 400px;
	transition: width 0.5s;
	position: relative;
	padding-top: 6px;
	height: 100vh;
	background-color: #2a2c42;;
	// overflow: hidden;

	&.properties__container--minimized {
		width: var(--sidebar-width);
		flex-shrink: 0;

		.user__dropdown {
			padding-right: 18px;
		}
	}

	.user__dropdown {
		position: initial;
		padding-right: 5px;
		transition: padding-right 0.25s;
	}

	.treeview__header {
		padding-left: 16px;
		padding-top: 0px;
		height: 24px;
		border-bottom: 1px solid #393e53;
	}

	.properties__nomessage {
		line-height: 70vh;
		text-align: center;
		opacity: 0.32;
	}
	.tableHolder,
	.inputfield__container,
	.radiofield__container,
	.select-field {
		padding: 16px 36px 16px 16px;
		border-bottom: 1px solid #393e53;
		margin-bottom: 0px;
	}

	.tableHolder {
		table {
			border-spacing: 3px;
			border-collapse: separate;
		}
		td {
			padding: 1px;
			padding-bottom: 4px;
		}
		.inputfield__container {
			// @extend .inputfield;
			padding:0;
		}

		

		.key__placeholder{
			text-align: center;
			// font-size: 12px;
			opacity: 0.32;
		}

		.value__placeholder{
			text-align: center;
			// font-size: 12px;
			opacity: 0.32;
		}

		.keyValue__placeholder{
			td {
				text-align: center;
				padding-bottom: 0;
				opacity: 0.32;
			}
		}

		&.tableComponent{
		input {
			// @extend .inputfield;
			width: 100%;
			display: block;
			background-color: #14182a;
			border-radius: 2px;
			height: 28px;
			border: transparent;
			padding: 4px 8px;
			color: #ffffff;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.25px;
			line-height: 18px;
			border: 1px solid transparent;
			border-radius: 2px;
		}
	}
	}



	.radiofield__inner__container {
		margin-bottom: 0px;
		padding: 0px;
	}

	.properties__fields__container {
		height: calc(100vh - 135px);
		overflow-y: auto;
		padding-bottom: 100px;
		.monaco-editor .suggest-widget {
			width:270px;
		}
	}

	.properties__btn__box {
		// height: 72px;
		background-color: #2a2c42;
		box-shadow: 0 -4px 8px 0 rgba(3, 6, 19, 0.16);
		width: 100%;
		padding: 16px 16px 32px;

		.btn-yellow {
			margin-right: 24px;
		}
		// z-index: 0;
	}
	.changes__msg {
		margin-left: 12px;
		color: #ffffff;
		font-size: 10px;
		font-weight: 400;
		line-height: 16px;
		opacity: 0.5600000023841858;
		text-align: right;

		&.changes__msg--unsaved {
			color: #ff7761;
		}
	}

	.properties__toggle {
		display: none;
		position: absolute;
		top: 50%;
		left: -12px;
		border-radius: 50%;
		background: #2a2c42;
		transition: transform 0.5s;

		#path-1props-toggle {
			display: none;
		}

		&:hover {
			#path-1props-toggle {
				display: inline;
				fill: #42526e;
			}
			#mask-2props-toggle {
				fill: #6d707e;
			}
			#Stroke-3props-toggle {
				fill: #fff;
			}
		}
	}

	&:hover {
		.properties__toggle {
			display: block;
		}
	}

	.properties__tab {
		text-align: center;
		margin-top: 24px;

		.tabs__separator {
			margin-left: auto;
			margin-right: auto;
		}

		.tabs__box .tabscategory__name {
			display: none;
		}
	}

	.switchable__tab{
		// margin-right: 34px;
	}
}

.output__console {
	z-index: 4;
	background-color: #14182a;
	flex: 1;
	min-height: 50px;
	color: #c7c7cd;
	font-size: 12px;
	line-height: 18px;
	// padding: 12px 12px 0 16px;
	width: 100%;
	// 

	span {
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-o-user-select: text;
		user-select: text;
	}

	.output__console__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #ffffff;
		font-family: 'DM Sans';
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0.25px;
		line-height: 18px;
		opacity: 0.72;
		width: 100%;
		border-bottom: 1px solid #393E53;
		padding: 0 16px;
		height: 35px;

		span {
			padding-right: 8px;
		}


		button {
			padding-left: 12px;

			&:disabled {
				cursor: default;
				opacity: 0.3;
			}

			&:nth-of-type(2){
				padding-left: 9px;
			}
		}

		.horizontal__line {
			height: 1px;
			background-color: #393e53;
			flex: 1;
		}
	}

	#btn__downloadLogs{
		padding-top: 1px;
		padding-left: 8px;
	}

	.output__console__tabs__box{
		align-self: flex-end;

		.btn-consoleTab{
			padding-left: 0 !important;
			text-transform: uppercase;

			margin-right: 32px;
		}
	}

	
	
}

code {
	user-select: text;
}

.output__text {
	padding-top: 5px;
	padding-left: 16px;
	padding-bottom: 15px;
	padding-right: 5px;
	height: calc(100% - 35px);
	overflow: auto;
	display: flex;
	flex-direction: column;
	// word-break: keep-all;
	// white-space: nowrap;
	margin-bottom: 0;
	width: 100%;
	color: white;
	margin-bottom: 0;
}

.canvas__minimap {
	position: absolute;
	bottom: 20px;
	left: 20px;
	height: 200px;
	width: 150px;
}

.inputfield:focus {
	outline-offset: 0px;
	outline: none;
}

.workflows__options__toggleAllContainer {
	display: flex;
	.checkbox__container {
		margin-top: 2px;
		margin-right: 10px;
	}
}

.saved__workflows__options {
	// height: 48px;
	padding: 10px 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.checkmark {
		opacity: 0.56;
	}

	.checkbox__container input:checked ~ .checkmark {
		opacity: 1;
	}

	&.selectedState{
		justify-content: flex-end;
		padding-bottom: 13px;
		// flex-direction: row-reverse;
	}
	
}
.sortByWorkflows__dropdown{
	width: 100px;

	.activeMenuItem{
		font-weight: 700;
	}
}

.common__workflow__icons {
	// width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	img {
		margin-left: 4px;
		width: 24px;
		height: 24px;
	}

	.export__icon {
		width: 26px;
		height: 26px;
		transform: rotate(270deg);
	}

	.dropdown__menu--right {
		margin-top: 4px;
		width: 120px;
	}

	.dropdown__master {
		margin-left: 4px;
		.dropdown__threedots {
			padding: 3px 1px;
		}

		.dropdown__menu {
			padding: 4px 0;
			background-color: #2a2c42;
		}

		img {
			margin-left: 0;
			height: 16px;
			width: 16px;
		}
	}
}

.vertical__separator {
	height: 20px;
	border: 0.5px solid #d4d6e0;
}

.dropdown--active .dropdown__triangle,
.dropdown--active .dropdown__threedots {
	background-color: #0b0e1c;
}

.dropdown__triangle {
	display: inline-block;
	color: #c7c7cd;
	font-size: 1.2em;
	width: 16px;
	height: 20px;
	text-align: center;
	cursor: pointer;
	border-radius: 4px;

	&:hover {
		background-color: #0b0e1c;
	}
}

.dropdown__threedots {
	padding: 2px 1px;
	border-radius: 4px;
	cursor: pointer;
	margin-left: 3px;

	&:hover, &.showHoverState {
		background-color: #0b0e1c;
	}

	img {
		margin-bottom: 3px;
		height: 16px;
		width: 16px;
		margin-left: 0px;
	}
}

.btn_new_component {
	margin: 0 16px 0 0;
}

.new__workflow__modal {
	width: 400px;

	.inputfield__label {
		color: #fff;
	}
	.inputfield__container {
		margin-bottom: 18px;
	}

	.newDirectory__inputField{
		margin-bottom: 18px;

		.nameProject__title{
			position: absolute;
			font-size: 10px;
			color: white;
			letter-spacing: 0.25px;
			margin-top: 6px;
			line-height: 16px;
		}

		.inputfield__errormessage{
			position: absolute;
		}

		.search_reset{
			right: 5px;
			top: 28px;
			width: 14px;
			height: 14px;
		}
	}

	.select-field{
		margin-bottom: 16px;
	}

	.incorrectEnv__prompt{
		display: flex;
		color: white;
		font-size: 12px;
		line-height: 18px;
		column-gap: 4px;
		margin-bottom: 12px;

		svg {
			width: 24px;
			height: 16px;
		}
	}
}

#import-workflow-in-different-env{
	color: #fcad03;
	text-decoration: underline;
}

.new__workflow__modal_componentform {
	width: 1050px;

	.inputfield__label {
		color: #fff;
	}
	// .inputfield__container {
	// 	// margin-bottom: 30px;
	// }
}

.btn-cancel {
	margin-right: 18px;
	color: #fff;
}

.workflow__item {
	padding: 0 12px 0 5px;
	height: 32px;
	display: flex;
	// flex-flow: row wrap;
	margin-bottom: 1px;
	// justify-content: space-between;
	align-items: center;

	.checkbox__container {
		padding: 13px;

		.checkmark {
			top: 5px;
			left: 5px;
		}
		&:hover {
			background-color: #2a2c42;
			border-radius: 50%;
		}
	}

	&:hover, &.workflow___hoverState {
		background-color: #666a8d;

		.checkmark {
			opacity: 1;
			border-color: #ffffff;
		}

		.workflow__title {
			width: 210px;
			// color: #ffffff;
			font-weight: 600;
		}

		.workflow__icons {
			display: flex;
		}

		#innerStar {
			fill: #ffffff;
		}
	}

	&.workflow__item--selected {
		background-color: #fcad01;

		.workflow__title {
			color: #14182a;
			font-weight: 600;
		}

		.checkbox__container {
			&:hover {
				background-color: #ffe4aa;
				border-radius: 50%;
			}

			.checkmark {
				background-color: #15162f;
				border-color: #15162f;

				&::after {
					border-color: #ffffff;
				}
			}
		}

		.workflowFavoriteIcon:hover {
			background-color: #ffe4aa;
		}

		// #Path-TDI-Outer {
		// 	fill: #2A2C42;
		// }

		& > .dropdown__master {
			display: none;
			// background-color: #a7abb9;
		}

		#innerStar {
			fill: #1e2038;
		}

		#innerStar--active {
			fill: #15162f;
		}
	}

	&.workflow__item--starred {
		.checkmark {
			opacity: 1;
			border-color: #ffffff;
		}

		.workflow__title {
			font-weight: 600;
		}
	}

	&.workflow__disableHoverState {
		.workflow__icons {
			display: none;
		}
	}

	&.workflow__item--active{
		background-color: #666a8d;

		.workflow__title {
			color: #ffffff;
			opacity: 0.56;
		}

		#innerStar {
			fill: #ffffff;
			opacity: 0.7;
		}
	}

	&.workflow___noPermissions{
		.workflow__title {
			width: 215px;
		}
	}

	.workflow__icons {
		margin-left: 2px;
		display: none;
	}

	.workflow__title {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		height: 100%;
		line-height: 32px;
		color: #d4d6e0;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		margin-bottom: 0;
		cursor: pointer;
		margin-left: 6px;
	}

	.favoriteIcon__btn {
		padding-top: 3.5px;
	}

	.workflowFavoriteIcon {
		cursor: pointer;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		padding: 2px;

		&:hover {
			fill: #ffffff;
			background-color: #2a2c42;
		}

		&:focus {
			background-color: #15162f;
		}
	}

	.inPageSpinner{
		height: 24px;
		width: 24px;
		margin-right: 2px;

		.lds-ring{
			transform: scale(0.5);
			margin-left: -8px;
		}
	}

	
}

.save__workflow__btn:focus {
	outline: none;
}

.box__for__resizing__terminal {
	width: 100%;
	position: relative;
	display: block;
	height: 20px;
	cursor: n-resize;
	// position: absolute;
	top: -3px;
	background-color: red;

	//   &::after {
	//   content: '';
	//   display: block;
	//   height: 20px;
	//   width: 100%;
	//   cursor: n-resize;
	//   position: absolute;
	//   top: -3px;
	//   background-color: red;
	// }
}

.deleteWorkflowModal {
	width: 375px;
	color: #fff;

	.btn-yellow {
		margin-left: 10px;
	}


	&.deleteMultipleItemsModal {
		width: 480px;

		.modal__header{
			padding: 0;
			padding-top: 12px;
		}

		.deleteDirectory {
			flex-direction: row;
			justify-content: flex-end;

			button {
				margin-right: 16px;

				&:last-of-type{
					margin-right: 0px;
				}
			}
		}

		.preserveWorkflows__msg{
			display: flex;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.25px;
			color: #C7C7CD;

			svg{
				width: 22px;
				height: 22px;
				margin-right: 4px;
			}
		}

		.modalBtns__box{
			margin-top: 32px;
		}
	}
}

.deleteWorkflowModal__areYouSureMsg {
	font-weight: 700;
	letter-spacing: 0.25px;
	line-height: 21px;
	margin-bottom: 12px;
	font-size: 14px;
}

.deleteWorkflowModal__warningMsg {
	color: #ff7761;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.25px;
	line-height: 18px;
	margin-bottom: 48px;
}

.runWorkflowModal__container {
	overflow-y: auto;
}

.runWorkflowModal__container {
	max-height: 400px;
}

.editConnection_container {
	max-height: 550px;
}

.hierarchyModalContainer {
	width: 430px;
	max-height: none;
}

.hierarchyModalForm {
	width: 424px !important;
}

.runWorkflowModal__container .inPageSpinner, .editConnection_container .inPageSpinner {
	height: 400px;
	align-items: center;
	width: 396px;
}

.runWorkflowModal__container .modal__header, .editConnection_container .modal__header {
	background-color: rgba(0, 0, 0, 0.239999994635582);
	text-align: center;
	position: relative;
}

.runWorkflowModal__container .modal__title, .editConnection_container .modal__title {
	margin-bottom: 8px;
}

.runWorkflowModal__container .modal__title .previewIcon, .editConnection_container .modal__title .previewIcon {
	margin-right: 5px;
	margin-top: -1px;
}

.runWorkflowModal__container .modal__body, .editConnection_container .modal__body {
	padding: 0;
	padding-bottom: 16px;
}

.runWorkflowModal__container .modalBtns__box, .editConnection_container .modalBtns__box {
	padding-right: 24px;
}

.runWorkflowModal__container .modal__subtitle, .editConnection_container .modal__subtitle {
	color: #ffffff;
	opacity: 0.72;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.25px;
}

.runWorkflowModal__container .steps__progress, .editConnection_container .steps__progress {
	left: 0;
	top: 83px;
}

.runWorkflowModal__container .steps__progress .step1, .editConnection_container .steps__progress .step1 {
	left: 20%;
}

.runWorkflowModal__container .steps__progress .progress__bar, .editConnection_container .steps__progress .progress__bar {
	width: 25%;
	transition: width 10s;
}

.runWorkflowModal__container .steps__progress .progress__bar.progress__step3--slow, .editConnection_container .steps__progress .progress__bar.progress__step3--slow {
	width: 92%;
}

.runWorkflowModal__container .steps__progress .step3, .editConnection_container .steps__progress .step3 {
	right: 10%;
}


.runWorkflow__form,
.scheduleworkflow__form {
	width: 396px;
	padding: 12px 0 16px 24px;

	.profileField {
		padding-bottom: 6px;
		margin-right: 24px;
		margin-bottom: 16px;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			height: 1px;
			bottom: 0px;
			left: -8px;
			width: calc(100% + 16px);
			background-color: #6d707e;
		}
	}

	.inputfield__container,
	.select-field {
		margin-bottom: 12px;
	}

	.toggleField__container,
	.radiofield__inner__container {
		margin-bottom: 18px;
	}

	.radiofield__container {
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 18px;
	}

	.runWorkflow__innerForm {
		max-height: 50vh;
		overflow-y: auto;
		padding-right: 24px;
	}

	.scheduleworkflow__formFields {
		max-height: calc(100vh - 400px);
		overflow: auto;
		padding-right: 15px;
		margin-right: -15px;
	}
}

.pagination__container {
	// display: flex;
	padding-left: 12px;
	padding-right: 12px;
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
	position: relative;
	min-height: 30px;
	flex-direction: row-reverse;

	&.align__end{
		flex-direction: row;

	}

	.pagination__searchResultsNumber {
		display: inline-block;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 18px;
		opacity: 0.72;
		max-width: 90px;
		word-wrap: break-word;
		max-height: 72px;
		overflow: hidden;
	}

	.pagination__main {
		margin-top: -3px;
		// position: absolute;
		top: 15px;
		right: 12px;
	}
}

.nodeTypeInner {
	min-width: 110px;
	border-style: none;
}

.floatingToolbar__container {
	background-color: #42475d;
	border-radius: 4px;
	// width: 570px;
	height: 40px;
	padding: 0 8px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	z-index: 3;
	right: 36px;
	top: 32px;

	button:disabled img {
		opacity: 0.5;
	}
	> button:not(.btn-editor):not(.dag__scheduleBtn) {
		position: relative;
		height: 100%;
		align-items: center;
	}

	span {
	// tooltips are converting buttons to span
		cursor: default !important;

		&.btn-sm.btn-yellow{
			color: #262231;
			background-color: #543a00;
			box-shadow: none;
			padding-top: 1.5px;
	
			button {
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 0.25px;
				text-align: center;
			}
		}
	}


	#sixdots__icon {
		opacity: 0.5;
		transition: opacity 0.25s;
	}

	.sixdots__span {
		cursor: move !important;
		padding-top: 5px;

		&:hover #sixdots__icon {
			opacity: 1;
		}
	}

	.btn-yellow img {
		margin-top: -4px;
	}

	.innder {
		width: 100%;
	}

	.btn-save__main {
		display: flex;

		.dropdown__master.dropdown--disabled .btn-yellow.btn__dropdown__triangle {
			background-color: #543a00;
			box-shadow: none;
		}

		.runWorkflow__options .dropdown__menu{
			top: 24px;
		}
		
	}

	

	.btn-save {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.dropdown__menu {
		margin-top: 2px;
		background-color: #2a2c42;
		padding-top: 4px;
		width: 108px;
	}

	.dropdown__menu__item {
		text-align: right;
		color: #ffffff;
		font-size: 12px;
		font-weight: 700;
		padding-right: 6px;

		&:hover {
			color: #14182a;
		}
	}

	

	.vertical__separator {
		height: 32px;
		opacity: 0.1;
		border-color: #030613;
	}

	.select-field {
		width: 82px;
		margin-bottom: 0px;

		label {
			display: none;
		}

		.select-field-value {
			background-color: #14182a;
			border-radius: 2px;
			background-position: calc(100% - 4px);
		}
	}

	.dag__scheduleBtn{
		display: flex;
		align-items: center;
	}
}

.btn__dropdown__triangle {
	border-left: 1px solid rgba(66, 71, 93, 0.319);
	font-size: 1em;
	width: 15px;
	height: 24px;
	text-align: center;
	cursor: pointer;
	border-radius: 0 4px 4px 0;
	display: block;
	line-height: 22px;
}

.runWorkflow__options {
	display: flex;
	align-items: center;
	
	.dropdown__menu {
		width: max-content;
		top: 30px;
		.dropdown__menu__item {
			text-align: left;
			font-weight: 700;
			padding-right: 6px;
			// padding-left: 8px;
		}

		li:first-child{
			font-weight: bold;
			font-size: 10px;
			line-height: 16px;
			color: #9495A0;
			text-align: right;
			opacity: 1;

			&:hover{
				background-color: transparent;
				color: #9495A0;
			}
		}

	}
}

.scheduleWorkflowModal__container {
	width: 400px;
	position: relative;

	.modal__title {
		margin-bottom: 0px;
	}

	.modal__header {
		text-align: center;
		background-color: rgba(0, 0, 0, 0.239999994635582);
		padding-bottom: 2px;
	}

	.modal__body {
		margin-top: 65px;
		padding: 0;
		padding-bottom: 16px;
		// padding: 12px 24px 24px;
	}

	.steps__progress {
		top: 60px;
	}
}

.scheduleWorkflow__steps {
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	width: 100%;
	position: absolute;
	top: 35px;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.239999994635582);

	li {
		text-align: center;
		width: 49%;
		height: 24px;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 0.25px;
		line-height: 18px;
		color: rgba($color: white, $alpha: 0.56);
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 18px 0;

		&:first-child {
			width: 51%;
			border-right: 2px solid #6d707e;
		}

		img {
			margin-right: 10px;
			opacity: 0.56;
		}
	}
	.activeTab {
		opacity: 1;
		color: rgba($color: white, $alpha: 1);

		img {
			opacity: 1;
		}
	}
}

.steps__progress {
	height: 4px;
	background-color: #9495a0;
	width: 100%;
	position: absolute;
	top: 60px;

	.progress__bar {
		display: block;
		height: 4px;
		background-color: #fcad01;
		border-radius: 0 2px 2px 0;
		box-shadow: 0 0 4px 0 rgba(252, 173, 1, 0.72);
		width: 23%;
		transition: width 0.25s;

		&.progress__step2 {
			width: 69%;
		}
	}

	& > div {
		position: absolute;
		top: -4px;
		background-color: #42475d;
		border: 2.5px solid #c7c7cd;
		border-radius: 100%;
		width: 12px;
		height: 12px;
	}

	.step1 {
		left: 18%;
	}

	.step2 {
		left: 64%;
	}

	.step3 {
		right: 5%;
		background-color: #c7c7cd;

		&:before {
			content: '';
			position: absolute;
			left: -1px;
			border-radius: 100%;
			border: 2px solid #9495a0;
			position: absolute;
			height: 8px;
			width: 8px;
			top: -1px;
		}
		// border-color: #9495A0;
	}
}

.scheduleworkflow__form {
	padding-right: 24px;

	.modalBtns__box {
		padding-right: 0px;
	}

	.inputfield__container,
	.select-field {
		margin-bottom: 18px;
	}
}
.scheduledTimes__container {
	border-top: 1px solid #393e53;
	padding-top: 12px;
	margin-bottom: 30px;
	color: #59ceff;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 0.25px;
	line-height: 16px;
	position: relative;
	

	.scheduledTimes__list {
		list-style-position: inside;
		width: 100%;
		display: flex;
		padding-top: 6px;

		li:before {
			content: '';
			display: inline-block;
			height: 10px; // or px or em or whatever
			margin-left: -5px; // or whatever space you want
		}
	}

	.scheduledTimes__LHS {
		margin-right: 15px;
	}
	._title {
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 18px;
		display: block;
		margin-bottom: 6px;
	}

	.activeTime__field{
		position: absolute;
		right: 0;
		top: 14px;
	}
}

.managePorts__modal {
	width: 330px;

	.modal__header {
		// padding: 16px 16px 0 24px;
		// padding-top: -16px;
		background-color: rgba(0, 0, 0, 0.239999994635582);
	}

	.modal__title {
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.25px;
		line-height: 21px;
		margin-bottom: 8px;
	}

	.modal__subtitle {
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 18px;
		opacity: 0.7199999690055847;
	}

	.option__label {
		color: #ffffff;
	}

	.radiofield__inner__container {
		margin-bottom: 15px;
	}

	.radiofield span.label {
		transition: none;
	}

	.warning__msg {
		color: #ff3d4f;
		font-size: 10px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 16px;
		display: block;
		margin-top: 8px;
		margin-bottom: 8px;
	}
	.modalBtns__box {
		margin-top: 20px;
	}

	.portTicker__Btnbox{
		margin-right: 40px;
	}
}

.editor-hidden {
	display: none;
}

.portTickers__main {
	display: flex;
}
.portTicker__box {
	.portType {
		display: block;
		color: #ffffff;
		letter-spacing: 0.25px;
		line-height: 18px;
		margin-bottom: 8px;
		font-size: 12px;
	}
}

.portTicker__Btnbox {
	// height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	// margin-right: 40px;
	// line-height: 24px;
	button {
		background-color: #fcad01;
		box-shadow: 0 1px 4px 0 rgba(252, 173, 1, 0.72);
		width: 24px;
		height: 24px;
		color: #2a2c42;
		font-size: 30px;
		position: relative;

		&:disabled {
			background-color: #543a00;
			box-shadow: none;
			cursor: default;
		}
	}

	.increment__btn {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		span {
			position: absolute;
			top: -12px;
			left: 3px;
		}
	}

	.decrement__btn {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;

		span {
			position: absolute;
			width: 20px;

			top: -12px;
			right: 2px;
		}
	}

	.countText {
		display: inline-block;
		text-align: center;
		width: 32px;
		height: 24px;
		background-color: #14182a;
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 24px;
		&.disabled {
			opacity: 0.7;
		}
	}
}

.profiles__container,
.saved__workflows__container {
	.dropdown__menu {
		background-color: #2a2c42;
		box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
			2px 4px 8px 0 rgba(0, 0, 0, 0.32);
		// width: 79px;
		padding: 4px 0;
	}

	.dropdown__menu__item:hover {
		background-color: #666a8d;
		color: #fff;
		font-weight: 600;
		letter-spacing: 0.25px;
	}

	.component_action_wrapper .btn__new__action {
		img{
			margin-bottom: 2px;
			margin-right: 5px;
		}
		span {
			top: 0px;
		}
	}
}

.modal__body section {
	margin-left: 0px;
}
.profiles__container {
	.inPageSpinner {
		// height: 633px;
		align-items: center;
	}

	.activeProfile__box {
		margin: 0 16px;
		padding: 12px 0;
		border-bottom: 1px solid#6D707E;
		letter-spacing: 0.25px;
		line-height: 18px;
		font-size: 12px;

		._title {
			display: block;
			color: #ffffff;
			font-weight: 700;
			margin-bottom: 8px;
		}

		img {
			margin-right: 6px;
			margin-top: -5px;
		}

		._name {
			color: #d4d6e0;
			font-weight: 400;
			max-width: 160px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.toggleField__container {
			display: inline-flex;
			margin-left: 10px;
		}

		.activeProfile__innerBox {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 18px;
		}
	}

	.squareHover {
		width: 16px;
		padding: 3px 0;
		height: 22px;
		width: 16px;
		border-radius: 2px;
	}
	.squareHover:hover {
		background-color: #14182a;
	}

	#Path-TDI-Outer {
		fill: #9fa0a9;
	}

	.workflow__title {
		cursor: default;
		width: 120px;

		margin-right: 20px;
	}

	.search__input__container {
		padding-top: 12px;
	}

	.checkbox__container {
		margin-right: 10px;
	}

	.workflow__item {
		padding-right: 5px;

		.dropdown__master {
			height: auto;
			margin-top: 5px;
		}

		&:hover {
			.workflow__title {
				width: 120px;
			}

			.toggleField {
				opacity: 1;
			}
		}

		&.workflow__item--selected {
			.toggleField {
				border-color: #2a2c42;

				.toggleDot {
					background-color: #2a2c42;
				}

				&.active {
					background-color: #2a2c42;
					.toggleDot {
						background-color: #fcad01;
					}
				}
			}

			// .workflowFavoriteIcon{
			// 	#Shape{

			// 	}
			// }
		}
	}

	.workflow__item.workflow__disableHoverState:hover .workflow__title {
		width: 155px;
	}

	.workflow__item.workflow___noPermissions:hover .workflow__title {
		width: 155px;
	}

	.toggleField {
		border-color: rgba(255, 255, 255, 0.56);
		opacity: 1;
		margin-right: 8px;

		&.active {
			border-color: #ffa900;
		}
	}

	.workflow__item .dropdown__menu {
		height: 128px;
	}

	.dropdown--active {
		.squareHover {
			background-color: #14182a;
		}
	}

	// .workflow__item:focus-within .dropdown--active {
	// 	color: red;
	// }

	.search__input__container{
		padding-bottom: 0;
	}
}

.profileModal__container {
	.modal__header {
		background-color: rgba(0, 0, 0, 0.239999994635582);
	}

	.modal__title {
		margin-bottom: 8px;
	}

	.modal__subtitle {
		color: #ffffff;
		opacity: 0.72;
		font-size: 12px;
	}

	.modal__body {
		padding-top: 1px;
		padding-left: 0px;
		padding-right: 0px;
	}

	.runWorkflow__form {
		padding-top: 16px;
	}

	.modalBtns__box {
		padding-right: 24px;
	}
}

.editormodelBtns_box {
	margin: 10px 0 0 0;
}

.jobStatusIndicator {
	position: relative;
	padding: 0 8px;
	margin: 6px 0;
	border-left: 1px solid #6d707e;
	display: flex;
	align-items: center;

	svg {
		margin-top: 5px;
	}

	& > img {
		height: 20px;
		width: 20px;
	}

	.runningJobsNumber {
		position: absolute;
		top: -4px;
		right: 7px;
		color: #030613;
		font-size: 10px;
		background-color: #fcad01;
		border-radius: 4px;
		width: 14px;
		height: 14px;
		text-align: center;
		font-weight: 600;
	}

	.jobStatusPanel {
		opacity: 0;
		position: absolute;
		width: 400px;
		max-height: 290px;
		background-color: #2a2c42;
		right: 8px;
		top: 38px;
		transition: opacity 0.25s;
		box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
			2px 4px 8px 0 rgba(0, 0, 0, 0.32);

		&.show {
			opacity: 1;
			z-index: 1004;
		}

		a {
			color: #fcad01;
			font-size: 10px;
			font-weight: 600;
			letter-spacing: 0.25px;
			line-height: 12px;
			text-align: left;
			padding-right: 6px;
			padding-top: 4px;
		}
	}

	.jobStatusTitle {
		color: rgba(255, 255, 255, 0.72);
		font-size: 10px;
		font-weight: 600;
		letter-spacing: 0.25px;
		padding-left: 8px;

		&.jobStatusTitleCompleted {
			display: block;
			margin: 0 8px;
			padding-left: 0;
			padding-top: 4px;
			border-top: 2px solid #6d707e;
			margin-bottom: 6px;
			position: sticky;
			background-color: #2a2c42;
			top: 0;
		}

		&.hideBorderTop{
			border-top: transparent;
		}
	}

	.jobItemBox{
		height: 49px;
		width: 100%;
		display: flex;
		padding: 5px 12px 2px;
		color: #c6c6cc;
		margin-bottom: 2px;
		font-size: 10px;
		line-height: 12px;

		&:hover {
			background-color: #0b0e1c;
		}

		svg {
			flex-shrink: 0;
		}
	}

	.jobStatusList {
		list-style-type: none;
		margin-bottom: 10px;
		margin-top: 2px;
		overflow: auto;
		max-height: 261px;


		.jobStatusInfo {
			width: 85%;
			margin: 0 6px;
			font-weight: 400;
			letter-spacing: 0.25px;
			display: flex;
			flex-direction: column;
			flex: 1;

			.jobName {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.25px;
				line-height: 18px;
				width: 84%;

				span {
					color: white;
				}
			}

			.streamingRunningJob .jobName{
				width: 75%;
			}


			.jobStatus__secondLine{
				// height: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				span {
					margin-right: 12px;
				}

				svg {
					margin-right: 2px;
					margin-top: 0;
				}
			}

			
		}

		.viewLogText {
			// margin: 0 6px; 
			cursor: pointer;
			text-decoration: underline;
			// font-style: italic;
		}

		.jobState {
			width: max-content;
			display: flex;
			align-items: center;
			font-size: 10px;


			&:before {
				content: '';
				border-radius: 100%;
				width: 8px;
				height: 8px;
				display: inline-block;
				margin-right: 4px;
			}

			&.jobState--error,
			&.jobState--failed,
			&.jobState--dead {
				color: #b05300;
				&::before {
					background-color: #b05300;
				}
			}

			&.jobState--killed {
				color: #ff3d4f;
				&::before {
					background-color: #ff3d4f;
				}
			}

			&.jobState--success {
				color: #8fff73;
				&::before {
					background-color: #8fff73;
				}
			}
		}

		.jobStatusItem__firstLine {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			// align-self: center;
			// width: ;
			white-space: nowrap;
			margin-bottom: 4px;



			.jobName{
				overflow: hidden;
				text-overflow: ellipsis;
				flex-shrink: 1;
				// width: 150px;
			}

			.jobState {
				padding-left: 16px;
			}

		}

		.jobStatusItem__secondLine{
			display: flex;
			justify-content: space-between;
		}

		.jobStatusItem__timeDurationInfo{
			display: flex;
			align-items: center;

			svg {
				margin-top: 0;
				margin-right: 2px;
			}

			span {
				margin-right: 10px;
			}

		}
		

		.jobStatusBtns {
			// width: 36px;
			// text-align: end;f
			// padding-top: 3px;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			
		}
	

		.terminateMode {
			display: block;
			padding-left: 8px;
			// padding-top: 4px;
			padding-top: 1px;
			padding-right: 0;
			display: flex;
			// align-items: center;
			flex-direction: column;
			justify-content: center;

			&:hover {
			}

			button {
				font-size: 12px;
				letter-spacing: 0.25px;
				line-height: 14px;
				font-weight: 600;

				&:first-child {
					margin-right: 16px;
					color: #ff3d4f;
				}
			}

			.terminateMsg{
				margin-bottom: 8px;
			}
		}

		.inProgressBox {
			display: flex;
			justify-content: flex-start;
		}

		.ds__node__preview__status {
			position: static;
			width: 85%;
			// border-radius: 50%;
			// margin-top: 4px;
		}

		.streamingRunningJob .ds__node__preview__status{
			width: 78%;
		}

		.jobItem__inProgress__state{
			display: flex;
			flex-direction: column;
			position: relative;
		}

		.ds__node__preview__status{
			margin-bottom: 3px;
		}

		.btn-jobTerminate{
			position: absolute;
			right: 0px;
			top: -14px;
		}

		.btn-viewLog{
			position: absolute;
			right: 25px;
			top: -14px;
		}

		.terminateMsg {
			color: #c6c6cc;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.25px;

			&.confirmed {
				line-height: 41px;
				margin-left: 82px;
			}
			// line-height: 14px;
		}
	}
}

#JobOpenWorkflowIcon:hover #JobOpenWorkflowIcon-inner {
	fill: #003f59;
}

#JobCloseWorkflowIcon:hover #JobCloseWorkflowIcon-inner {
	fill: #7e0001;
}

.loseUnsavedFormChangesModal {
	.modal__header {
		display: none;
	}

	width: 540px;

	.modal__body {
		padding: 24px;
	}

	.modalBtns__box {
		flex-direction: row;
		justify-content: flex-end;

		.btn-yellow {
			margin: 0 16px;
		}
	}

	.deleteWorkflowModal__warningMsg {
		margin-bottom: 40px;
	}
}
.useFileSelector {
	position: relative;
	.inputfield {
		width: 93%;
	}

	.btn-fileBrowser {
		position: absolute;
		top: 39px;
		right: 25px;

		img {
			height: 25px;
		}
	}
}

.ant-modal-confirm-title,
.ant-modal-confirm-content {
	color: white !important;
}

.hide_builtib_treedata {
	display: none;
}

.show_builtib_treedata {
	display: block;
}

.tree_label_custom_wrapper {
	padding: 10px 0 0 0;
}

.upload-btn input {
	height: 100%;
	width: 100%;
	display: block;
	background-color: #14182a;
	border-radius: 2px;
	border: transparent;
	padding: 4px 8px;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.25px;
	line-height: 18px;
	border: 1px solid transparent;
	border-radius: 2px;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload-btn input[type='file'] {
	background-color: #13182a;
	color: white;
	color: white;
	padding: 6px 6px;
	margin-bottom: 8px;
}

// .upload-btn input:hover {
// 	border-color: #FCAD01;
// }

.upload-btn input:focus {
	border-color: #fcad01;
}

.upload-btn label {
	font-size: 13px;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.25px;
	line-height: 18px;
	text-align: left;
	display: block;
	margin-bottom: 4px;
	text-transform: capitalize;
	color: white;
}

.upload-btn .showerror {
	display: block;
	margin-top: 6px;
	color: #ff7761;
	font-size: 10px;
	letter-spacing: 0.25px;
	line-height: 16px;
}

.upload-btn span {
	display: none;
}

.showinputfileError {
	border: 1px solid #b01900 !important;
	margin-bottom: 0px !important;
}
.upload-btn {
	// margin-bottom: 45px;
}

.canvasContextMenu {
	padding: 3px 0;
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.25px;
	line-height: 12px;
	background-color: #14182a;
	border-radius: 1px;
	box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.32),
		2px 4px 8px 0 rgba(0, 0, 0, 0.16);
	

	button, li {
		padding: 6px;
		height: 24px;
		width: 100%;

		&:hover {
			background-color: #fcad01;
			color: #030613;
		}
	}

}
.queryBuilder {
	height: 490px;
	.header {
		position: initial !important;
		color: white !important;
		background: initial !important;
	}
	.ui.search > .results .result .title {
		text-shadow: initial !important;
		line-height: initial !important;
	}
	.drag div:not(.text):not(.label) {
		//color: white;
	}
	.ui.search .prompt,
	.ui.input > input {
		border-radius: 4px !important;
		background-color: #14182a;
		color: white;
		// border: 1px solid #d9d9d9;
	}
	.ant-select-selection {
		border: none;
	}
}

.editorTab {
	.ant-tabs-ink-bar{
		margin-left: 0 !important;
	}
	.ant-modal-body {
		padding-top: 0 !important;
	}
	.ant-tabs-tab {
		color: white;
		text-transform: uppercase;
		font-family: 'DM Sans', Arial, Helvetica, sans-serif;
		font-size: 12px;
	}
	.icon {
		margin-right: 0px !important;
	}
	.ant-col-22 {
		border-left: 1px solid white;
		padding-left: 10px;
	}
	h5 {
		color: white;
	}
	.ant-radio-button-wrapper {
		padding: 0px;
		height: 27px;
		width: 27px;
		line-height: 23px;
		background-color: #9b9fc6;
		border: none;
		text-align: center;
		box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
		&::before {
			background-color: none;
			width: 0;
		}
	}
	.ant-radio-button-wrapper:first-child {
		border-radius: 2px 0 0 2px;
	}
	.ant-radio-button-wrapper:last-child {
		border-radius: 0 2px 2px 0;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		z-index: 1;
		background-color: #fcad01;
		box-shadow: none;
	}

	.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
		padding-bottom: 5px;
		padding-left: 0px;
		padding-right: 0px;
		margin: 0 20px;
	}
	.ant-tabs-ink-bar {
		background-color: white;
	}
	.ant-tabs-top-bar {
		border-bottom: none;
	}
	.ant-select-selection--single,
	.ant-select-selection--multiple {
		height: 30;
	}
	.addButtonIcon {
		background-color: #8fff73;
		border: none;
		i {
			color: black;
			font-weight: bold;
		}
	}
	.removeButtonIcon {
		background-color: #970900;
		border: none;
		i {
			color: white;
			font-weight: bold;
		}
	}
	.table.icon {
		color: white;
	}
	.whereoption {
		.ant-radio-button-wrapper {
			color: rgba(0, 0, 0, 0.48);
			line-height: 26px;
			width: 28px;
			font-weight: bold;
		}
		.ant-radio-button-wrapper:first-child {
			width: 42px;
		}
	}
	.select_row_col {
		.ant-radio-button-wrapper {
			color: rgba(0, 0, 0, 0.48);
			line-height: 26px;
			width: 35px;
			font-weight: bold;
		}
		.ant-radio-button-wrapper:first-child {
			width: 45px;
		}
	}
	.query_indicator {
		position: absolute;
		border-left: 1px dashed #6d707e;
		width: 3px;
		// height:50px;
		left: 5px;
		top: 20px;
	}

	.ant-select-selection .ant-select-selection__clear {
		background-color: transparent;
		color: white;
	}
	.ant-select-selection__clear {
		right: 25px;
	}

	.query_position_indicator {
		position: absolute;
		border-top: 1px dashed #6d707e;
		width: 11px;
		height: 3px;
		left: -11px;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.query_position_drag{
		
	}
	.ant-select-selection__choice__content,
	.ant-select-selection-selected-value,
	.ant-select-dropdown-menu-item-group-title {
		font-size: 11px;
	}
	&.ant-select-dropdown {
		background-color: #14182a !important;

		.ant-select-dropdown-menu-item {
			color: #fff;
			background-color: #14182a !important;
			font-size: 11px;
			padding-top: 2px;
			padding-bottom: 2px;
		}
		.ant-select-dropdown-menu-item:hover {
			background-color: #2a2c42;
		}

		.ant-select-dropdown-menu-item-active {
			background-color: #f9fafb;
		}

		.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
			background-color: #f9fafb;
		}
	}
	.ant-modal-footer {
		border-top: none;
	}
	.ant-select-dropdown-menu-item-group-title {
		font-weight: bold;
		color: white;
	}
	.ui.category.search > .results .category {
		background-color: #14182a;
	}
	.ui.category.search > .results .category > .name {
		background-color: #14182a;
		color: white;
	}
	.ui.category.search > .results .category .results {
		background-color: #14182a;
		color: white;
	}

	.ui.search > .results {
		background: #14182a;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		border: none;
		.result {
			border-bottom: none;
		}
		.title {
			color: #fff;
		}
	}
	.ui.category.search > .results .category .result:hover,
	.ui.search > .results .result:hover {
		background-color: #2a2c42;
	}

	.input-field-options{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		row-gap: 4px;

		.toggleField{
			flex-shrink: 0;
		}
	}
}

.properties__body .componentDescription {
	span {
		color: rgba(255, 255, 255, 0.72);
		user-select: text;
		font-size: 12px;
		white-space: pre-wrap;
	}
}

@media screen and (max-height: 759px) {
	// for 10, height: 329
	// for 8, height: 263
	// for 13, height: 554
	.saved__workflows__container .inPageSpinner,
	.profiles__container .inPageSpinner {
		height: 263px;
	}
}

@media screen and (min-height: 760px) and (max-height: 845px) {
	.saved__workflows__container .inPageSpinner,
	.profiles__container .inPageSpinner {
		height: 329px;
	}
}

@media screen and (min-height: 845px) and (max-height: 960px) {
	.saved__workflows__container .inPageSpinner,
	.profiles__container .inPageSpinner {
		height: 395px;
	}
}

@media screen and (max-width: 1400px) {
	.properties__container{
		width: 300px;
	}
	.sql_editor {
		width: 250px;
	}
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
	.properties__container {
		width: 350px;
	}
	.sql_editor {
		width: 300px;
	}
}

.gitInnerModal{
	display: flex;
	align-items: center;

	.inputfield__container{
		margin-right: 20px;
	}
}

.git_modal{
	width: 500px;
}

.clusters__container {
	.search__input__container{
		margin-bottom: 23px;
	}

	.saved__profiles__innerContainer{
		border-top: 1px solid rgba(11, 14, 28, 0.1);
	}

	.workflow__item{
		padding-left: 12px;
		padding-right: 16px;
		justify-content: space-between;
		height: 36px;
		border-bottom: 1px solid rgba(11, 14, 28, 0.1);
	

		&:hover .workflow__title{
			width: 190px;
		}
	
	}

	.workflow__title{
		margin-left: 0;
		width: 190px;

		&:hover{
			width: 190px;
		}
	}

	.toggleField{
		margin-right: 0;
	}
}

.varPlaceholder__box{
	position: absolute;
	top: 0;
	left: 0;

	#ds__nodeVariablePath{
		fill: rgb(4, 85, 164);
	}

	circle{
		fill: #1E2038;
	}

}

.varInputPort__box{
	margin-top: 19px;

	.optionsList span {
		font-weight: 600;
	}
}

.duration_field .ant-time-picker-icon .ant-time-picker-clock-icon, .ant-time-picker-clear .ant-time-picker-clock-icon {
	display: block;
    color: white;
    line-height: 1;
}

.duration_field .ant-time-picker-input {
	background: #14182a;
    border: none;
    border-radius: 0px;
    color: white;
}
.duration_field {
	margin-bottom: 18px;
}

.duration_field .ant-time-picker:hover .ant-time-picker-clear {
	opacity: 1;
    pointer-events: auto;
    background: #14182a;
    color: white;
}

 .ant-time-picker-panel {
	// margin-left: -10px !important;
}

.ant-time-picker-panel-input-wrap  {
	background: #14182a;
	color: white;
	.ant-time-picker-panel-input {
		background: #14182a;
		color: white;
	}
}

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
	width: 100%;
}

.ant-time-picker-panel-combobox {
    background: #14182a;
    color: white;
	width: 100%;
	
}
.ant-time-picker-panel-select-option-selected {
	color:#1890ff;
	background: none;
}

.ant-time-picker-panel-select li:hover {
    background: none;
	color:#1890ff;
}

li.ant-time-picker-panel-select-option-selected {
	font-weight: 600;
    background: none;
}

#btn-toggleHelpPanel{
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #D4D6E0;
	opacity: 0.72;
	padding-top: 7px;
	padding-bottom: 7px;
	display: flex;
	align-items: center;
	

	&.active{
		opacity: 1;
	}
}

#bulb-icon{
	margin-right: 4px;
	padding: 3px;
	width: 29px;
    height: 29px;

	rect {
		fill: #D4D6E0;
	}

	&:hover{
		border-radius: 75%;
		background-color: rgba(0, 0, 0, 0.48);
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.48);

		rect {
			fill: #fff;
		}	
	}

	
}

.helpPanel__outerBox{
	.helpPanel__innerBox{
		padding: 16px;
	}	
	
}

.helpPanelHeader__box{
	.helpPanelHeader__title{
		font-weight: 600;
		font-size: 10px;
		line-height: 16px;
		letter-spacing: 0.25px;
		opacity: 0.72;
		margin-bottom: 12px;

		img{
			width: 25px;
			height: 25px;
			transform: rotate(-90deg);
		    margin-top: -3px;
			margin-left: 6px;
		}
	}

	.documentTitle__dropdown{
		margin-bottom: 16px;
		margin-right: 10px;
	}

	form{
		display: flex;
	}
}

.console__helpTab{
	padding: 16px 0 16px 16px;
	height: calc(100% - 35px);
	overflow: hidden;
    color: white;

	.documentTitle__dropdown {
		width: 260px;

		.select-field-value{
			background-color: #2a2c42;
		}
	}
	
	.helpPanelHeader__box{
		border-bottom: 1px solid #2A2C42;
		margin-right: 16px;
	}

	.helpDocument__box{
		padding: 16px 6px 16px 0;
		height: calc(100% - 95px);
		overflow: auto;

		 * {
			user-select: text;
		}
		
		color:  #FFFFFF;

		p {
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.25px;
			user-select: all;
		}

		h1, h2 {
			color: #c7c7cd;
			font-weight: 600;
		}

		pre, code {
			white-space: pre-wrap;       /* Since CSS 2.1 */
			white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
			white-space: -pre-wrap;      /* Opera 4-6 */
			white-space: -o-pre-wrap;    /* Opera 7 */
			word-wrap: break-word;  
		}

		.inPageSpinner {
			height: 100%;
			align-items: center;
		}

		table, th, td {
			border: 1px solid rgba(255, 255, 255, 0.72);
			text-align: center;
		}

		table {
			border-collapse: collapse;
			margin-bottom: 2em;
		}

		td, th {
			padding: 4px 8px;
		}

		td.separated {
			padding: 0;

			span{
				padding: 4px 8px;
				display: block;
				border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

				&:last-of-type{
					border-bottom: none;
				}
			}
		}
		  
	}
}

#importCode {
	resize: vertical;
	max-height: 500px;
}
.imagePreviewModal{
    div:focus, img:focus {
        outline: none;
    }

    img{
        width: 72vw;
        height: calc(72vw * 0.56);
	}
	
	.modal__header{
		display: none;
	}

	.modal__body{
		padding: 0;
	}
}

.component_arg_wrapper {
	display: flex;
	margin-top: 25px;
	
	.arg_field {
		width: 30%;
		margin-right: 10px;
	}
}

.comp-parameter-required-field {
	// margin-top: 25px;
}

.edit-parameter-btn{
	display: flex;
}

.arg_add_btn {
	border-radius: 25px;
	width: 25px;
	height: 25px;
	margin-left: 15px;

}
.arg_add_btn span {
	position: absolute;
    top: -12px;
    left: 3px;
}

.arg_wrapper {
	// display: flex;
	align-self: center;
    align-items: end;
}

.removeIconArg  {
	margin-top:19px;
	.portTicker__Btnbox button  {
		background-color: #970900 !important;
		border: none;
	}
	.portTicker__Btnbox {
		align-items: center;
		display: block;
		float: right;
		text-align: center;
	}
	.portTicker__Btnbox button i  {
		color: white !important;
	}
}

.arg_list_wrapper .handleGrab {
	margin-top: -12px;
}

.editor-hidden {
	display: none;
}

.portTickers__main {
	display: flex;
}
.portTicker__box {
	.portType {
		display: block;
		color: #ffffff;
		letter-spacing: 0.25px;
		line-height: 18px;
		margin-bottom: 8px;
		font-size: 12px;
	}
}

.portTicker__Btnbox {
	// height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
	// margin-right: 40px;
	// line-height: 24px;
	button {
		background-color: #fcad01;
		box-shadow: 0 1px 4px 0 rgba(252, 173, 1, 0.72);
		width: 24px;
		height: 24px;
		color: #2a2c42;
		font-size: 30px;
		position: relative;

		&:disabled {
			background-color: #543a00;
			box-shadow: none;
			cursor: default;
		}
	}

	.increment__btn {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		span {
			position: absolute;
			top: -12px;
			left: 3px;
		}
	}

	.decrement__btn {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;

		span {
			position: absolute;
			width: 20px;

			top: -12px;
			right: 2px;
		}
	}

	.countText {
		display: inline-block;
		text-align: center;
		width: 32px;
		height: 24px;
		background-color: #14182a;
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 24px;
		&.disabled {
			opacity: 0.7;
		}
	}
}

.editorTab .ant-modal-close {
	display: none;
}
.goBackDirectories__box{
	padding-left: 6px;
	height: 24px;
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	

	*{
		font-weight: 700;
		font-size: 12px;
	}

	button {
		height: 24px;
		line-height: 24px;
		display: flex;
		align-items: center;

		svg {
			margin-right: 6px;
		}

		&:disabled{
			cursor: default;
		}
		

		
	}

	.directory__breadcrumb{
		display: flex;
		align-items: center;
		
		span{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 108px;
		}
	}

}

.showExecutionEnvToggle .directoriesList{
	max-height: calc(100vh - 410px);
}

.directoriesList{
	overflow-y: auto;
	max-height: calc(100vh - 355px);
	margin-bottom: 24px;

	.directory__item{
		height: 32px;
		display: flex;
		align-items: center;
		line-height: 32px;
		padding-left: 8px;
		padding-right: 8px;
		font-weight: 700;
		cursor: pointer;
		font-size: 12px;
		
		.directory__item-text {
			white-space: nowrap; 
			overflow: hidden; 
			text-overflow: ellipsis; 
			flex: 1; 
			display: inline-block; 
			vertical-align: middle; 
			color: inherit; 
		}

		svg {
			margin-right: 8px;
		}

		&:hover, &.showHoverState{
			background-color: #666a8d;
		}

		.inlineInputField{
			width: 80%;
			// margin-right: 10px;
		}
	}
}

#btn_sortByWorkflows{
	border: 1px solid #6D707E;
	border-radius: 2px;
	color: #D4D6E0;
	font-size: 14px;
	display: flex;
	align-items: center;
	
	svg {
		margin-right: 3px;
		width: 18px;
		height: 18px;
	}

	&.showHoverState, &:hover{
		border-color: #D4D6E0;
	}
}

#btn_importWorkflows{
	border: 1px solid #6D707E;
	border-radius: 2px;
	color: #D4D6E0;
	font-size: 14px;
	display: flex;
	align-items: center;
	
	svg {
		margin-right: 3px;
		width: 18px;
		height: 18px;
	}

	&.showHoverState, &:hover{
		border-color: #D4D6E0;


	}
}

.ant-dropdown-menu{
	background: #2A2C42;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32), 0px 8px 16px rgba(0, 0, 0, 0.48);
	border-radius: 1px;

	* {
		color: white;
		font-size: 12px;
		font-family: 'DM Sans';
		font-weight: 400;
		letter-spacing: 0.25px;
	}
	
	.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{
		height: 24px;
		padding: 0;
		padding-left: 12px;
		line-height: 24px;

		&:hover{
			background: #666A8D;
			font-weight: 700;
		}

		
	}


	.ant-dropdown-menu-submenu-disabled, .ant-dropdown-menu-item-disabled{
		opacity: 0.32;
		cursor: default;
		background: #2a2c42;



		&:hover, .ant-dropdown-menu-submenu-title:hover{
			background: #2a2c42;
			cursor: default;
			color: white;
			font-weight: 400;
		}
	}

	.ant-dropdown-menu-submenu-title i{
		display: none;
	}

	&.ant-dropdown-menu-sub{
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.dropdownSubMenu__title{
		display: flex;
		justify-content: space-between;
		padding-right: 6px;
	}
}

.workflowItem__contextMenuOptions{
	width: 135px;
}

.workflowItem__contextMenuOptionsss{
	width: 135px;
}

.directory__contextMenuOptions{
	width: 120px;
}

.directory__contextMenuOptions{
	width: 120px;
}
.customComponentParameters__modal{
	width: calc(100vw - 250px) !important;
	top: 50px;


	.ant-modal-content{
		height: calc(100vh - 100px);
		display: flex;
		flex-direction: column;
	}

	.ant-modal-body{
		flex: 1;
		padding-right: 0;
	}

	.ant-modal-footer{
		display: none;
	}

	.argsList__box{
		margin-top: 8px; 
		background-color: rgba(11, 14, 28, 0.48);
		// height: calc(100; 
		height: 100%;
	}

	form {
		height: 100%;
		padding-bottom: 24px;
	}

	.component_parameter_wrapper {
		display: flex;
		flex-direction: column;
		padding-right: 24px;
		height: calc(100vh - 230px);
		margin-bottom: 18px;
		overflow-y: auto;

		.inputfield__container, .select-field{
			margin-bottom: 14px;
		}



	}

	.modalBtns__box{
		padding-right: 24px;
	}
	
}

.Editor-modal{
	.inPageSpinner{
		position: absolute;
		z-index: 1;
		background-color: rgba(0,0,0,0.5);
		top: 71px;
		width: 90vw;
		height: 75vh;
		display: flex;
		align-items: center;
	}	
}


.shareWorkflowModal__container{
	width: 400px;

	.modal__body{
		padding: 16px 24px 24px;
	}

	.modalBtns__box{
		padding-right: 0;
		margin-top: 32px;
	}

	.spinnerOuter{
		position: absolute;
		background-color: rgb(0, 0, 0, 0.32);
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
	}
}

.workflow__dagMsg{
	font-size: 12px;
	color: #C7C7CD;
	margin-bottom: 12px;
	display: block;
}

.properties__body{
	height: calc(100% - 56px);
}

.scheduleForm__propertiesRHS{
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 16px;
	overflow: auto;

	.scheduledTimes__container{
		padding: 24px;	

		.scheduledTimes__LHS{
			margin-right: 32px;
		}
	}
	
	.catchup-radio-field{
		padding: 0px;
		padding-left: 24px;
		padding-bottom: 20px;
	}

	.scheduleForm__propertiesRHS__actionBtns{
		display: flex;
		padding: 24px;
		flex-direction: column;
		

		button:first-of-type{
			margin-bottom: 8px;
		}
	}

	.inputfield__container, .select-field{
		padding-top: 0;
		border-bottom: none;
		padding-left: 24px;
		padding-right: 24px;
	}

	._form--halfwide, .split__fields--halfwide {
		padding-left: 24px;
		padding-right: 24px;
		
		.inputfield__container, .select-field{
			padding-left: 0;
			padding-right: 0;
			row-gap: 16px;
		}
	}

	.toggleField__container{
		padding-left: 24px;
		margin-bottom: 16px;
	}

	.retries__dropdown{
		width: 50%	;
	}
	
}

.configurationSchedule__box{
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 18px;

	p{
		font-size: 12px;
		margin-bottom: 4px;
	}

	.configurationSchedule__fields{
		border: 2px solid transparent;
		border-radius: 4px;

		&.showForm{
			border-color: #666a8d;
			padding: 8px 12px 8px 8px;

		}
	}
	

	.select-field{
		padding: 0;
		margin-bottom: 12px;
	}

	.dayHrSecFields{
		display: flex;
		gap: 16px;

		.select-field {
			width: 52px;
			margin-bottom: 0px;
		}
	}

	.frequency__dropdown{
		width: 124px;
	}

	.scheduleString__previewInnerBox{
		display: flex;
		align-items: center;
	}

	.scheduleString__previewBox{
		background: #14182A;
		padding: 8px;
		letter-spacing: 0.25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 2px;
		cursor: pointer;
		

		.scheduleString__subtitle{
			color: #C7C7CD;
		}

		&.disableClick{
			cursor: default;
		}

		svg {
			margin-right: 12px;
		}
	}

	.scheduleFields__formBtns{
		margin-top: 12px;
		display: flex;
		justify-content: flex-end;

		button:first-child{
			margin-right: 20px;
		}

		button:first-child{
			margin-right: 20px;
		}
	}
	.scheduleFields{
		margin-top: 12px;

		&.isRecurringSchedule{
			border-top: 1px solid #393E53;;
			padding-top: 12px;
		}
	}

	.radiofield__container{
		padding: 0;
		border-bottom: 0;
		margin-bottom: 12px;
	}
}


.streamingPreviewWorkflowModal__container{
	width: 396px;

	.modal__body{
		padding: 16px 24px;
	}

	.select-field, .inputfield__container{
		margin-bottom: 12px;
	}

	.modalBtns__box{
		margin-top: 24px;
		padding-right: 0;
	}

	.infoMsg{
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.25px;
		color: #C7C7CD;
		display: flex;

		svg{
			// width: 22px;
			margin-right: 4px;
			// height: 22px;
			flex-shrink: 0;
		}
	}
}

.editWorkflowConfig__modal{
	background-color: #3e4161 !important;
	width: 350px;
	color: white;

	form{
		border: none;
		padding: 0;
		margin: 0;
	}

	.modalBtns__box{
		margin-top: 12px;
		flex-direction: row;
		justify-content: end;
		column-gap: 8px;
	}

	.btn-cancel{
		margin-right: 0;
	}
}

.h-24 {
	height: 24px!important;
}

.RouteOptimisationModal {
	.modal__header {
	}
  
	.modal__body {
	  border-radius: 4px;
	  box-shadow: 4px 8px 32px 0 rgba(0, 0, 0, 0.48),
		inset 0 0 4px 0 rgba(255, 255, 255, 0.1);
	  width: 900px;
	  position: relative;
	  color: #ffffff;
	  padding: 10;
	  height: fit-content;
	}

	.ant-btn:hover,
	.ant-btn:focus {
		background-color: #fcad01 !important;
		border-color: #fcad01 !important;
		color: #000 !important;
	}

	.ant-input:hover,
	.ant-input:focus {
		border-color: #fcad01 !important;
		color: #fff !important;
	}
	.fixed-bottom {
		position: fixed;
		bottom: 0px;
		display: flex;
		justify-content: center;
		background-color: #2a2d43;
	}

	.route-btn-submit{
		background-color: #fcad01;
		border-color: #fcad01;
		color: #000;
		font-weight: 600;
		width: 180px;
		font-size: 14px;
	}

	.route-btn-discard{
		background-color: #f0f0f0;
		border-color: #f0f0f0;
		color: #000;
		font-weight: 600;
		font-size: 12px;
	}

	.route-form{
		height: 70vh;
		gap: 20px;
		padding: 10px;
		margin-bottom:60px;
		overflow-y: auto;
		color: #ffffff
	}  

}
.ant-select-dropdown {
	background-color: #ffffff; 
	color: #000;
}

.configure-route-button {
    margin-top:2rem;
	margin-left: 1rem;
}

.add-operator-button {
    margin-bottom:1rem;
	background: #fcad01;
	flex-wrap: wrap;
}

.area-select {
	.ant-select-selector {
		background-color: #f0f2f5;
		border: 1px solid #d9d9d9; 
		border-radius: 4px; 
		color: #000; 
	}
	
	.ant-select-selection-item {
		color: #000;
	}
		
	.ant-select-selection-placeholder {
		color: #888; 
	}
		
	.ant-select-selection-overflow-item {
		background-color: #e6f7ff; 

		.ant-select-dropdown {
			background-color: #fff; 
			border: 1px solid #d9d9d9; 
		}
		
		.ant-select-item {
			color: #000; 
		}
		
		.ant-select-item-option-selected {
			background-color: #bae7ff; 
		}
		.ant-select-selector:focus,
		.ant-select-selector:focus-visible,
		.ant-select-selector-focused {
			border-color: none; 
			background-color: none; 
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); 
		}

		.ant-select-selector:hover {
			background-color: none; 
			border-color: none; 
		}

	}
}
  
.radio-group-custom {
	.ant-radio-wrapper {
	  display: flex;
	  align-items: center;
	  margin-right: 1rem; 
  
	  .ant-radio {
		&-inner {
		  background-color: #f0f0f0;
		}
  
		&-checked .ant-radio-inner {
		  background-color: #fcad01;
		}
	  }
	}
}
.radio-group-route-optimisation {
	.ant-radio-wrapper {
	  align-items: center;
	  .ant-radio {
		&-inner {
		  background-color: #f0f0f0;
		}
  
		&-checked .ant-radio-inner {
		  background-color: #fcad01;
		}
	  }
	}
}


.operator-table {
		margin:0;
	.ant-table-thead > tr > th {
	  background-color: #2a2d43 !important;
	  padding: 12px 16px;
	  font-size: 14px;
	  font-weight: 600;
	  text-align: left;
	  border: none !important;
	  outline: none !important;
	}
  
	.ant-table-tbody > tr > td {
	  background-color: #2a2d43 !important;
	  padding: 12px 16px;
	  font-size: 14px;
	}

	.editable-row .editable-cell {
	  position: relative;
	  padding: 8px 12px;
  
	  input {
		width: 100%;
		padding: 4px 8px;
		font-size: 14px;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
  
		&:focus {
		  border-color: #fcad01;
		  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}
	  }
	}
  
	.ant-pagination {
	  margin: 16px 0;
	  text-align: right;
  
	  .ant-pagination-item {
		min-width: 32px;
		height: 32px;
		line-height: 30px;
		margin-right: 8px;
		border-radius: 4px;
		font-size: 14px;
	  }
  
	  .ant-pagination-item-active {
		background-color: #fcad01;
		border-color: #fcad01;
  
		a {
		  color: #fff;
		}
	  }
  
	  .ant-pagination-item:hover {
		border-color: #fcad01;
  
		a {
		  color: #fcad01;
		}
	  }
  
	  .ant-pagination-prev,
	  .ant-pagination-next {
		min-width: 32px;
		height: 32px;
		line-height: 32px;
	  }
  
	  .ant-pagination-disabled {
		.ant-pagination-item-link {
		  color: #d9d9d9;
		  cursor: not-allowed;
		}
	  }
	}
  
	.ant-table-body {
		background-color: rgba(0, 0, 0, 0.2);
	  &::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	  }
  
	  &::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 4px;
	  }
  
	  &::-webkit-scrollbar-track {
		background-color: #f1f1f1;
	  }
	}

	.ant-table {
		border: none !important;
	}
	.ant-table-cell {
		padding: 2px 4px !important;
	  }

	  .ant-table-thead > tr > th {
		padding: 2px 4px !important;
	  }
	.ant-table-container {
		border: none !important;
	}
	
	.ant-table-title {
		padding: 0px !important;
		background-color:  #2a2d43 !important;
		border:none !important;
	}
	
	.ant-table-tbody > tr > td {
		border: none !important;
	}
	
	.ant-table-thead > tr > th::before {
		display: none !important;
		padding: 0px;
	}
	
	.ant-table-tbody > tr:hover > td {
		background-color: #2a2d43 !important
	  }
}
  
  .prioritize-checkbox {
	.ant-checkbox + span {
		padding-right: 8px;
		padding-left: 8px;
		color: #fff;
	}
	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: #fcad01;
		border-color: #fcad01;
		color:#000
	}
	.ant-checkbox-inner {
		position: relative;
		top: 0;
		left: 0;
		display: block;
		width: 16px;
		height: 16px;
		background-color: #fff;
		border: 1px solid #d9d9d9;
		outline: none !important;
		border-radius: 2px;
		border-collapse: separate;
		-webkit-transition: all 0.3s;
		transition: all 0.3s;
	}
  }
  
.route-btn-group{
	display: flex;
	justify-content: space-between;
	gap: 40px;
	align-items: center;
}
.btn_configure_pipeline{
	background-color: #fcad01;
	border-color: #fcad01;
	color: #000;
	font-weight: 600;
	width: 220px;
	font-size: 14px;
	padding: 4px;
}

.disabled-select .ant-select-selector {
    background-color: grey !important;
    color: white !important;
}

.disabled-select .ant-select-selection {
    background-color: gray !important;
}

.GitInnerTab {
	font-size: 12px;
	cursor: pointer;
	font-weight: 700;
	margin-top: 7px;
    margin-left: 5px;
}

.innerGitTabs {
    .ant-tabs-nav-scroll {
		display: none;
	}

}

.enlargeGitIcon {
	font-size: 1.4rem;
	margin-right: 4px;
	vertical-align: middle;
}

.custom-tooltip {
	font-size: 10px;
}

.custom-tooltip .ant-tooltip-inner {
    font-size: 10px;
	white-space: nowrap;
}

.gitdirectoryName{
	vertical-align: middle;
	font-size: 12px;
	margin-left: 5px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.directoryNameText {
	flex: 1;
    display: block; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}

.gitDirectories__container{
 list-style-type: none;
 padding: 10px;
 margin-bottom: 1px;
}

.gitDirectories__item{
   margin-bottom: 3px;
   font-size: 14px;
   height: 32px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-right: 10px;
}

.gitDirectories__item:hover, .gitDirectories__item.active {
    background-color: #666a8d;
}

.gitflow__item {
	padding: 0 12px 0 10px;
	display: flex;
	position: 'relative';
	margin-bottom: 10px;
	transition: height 0.3s ease;
	align-items: center;
	

	.workflow__icons {
		margin-left: 2px;
		display: none;
	}

	.gitflow__title {
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		height: 100%;
		letter-spacing: 0.25px;
		margin-bottom: 0;
		cursor: pointer;
		margin-left: 6px;
		line-height: 16px;
        color: #9495A0;
        font-size: 0.8rem;
        font-weight: 700;
	}

	.favoriteIcon__btn {
		padding-top: 3.5px;
	}

	.workflowFavoriteIcon {
		cursor: pointer;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		padding: 2px;

		&:hover {
			fill: #ffffff;
			background-color: #2a2c42;
		}

		&:focus {
			background-color: #15162f;
		}
	}

	.inPageSpinner{
		height: 24px;
		width: 24px;
		margin-right: 2px;

		.lds-ring{
			transform: scale(0.5);
			margin-left: -8px;
		}
	}
}

.dropdown-content {
    transition: max-height 0.3s ease, opacity 0.3s ease;
	top: 30px;
	width: 98%;
	display: block;
	max-height: 320px;
	overflow-y: scroll;
	margin-top: 4px;
}

.gitflow__item.active .dropdown-content {
    max-height: 90px;
    opacity: 1;
}

.gitSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.ant-tabs-nav-container-scrolling {
	padding-left: 20px;
	padding-right: 20px;
}

.ant-tabs-tab-prev.ant-tabs-tab-arrow-show, .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    width: 32px;
    height: 100%;
    opacity: 1;
    color: #9495A0;
    pointer-events: auto;
}

.gitScroll {
	max-height: none;
    height: auto;
    overflow-y: visible;
}

.WorkspcaeSelect {
	width: 320px;
	padding: 2px;
	padding-right: 16px;
}

.workSpaceText {
	font-weight: bolder;
}

.custom-radio-group .ant-radio-button-wrapper {
	background-color: #fcad01;
	color: black;
	border: 1px solid #42475D;
	transition: background-color 0.3s, color 0.3s;
	height: 25px;
	line-height: 23px;
	display: flex;
  }

  .custom-radio-group .ant-radio-button-wrapper-checked {
	background-color: #fcae06;
	color: black; 
	display: flex;
  }

  .globalCluster {
	display: flex;
	align-items: center;
	gap: 5px;
  }