.card-loading {
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-text {
        margin-left: 10px;
        color: white;
    }
}

@mixin loading-skeleton($base-color, $shine-color) {
	background-image: linear-gradient(
		90deg,
		$base-color 0%,
		$shine-color 50%,
		$base-color 100%
	);
	background-size: 400% 400%;
    animation: shine-lines 1.2s infinite linear;

    @keyframes shine-lines {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
}

.mainLoadingSkeleton{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100vw - var(--sidebar-width));
    height: 100vh;
    z-index: 2500;
    display: flex;
    // flex-wrap: wrap;
}

.mainLoadingSkeleton__LHS, .mainLoadingSkeleton__RHS{
    width: 235px;
    height: 100vh;
    padding: 24px 20px 24px 12px;

    div {
        // background: #666A8D;
        border-radius: 2px;
        @include loading-skeleton(rgba(102, 106, 141, 0.4), #666A8D);
    }

    .mainTitle{
        height: 32px;
        width: 120px;
        margin-left: 40px;
        margin-bottom: 37px;
    }
    
    .treeviewTitle__box{
        background: transparent;
        margin-bottom: 14px;
    }

    .treeviewTitle{
        width: 88px;
        height: 14px;
        display: inline-block;
        margin-right: 12px;
    }

    .search__box{
        width: 202px;
        height: 40px;
        margin-bottom: 43px;
    }

    .treeviewItems{
        width: 148px;
        height: 18px;
        margin-bottom: 12px;

        &.firstItem{
            width: 88px;
        }
    }
}

.mainLoadingSkeleton__canvas{
    background-color: #030613;
    height: 100vh;
    // width: calc(100vw);
    flex: 1;
    position: relative;

    .canvasHeader{
        height: 36px;
        background: #2A2C42;
    }

    
    .tabSkeleton{
        width: 160px;
        height: 36px;
        padding: 9px 36px 7px 10px;
        background-color: #030613;

        .innerTabSkeleton{
            width: 114px;
            height: 20px;
            border-radius: 2px;
            @include loading-skeleton(rgba(42, 44, 66, 0.6), #2a2c42);
        }   
        
    }

    .floatingToolsSkeleton{
        width: 40%;
        height: 40px;
        position: absolute;
        top: 60px;
        right: 32px;
        border-radius: 2px;

        @include loading-skeleton(rgba(66, 71, 93, 0.6), #42475D);

    }

    .zoomActionToolsSkeleton{
        position: absolute;
        right: 32px;
        bottom: 96px;
        div{
            width: 36px;
            height: 73px;
            @include loading-skeleton(rgba(42, 44, 66, 0.6), #2a2c42);
            border-radius: 8px;

            &:first-child {
                margin-bottom: 6px;
                height: 36px;
            }
        }
    }

    .consoleSkeleton{
        background: #14182A;    
        height: 72px;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 14px 32px 14px 14px;
        display: flex;
        justify-content: space-between;

        div{
            height: 14px;
            @include loading-skeleton(rgba(42, 44, 66, 0.6), #2a2c42);
        }

        .consoleTitle {
            width: 158px;
        }

        .consoleOptions {
            width: 66px;
        }
    }
}

.mainLoadingSkeleton__RHS{
    width: 74px;
    padding: 12px;

    .userIconSkeleton{
        width: 32px;
        height: 32px;
        margin-bottom: 29px;
        border-radius: 100%;
        margin-left: 9px;
    }

    .propsIconSkeleton{
        width: 50px;
        height: 50px;
    }
}