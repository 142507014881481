
.gen_ai{
    width:calc(100vw - 59.5rem);
    font-size:1.1rem;
    height:30rem;
    padding:0;
    margin-bottom: 1rem;
    overflow: auto;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.holder {
    display: flex;
    justify-content: flex-start;
    width: 820px;
    > :nth-child(2) {
        margin-left: 1rem;
    }
}

.container {
    background-color: #212233;
    border-radius: 0.5rem;
    overflow-y: auto;
    height: 27rem;
    width: 100%;
}

.icons {
    display: flex;
    width: 2rem;
    height: 2rem;
    min-height: 2rem;
    border-radius: 0.5rem;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 189, 69);
    color: rgb(14, 17, 23);
    flex: 0 0 2rem;
}
.text {
    font-size:1rem;
    max-width: 56rem;
    border: 1px solid #3b3d43;
    background-color: #3a3b46;
    border-radius: 5px;
    padding: 1rem;
    margin-left: 2.7rem;
    width:auto;
    display:inline-block
}

.text_holder {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    max-width: 100%;
    flex-wrap: wrap;
}

.text_holder_human {
    justify-content: flex-end;
}

.text_human {
    margin-right: 2.7rem;
    background-color: #FBC441;    
    color: black;
}

.input_holder {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
}

.rowRender {
    margin-bottom: 0.2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
}

.rowRender:hover {
    // background-color: #14182a;
}

.btn {
    display: flex;
    align-items: center;
    gap: 4px;
}

.username {
    font-size: 1rem;
    color: #e8e9e9
}

.time {
    color: #808185;
    font-size: 1rem;
}

.btn_update {
    margin-left: 3.8rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
}

.button {
    margin-left: 3.8rem;
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom:3rem;
}

.select {
    margin-left: 3.8rem;
    width: 45%;
    border: 1px solid #cccccc87;
    padding: 1rem;
    border-radius:0.4rem;
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
}

.single-select {
    margin-left: 3.8rem;
    width: 45%;
    border: 1px solid #cccccc87;
    padding: 1rem;
    border-radius:0.4rem;
    justify-content: flex-start;
    gap: 0.5rem;
}

.loading {
    font-size:1.1rem;
    width:70%;
    text-align: left;
    margin: 1rem 0;
    margin-left: 2.7rem;
    margin-bottom: 4rem !important;
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

.inputBtn {
    background-color: #212233;
    height: 50px;
    width: 50px;
    display: flex;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    justify-content: center;
    align-items: center;
}
.user_display {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom:0.2rem;
}

.user_display_human {
    flex-direction: row-reverse;
}

.isArray {
    gap: 0.5rem;
    > .text {
        margin-left: 0rem;
        margin-right: 0rem;
        background-color: #36c0a2;
    }
    >.text:is(:last-child) {
        margin-right: 2.7rem;
    }
}

.avatar {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    pointer-events: none;
    padding: 4px;
    border: 1px solid #3c3e44;
    justify-content: center;
    display: flex;
    align-items: center;
    svg {
        height: 4rem;
        width: 4rem;
        color: #747784;
    }
}

.error_container {
    color: red;
    margin-left: 3.7rem;
}
  
@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
        .25em 0 0 white,
        .5em 0 0 white;}
}

.w-30 {
    width: 50%;
}

.genai-editor {
    
}
.editor_holder > section{
    margin:0;
    flex-grow: 1
}
.round_btn_holder {
    display: flex;
    width: 50px;
    justify-content: space-between;
    margin-left: 0.5rem;
}

.round_btn {
    &:hover {
        background-color: #3E4161;
    }
}
.feedback {
    color: #C7C7CD;
    font-size: 1rem;
}
