.toasty_common {
    background: #3a3f54 !important;
    // left: 60px !important;
    padding: 8px 8px 8px 0px !important;
    img {
      width: 30px;
      height: 30px;
    }
  }
  
  .progress_toasty {
    background: white !important;
    opacity: 0.7 !important;
    height: 4px !important;
  }
  
  .error_toasty {
    border-left: 4px solid #fabeb1;
  }
  
  .success_toasty {
    border-left: 4px solid #86eb71;
  }
  
  .info_toasty {
    border-left: 4px solid #94eef7;
  }
  
  .warn_toasty {
      border-left: 4px solid #94eef7;
  }

  .icon-wrapper {
    color: white;
    display: flex;
  }
  
  .notify-icon {
    display: flex;
    width: 20%;
    padding: 0px 0 0 0;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
  }
  
  .notify-text {
    display: flex;
    width: 80%;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
  }
  
  .Notify_Container_Wrapper {
    background: #3a3f54;
  }
  
  .Toastify__close-button--default {
    color: #fff !important;
    opacity: 0.5;
  }