.checkbox__container {
    display: block;
    position: relative;
    width: 16px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    &:hover .dummyCheckbox ~ .checkmark {
        border: 1px solid #ffffff;
        border-radius: 2px;
    }

    &:global {
        /* On mouse-over, add a grey background color */
        &.checkbox__sm{
            transform: scale(0.66);
        }
    }
}

.checkbox__gold {        
    /* Style the checkmark/indicator */
    .dummyCheckbox:checked ~ .checkmark {
        background-color: #fcad01;
        border-color: #fcad01;
        // background-color: #2A2C42;
    }

    .dummyCheckbox:checked ~ .checkmark:after {
        border-color: #2a2c42;
    }
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #c7c7cd;
    border-radius: 2px;

    &:after {
        content: '';
        position: absolute;
        display: none;
    }
}

/* Hide the browser's default checkbox */
.dummyCheckbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .checkmark {
        background-color: #14182a;
        border-color: #14182a;
    }

    /* Show the checkmark when checked */
    &:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    &:checked ~ .checkmark:after {
        left: 4.25px;
        top: 0.1px;
        width: 5.5px;
        height: 9.5px;
        border: solid #fff;
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.checkmark__partialselected {
    .checkmark {
        background-color: #696a76;
        border: 1px solid #898c9a;
    }

    .checkmark:after {
        display: block;
        left: 2px;
        top: 6px;
        background-color: #c7c7cd;
        width: 10px;
        height: 1.5px;
    }
}
