.helpPage__section{
    padding-top: 64px;
    height: 100vh;
    background-color: #14182a;

    .helpPanelHeader__title{
        display: none;
    }

    .btn-goBack{
        padding-left: 16px;
        padding-top: 10px;
        font-size: 16px;
        color: white;
        text-decoration: underline;
    }

    form {
        .select-field-value{
            border-color: rgba(252, 173, 1, 0.5);
        }
    }


    .console__helpTab .helpPanelHeader__box {
        border-bottom: 2px solid #f8aa01;
    }
}