.container{
    display: flex;
    flex-direction: column;
}

.search__input__container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    width: 290px;

    :global(.search_input){
        height: 34px;
        background-size: 14px 14px;
    }

    :global(.search_reset){
        top: 10px;
        right: 12px;
        width: 16px;
        height: 16px;
    }
}

.pageTitle{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
}

.pageTableHeader{
    display: flex;
    justify-content: flex-start;
}


.mainContainer{
    display: flex;
    column-gap: 24px;
    flex: 1;

}

.gitSettingsForm{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 10px;
    margin-top: 35px;
}

.gitFormLabel{
    margin-bottom: -5px;
    font-size: 14px;
}

.gitFormButton {
    background-color: transparent;
    color: white;
    margin-right: 5px;
}

.gitButton_group{
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.usersTable{
    flex: 1;
}


.roleBox{
    height: 22px;
    border-radius: 2px;
    padding: 2px 4px;
    color: white;

    &:global {
        &.isAdmin{
            background: #006198;        
        }
        &.isUser{
            background: #ff0f0f;        
        }
    }
}


.statusInfo {
    padding: 2px 4px;
    color: white;
}

.statusActive{
    color: #26c000;

}

.statusInActive{
    color: #d80024;

}

.userInfoPane{
    width: 30%;
    background: #1E2038;
    border: 1px solid #666A8D;
    border-radius: 4px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
}

.userInfoPaneHeader{
    padding-bottom: 12px;
    border-bottom:  1px solid #666A8D;
    margin-bottom: 24px;

    p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    span{
        font-size: 12px;
    }
}

.userInfoFormContainer{
    height: 100%;
}

.userInfoFormContainerSpinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.userInfoFormContainer, .userInfoForm {
    :global(.radiofield__inner__container){
        margin-bottom: 0;
    }
}

.userInfoForm{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.sinkForm{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.userInfoFormActionBtnsContainer{
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    justify-self: flex-end;

}

.edit-user{
    flex: 1;
    min-width: auto;
}

.delete-user {
    width: 40px;
    min-width: auto;
    padding: 0;
    // height: 40px;
}

.selectedTableRowState{
    &:nth-child(n) {
        border: 1.5px solid #FCAD01;
        background: #2A2C42;
        border-radius: 2px;
    }
}

.btnNewUser{
    min-width: auto;
    display: flex;
    align-items: center;    
    justify-content: center;
    column-gap: 6px;
}

.addEditUserModal{
    .userInfoForm{
        margin-bottom: 30px;
    }   

    :global(.modal__title){
        text-align: left;
    }

    :global(.modal__subtitle){
        display: flex;
        width: 100%;
        text-align: left;
        justify-content: space-between;
        align-items: center;
    }

    :global(.inPageSpinner)  {
        height: auto;
        width: auto;

    }

}

.addEditUserModal__innerContainer{
    padding: 16px 24px;
    color: white;
    width: 396px;
}

.userStatusLabel {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
}

.addEditUserModal__actionBtns{
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    & > div{
        width: 100%;
    }

}
.mt-0 {
    margin-top:0 !important;
}
.centerLoading {
    width: 100%;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_input {
    margin-left: 0;
    width: 22rem;
}

.search_container {
    margin-left: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.help_center {
    display: flex;
    align-items: center;
    img {
        margin-right:0.5rem;
    }
    &:hover {
        font-weight: bold;
    }
}

.nifi_titles {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-right: 1.5rem;
    row-gap: 1rem;
    column-gap: 1rem;
    max-height: calc(100vh - 190px);

    .title {
        font-size: 0.875rem;
        color:white;
        margin:0;
    }

    .sinkCard{
        padding:1rem 1rem 0.25rem;
        height: max-content;
    }

    .sinkTitleContainer{
        justify-content:center;
        align-items:center;
    }
    
    .sinkTitle {
        font-weight: 500px;
        font-size: 16px;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:12px;
    }

    .sinkInfo{
        margin-top:16px;
        margin-bottom:16px;
    }
    
    .actionContainer{
        margin-top: 16px;
    }

    .spinner-container{
        position: fixed; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%); 
        z-index: 9999; 
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw; 
        height: 100vh;
        pointer-events: none; 
    }
    
    .modDescription {
        font-size: 0.875rem;
        color:white;
        margin:0;
        height: 60px;
    }
    
    li {
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #212233;
        width: 22rem;
        
        > div {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
         }
    }

    .key {
        color: rgba(255, 255, 255, 0.48);
    }

    .editButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid rgba(255, 255, 255, 0.48) ;
        border-radius: 2px;
        color:white;
        font-size: 0.875rem;
        padding: 2px 0;
        width: 100%;
        margin-bottom:0.5rem;
        &:hover {
            border-color: white;
        }
    }
}

.badge-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 0.75rem;

    .status-badge{
      height: 26px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 0.8125rem;
      padding: 3px 2px 3px 6px;
      .label{
        color: #fff;
        text-transform: capitalize;
        margin-left: 0.5rem;
        margin-right: 0.75rem;
        font-size: 0.75rem;
        font-weight: 700;
      }
      .info__icon {
        margin: 0;
        margin-top: 1px;
      }
    }
    .circle {
        border-radius: 100%;
        width: 0.625rem;
        height: 0.625rem;
        margin-left: 0.5rem;
    }
    .running {
        > .label{
            color: #A0D94D;  
        }
        > .circle
        {
            background-color: #A0D94D;  
        }
    }
   
    .terminated {
        > .label{
            color: #FF7766;  
        }
        > .circle {
            background-color: #FF7766;  
        }
    }
}

.divider {
    background-color: rgba(255, 255, 255, 0.08);
    height: 1px;
    width: 100%;
}

.admin__container{
    display: flex;
    color: #ffffff;
    font-size: 12px;
    height: 100vh;
    overflow: hidden;
    background-color: #3E4161;

   
    .btn-lg {
        height: 36px;
        min-width: 100px;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        font-size: 1.5rem;
        color:white;
        margin: 2rem 0 2rem 0.75rem ;
    }

    .content_holder {
        flex-grow: 1;
        padding: 1.5rem;
        overflow: auto;
    }
    .truncate {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .search_holder {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 118px;
        z-index: 1;
    }

    header {
        display: flex;
        justify-content: space-between;
        color:white;
        h1 {
            color:white;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            img {
                margin-right: 0.5rem;
            }
        }
    }

    .treeview__container{
        background-color: #030613;
        padding:0.75rem;
        width: 276px;
        min-width: 276px;

        ul {
            list-style: none;
        }

        li {
            padding: 0.625rem 0;
            border-radius: 2px;

            &:hover {
                background-color: #5b608fae;   
            }
            &.selected {
                background-color: #5B608F;
            }
            a {
                color: white;
                font-size: 1rem;
                list-style: none;
                span {
                    padding-left:1rem;
                }
                img {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.grow {
    flex-grow: 1;
}

.auto {
    width : auto !important;
    height : auto !important;
}

.addEditNifiModal__innerContainer{
    padding: 16px 24px;
    color: white;
    width: 396px;
}

.addEditNifiSinkModal__innerContainer{
    padding: 16px 24px;
    color: white;
    width: 552px;
}

.nifiStatusLabel {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
}

.addEditNifiModal__actionBtns{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1rem;;

    & > div{
        width: 100%;
    }

}

.addEditNifiSinkModal__actionBtns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
}

.addEditNifiModal{
    :global(.modal__header){
        padding: 16px 16px 0px 24px;
        background-color: #3E4161;
        text-align: start;
        position: relative;
    }
    :global(.modal__body){
        background-color: #3E4161;
        font-weight: 500;
        font-size: 12px;
       
    }
    :global(.modal__title){
        font-weight: 700;
        font-size: 16px;
    }
}

.nifi-edit-btn{
    min-width: 96px;
    height: 26px;
}

.nifi-cancel-btn{
    min-width: 96px;
    height: 26px;
}

.nifi-delete-btn{
    min-width: 96px;
    height: 26px;
}

.nifi-sink-modal-subtitle{
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    color: #C7C7CD;
}

.sink-delete-btn{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.nifi_content{
    flex: 1;
    padding: 1.5rem 0 0 1.5rem;
}

.tabContent__container{
    padding-top: 4rem;
}

.ml-half {
    margin-left: 0.5rem;
}

.mt-half{
    margin-top: 0.5rem;
}

.inline {
    display: flex;
    justify-content: flex-start;
}

.select_container{
    display: flex;
}

.select_container_item{
    flex: 1;
    margin-right: 4px;
}

.select_container_item:last-child{
    margin-right: 0;
}

.gitFormField {
    background-color: #030613;
    color: #fff; 
    border: 1px solid #333; 
    padding: 8px; 
    border-radius: 4px;
    width: 100%;
    font-size: 14px;
  }

  .gitFormField:disabled {
    background-color: lightgrey;
    cursor: not-allowed; 
  }

  .gitsearch_holder {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    position: absolute;
    z-index: 1;
    margin-left: 20px;
}

.gitCopyUrlButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 18.5rem;
    padding: 0;
  }

  .gitAdminsidebar {
    background-color: #212233;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .gitForm {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .gitEditDeleteButton{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .errorText {
    color: red;
    font-size: 12px;
  }

  .nodeTypeCon {
    width: 130px;
  }

  .nodeTypeInner {
    width: 100%;
  }

 .assetList{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-top:20px 
 }

 .hierarchyName {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 130px; 
    display: inline-block;
  }

.addEditModelModal__innerContainer{
    margin-top: 20px;
    padding: 16px 24px;
    color: white;
    width: 800px;
    min-height: 70vh;
    background-color: #2A2C42;
    border-radius: 4px;
    border: 1px solid #fff;
}

.addEditModelModal__actionBtns{
    display: flex;
    flex-direction: row;
    gap: 1rem;

    & > div{
        width: 100%;
    }

}

.attributes-table{
    height: 70vh;
    input {
		width: 100%;
		padding: 4px 8px;
		font-size: 14px;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
  
		&:focus {
		  border-color: #fcad01;
		  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		}
        &:hover {
			border-color: #fcad01;
			box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
		  }
	  }
     
}

.assetForm{
    display: flex;
    flex-direction: column;
    height: 68vh;
    row-gap: 30px;
}

   
.attributes-lists{
    padding: 20px; 
    border: 1px solid #fff;
    background: #2A2C42;
    height: 58vh;
    margin-top: 20px; 
    margin-bottom: 20px;
}

.attributes-lists-csv{
    padding: 20px; 
    border: 1px solid #fff;
    background: #2A2C42;
    height: 40vh;
    margin-top: 20px; 
    margin-bottom: 20px;
}

.attributes-lists__data{
  max-height: 30vh;
  overflow-x: hidden;
}

.attributes-row{
    margin-top: 16px;
    margin-bottom: 16px;
}

.actions {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid #ddd;
    background: #3e4161;
  }
  
  .actionButton {
    color: #fcad01;
  }
  
  .actionButton:hover {
    color: #007bff;
  }
  
  .banner {
    background: grey; 
    color: #fff;
    padding: 8px ;
    border-radius: 4px;
    font-size: 14px;
  }
   .pullPushButton {
    background-color: #007bff;
    font-size: 14px;
   }


.hierachy__container{
    display: flex;
    color: #ffffff;
    font-size: 12px;
    overflow: auto;
    width: 100%;
    height: 100vh;
    background-color: #3E4161;

   
    .btn-lg {
        height: 36px;
        min-width: 100px;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        font-size: 1.5rem;
        color:white;
        margin: 2rem 0 2rem 0.75rem ;
    }

    .content_holder {
        flex-grow: 1;
        padding: 1.5rem;
        overflow: auto;
    }
    .truncate {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .search_holder {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        position: absolute;
        top: 118px;
        z-index: 1;
    }

    header {
        display: flex;
        justify-content: space-between;
        color:white;
        h1 {
            color:white;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            img {
                margin-right: 0.5rem;
            }
        }
    }

    .treeview__container{
        background-color: #030613;
        padding:0.75rem;
        width: 276px;
        min-width: 276px;

        ul {
            list-style: none;
        }

        li {
            padding: 0.625rem 0;
            border-radius: 2px;

            &:hover {
                background-color: #5b608fae;   
            }
            &.selected {
                background-color: #5B608F;
            }
            a {
                color: white;
                font-size: 1rem;
                list-style: none;
                span {
                    padding-left:1rem;
                }
                img {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.fileMetaDataContainer {
    margin-top: 20px;
    margin-right: 20px;
    padding: 24px;
    max-width: 92%;
    overflow: auto;
    background: #212233;
    border-radius: 8px;
    
    .actionPanel {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        
        .leftActions {
            display: flex;
            gap: 12px;
        }
        
        .rightActions {
            flex: 0 0 300px;
        }
    }

    .actionButton {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 40px;
        padding: 0 16px;
        background: #fcad01;
        border: 1px solid #3f4164;
        border-radius: 6px;
        color: #000;
        transition: all 0.3s ease;

        &:hover {
            background: #3f4164;
            border-color: #515580;
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

    .deleteButton {
        @extend .actionButton;
        background: #422a2a;
        border-color: #643f3f;

        &:hover {
            background: #643f3f;
            border-color: #805151;
        }
    }

    .searchInput {
        :global {
            .ant-input {
                background: #2E2F45;
                border: 1px solid #363859;
                border-radius: 6px;
                color: #E1E1E6;
                padding: 8px 12px;
                height: 40px;
                font-size: 14px;
                transition: all 0.3s ease;

                &::placeholder {
                    color: #8C8CA1;
                }

                &:hover {
                    border-color: #4A4B6A;
                }

                &:focus {
                    border-color: #6366F1;
                    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
                }
            }
        }
    }

    .dataTable {
        :global {
            .ant-table {
                background: #2E2F45;
                border-radius: 8px;
                overflow: auto;
                max-width: 76vw;
                max-height: 64vh;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                
                .ant-table-thead > tr > th {
                    background: #383A57;
                    color: #fff;
                    border-bottom: 2px solid #2E2F45;
                    font-weight: 600;
                    padding: 12px 16px;
                    
                    &:first-child {
                        border-top-left-radius: 8px;
                    }
                    
                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }

                .ant-table-tbody > tr {
                    td {
                        padding: 12px 16px;
                        border-bottom: 1px solid #363859;
                        color: #E1E1E6;
                        transition: all 0.3s ease;
                    }

                    &:hover > td {
                        background: #363859 !important;
                    }

                    &.ant-table-row-selected > td {
                        background: #404266;
                    }

                }

                .ant-input {
                    background: #000;
                    border: 1px solid #454870;
                    color: #fff;
                    border-radius: 4px;
                    transition: all 0.3s ease;

                    &:hover, &:focus {
                        border-color: #5C60A3;
                        box-shadow: 0 0 0 2px rgba(92, 96, 163, 0.2);
                    }

                    &::placeholder {
                        color: #8285A7;
                    }
                }
            }
        }
    }

    .dataTableRevert { 
        :global {
            .ant-table {
                background: #2E2F45;
                border-radius: 8px;
                overflow: auto;
                max-width: 100vw;
                max-height: 64vh;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                
                .ant-table-thead > tr > th {
                    background: #383A57;
                    color: #fff;
                    border-bottom: 2px solid #2E2F45;
                    font-weight: 600;
                    padding: 12px 16px;
                    
                    &:first-child {
                        border-top-left-radius: 8px;
                    }
                    
                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }

                .ant-table-tbody > tr {
                    td {
                        padding: 12px 16px;
                        border-bottom: 1px solid #363859;
                        color: #E1E1E6;
                        transition: all 0.3s ease;
                    }

                    &:hover > td {
                        background: #363859 !important;
                    }

                    &.ant-table-row-selected > td {
                        background: #404266;
                    }

                    &:last-child > td {
                        background: rgba(99, 102, 241, 0.35) !important;
                    }
                }
            }
        }
    }

    .tablePagination {
        :global {
            .ant-pagination-item,
            .ant-pagination-prev,
            .ant-pagination-next {
                background: #3e4161;
                border-color: #3f4164;
                
                a {
                    color: #fff;
                }

                &:hover {
                    border-color: #515580;
                }
            }

            .ant-pagination-item-active {
                background: #3e4161;
                border-color: #fcad01;
            }
        }
    }

    .emptyState {
        padding: 24px;
        text-align: center;
        color: #8e8ea0;
        background: #2a2c42;
    }
}

.versionButton {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.dataTableRevertPreview { 
    :global {
        .ant-table {
            background: #2E2F45;
            border-radius: 8px;
            max-width: 100vw;
            overflow: auto;
            max-height: 64vh;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            
            .ant-table-thead > tr > th {
                background: #383A57;
                color: #fff;
                border-bottom: 2px solid #2E2F45;
                font-weight: 600;
                padding: 12px 16px;
                
                &:first-child {
                    border-top-left-radius: 8px;
                }
                
                &:last-child {
                    border-top-right-radius: 8px;
                }
            }

            .ant-table-tbody > tr {
                td {
                    padding: 12px 16px;
                    border-bottom: 1px solid #363859;
                    color: #E1E1E6;
                    transition: all 0.3s ease;
                }

                &:hover > td {
                    background: #363859 !important;
                }

                &.ant-table-row-selected > td {
                    background: #404266;
                }

            }
        }
    }
    .tablePagination {
        :global {
            .ant-pagination-item,
            .ant-pagination-prev,
            .ant-pagination-next {
                background: #3e4161;
                border-color: #3f4164;
                
                a {
                    color: #fff;
                }

                &:hover {
                    border-color: #515580;
                }
            }

            .ant-pagination-item-active {
                background: #3e4161;
                border-color: #fcad01;
            }
        }
    }
}
.activeRow {
    td {
        background: rgba(99, 102, 241, 0.35) !important;
    }
}

.dataRow {
    td {
        background: transparent;
    }
}