.useFileSelector {
	position: relative;
	.inputfield {
		width: 93%;
	}

	.btn-fileBrowser {
		position: absolute;
		top: 39px;
		right: 25px;
		margin-left: 8px;
		width: 25px;
		min-width: auto;
		padding-left: 1px;
		box-shadow: none;
	}
}

.useCaptureSchema {
	padding-right: 16px;
	border-bottom: 1px solid #393e53;
	&.__inputField {
		display: flex;
		column-gap: 8px;

		.inputfield__container{
			padding-right: 0;
			border-bottom: 0;
		}
	}

	position: relative;

	.btnCaptureSchema__outer{
		display: flex;
		

		&.btnCaptureSchema__captureSchemaField{
			margin-top: 39px;
		}
	}

	.btns__captureSchema {
		

        &.moveButtonToLeft{
			padding: 16px 0 19px 16px;
			margin-left: 0;
			width: 100%;

			.innerCaptureSchemabox{
				margin-bottom: 19px;
			}

			.btnCaptureSchema__outer{
				margin-top: 0px;
			}

			.runWorkflow__options .dropdown__menu.dropdown__menu--right{ 
				right: -39px;
			}
        }
	}

	

	.innerCaptureSchemabox{
		display: flex;
    	align-items: center;
	}

	.btn-capture {
		border-color: transparent;
		box-shadow: none;
		width: 28px;
		padding-left: 0;
		font-weight: 600;
    	letter-spacing: 0.25px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		padding-right: 0;
		// display: flex;
		// align-items: center;

		&:hover,
		&:focus {
			color: #262231;
			border-color: transparent;
			background-color: #fcad01;
		}

		&.ant-btn-loading::before {
			content: none;
		}
	}

	&.schemaCaptureInProgress {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		


		.btn-capture {
			margin-right: 8px;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 2px;
			
			svg {
				width: 16px;
				height: 16px;
			}
		}

		.btn-cancelSchemaCapture {
			display: flex;
			margin-top: 39px;

			svg {
				width: 16px;
				height: 16px;
			}
		} 

		.captureSchema__loadingSkeleton{
			margin-right: -16px;
			grid-column-start: 1;
			grid-column-end: 3;
		}

    }
    
    .captureSchema__Fields{
		margin-left: -16px;
		margin-right: -16px;
        padding: 12px 22px 12px 16px;
        background-color: #494C7A;

        .select-field {
            padding: 0 0 12px 12px;
            border-bottom: 0;
            border-left: 2px solid #2A2C42;

            &:last-of-type{
                padding-bottom: 0;
            }
        }
    }
	
	.btn-resetCaptureSchema{
		position: absolute;
		right: 8px;
		top: 88px;
		svg {
			transform: rotate(-45deg) scaleX(-1);
		}
	}

    .selectedFieldsBox{
        padding-top: 4px;
        background-color: transparent;
        border-radius: 2px;
        // min-height: 64px;
        overflow-y: auto;
        margin-bottom: 24px;

        .multiple-select-tags{
            margin-bottom: 8px;
		}
		
		.noColsMsg{
			color: rgba(255, 255, 255, 0.32);
			font-size: 12px;
		}
    }

    .captureSchemaTimeAgo{
        font-size: 10px;
        line-height: 16px;
        opacity: 0.72;
        margin-left: 16px;
        display: inline-block;
		color: white;
    }
    

    .selectionInfoMsgBox{
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        svg{
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }
    }

    .selectionInfoMsg{
        font-size: 10px;
        line-height: 16px;

        
	}
	
	.btn-viewLog{
		margin-left: 16px;
		text-decoration: underline;
		font-size: 12px;
		color: white;
	}

	&.tableComponent__captureSchema{
		// transparent is set to fix alignment issues
		border-color: transparent;
		padding-right: 10px;

		.btn-viewLog{
			margin-right: 10px;
		}
	}

	.singleColumn__selection .select-field:first-of-type{
		padding-top: 8px;
	}
}

.titleInfoText__selectFieldOptionLabel{
	display: flex;
	width: 100%;
	justify-content: space-between;

	letter-spacing: 0.25px;

	span:last-of-type {
		color: #FFFFFF;
		opacity: 0.72;
		font-weight: 400;
		// width: fit-content;
		margin-left: 8px;
		// min-width: 50px;
		// text-align: end;
		flex: 0 0 auto;
	}


}

$base-color: #14182a;
$shine-color: #2d3554;
$animation-duration: 1.2s;

@mixin background-gradient {
	background-image: linear-gradient(
		90deg,
		$base-color 0%,
		$shine-color 50%,
		$base-color 100%
	);
	background-size: 400% 400%;
}

.captureSchema__loadingSkeleton {
	padding: 14px 16px;
	background-color: #494c7a;
	
	.field {
		opacity: 0.4;
		margin-bottom: 7px;
		padding-left: 14px;

		&:first-child{
			margin-bottom: 28px;
			padding-left: 0px;
		}


		.line1,
		.line2 {
			border-radius: 2px;

			@include background-gradient;
			animation: shine-lines $animation-duration infinite linear;
		}
		.line1 {
			margin-bottom: 3px;
			height: 21px;
			width: 48%;
			opacity: 0.6;
		}
		.line2 {
			width: 99%;
			opacity: 0.7;
			height: 26px;
		}

		@keyframes shine-lines {
			0% {
				background-position: 0% 0%;
			}
			100% {
				background-position: -135% 0%;
			}
		}
	}
}

.multiSelectField{
	position: relative;

	.portTickers__main{
		position: absolute;
		top: 10px;
		right: 0;
	}

	&:nth-child(2){
		.inputfield__label {
			display: block;
		}
	}

	.select-field {
		.inputfield__label {
			display: none;
		}

		&:nth-child(2){
			.inputfield__label {
				display: block;
			}
		}
	}

	.select-field:not(:last-of-type) {
		border-bottom: 0;
		padding-bottom: 0;
	}

}

.loop-dropdown .select-field-options-outer{
	// min-width: 190px !important
	right: 0;
    left: auto;
    min-width: 170px;
	top: 0px;
	
	// .select-field-options{
	// 	width: 170px;
	// 	min-width: 150px;
	// }
}

.mlpipeline__box {
	margin-right: 36px;
	margin-left: 16px;
	.useFileSelector {
		display: flex;
		justify-content: left;
	}
	.modalBtns__box {
		margin-top:1rem;
	}
	> div {
		padding: 0 !important;
		margin: 1rem 0 !important;
		> .inputfield__container, >.select-field {
			padding: 0 !important;
			margin: 1rem 0 !important;
		}
		.inputfield {
			width:100%;
		}
		.btn-fileBrowser {
			right:1px;
		}
	}

	.selectToConfigure__msg{
		font-size: 10px;
		margin-top: 2px;

		.info__icon{
			margin-left: 0;
			margin-top: 0;
			margin-right: 4px;
		}
	}

	#btn_configure_pipeline{
		margin-top: 1px;
		display: flex;
		align-items: center;
	}

	.select-field, .inputfield__container, .radiofield__container {
		border-bottom: 0;
	}

	.mode__field .select-field {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 8px;
	}

	.transform__fields{
		.useFileSelector {
			.inputfield__container{
				padding-top: 19px !important;

			}
			.inputfield {
				width: 90%;
			}
		}
		.btn-fileBrowser{
			top: 42px;
			right: 0;
		}

		.select-field, .inputfield__container {
			// padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
			padding-top: 19px;
			padding-left: 0;

			&:first-of-type{
				padding-top: 11px;
			}
		}
	}

	
}

#btn_new_experiment{
	display: flex;
	align-items: center;
	// line-height: 24px;
	width: 100%;
	height: 100%;
	svg {
		width: 12px;
		height: 12px;
		margin-right: 4px;
		// margin-top: 6px;
	}
}


.newExperimentModal__container {
	width: 396px;

	.modal__body{
		padding-top: 0;
	}

	.inputfield__container{
		margin-bottom: 12px;
	}

	.select-field{
		margin-bottom: 18px;
	}
}

.loseUnSavedChangesbgOverlay{
	background: #2A2C42;
	opacity: 0.72;
	pointer-events: none;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	pointer-events: none;
	z-index: 6;
}

.configurePipelineModal__container{
	width: 1000px;
    margin-top: 70px;
    height: calc(100% - 140px);
    margin-bottom: 70px;
	top: 0px !important;
	left: 50%;
    transform: translate(-50%, 0) !important;
	// height: 800px;

	.modal__header{
		height: 78px;
	}

	.modal__body{
		padding: 16px 24px 24px 6px;
		height: calc(100% - 78px);
		// display: flex;
		// flex-direction: column;
		// justify-content: space-between;
	}

	.configurePipeline__outer{
		display: flex;
		margin-bottom: 16px;
		flex: 1;
		position: relative;
		height: calc(100vh - 310px);
		overflow: auto;
		direction: rtl;
		flex-direction: row-reverse;

		& > div {
			direction: ltr;

		}

		.inPageSpinner{
			align-items: center;
			width: 100%;
		}
	}

	.modalBtns__box{
		padding-right: 0;
	}

	

	.configurePipeline__LHS{
		// width: 41%;
		// margin-right: 24px;
		// flex: 1;
		width: 350px;
		padding-left: 18px;

		.select-field, .inputfield__container{
			margin-bottom: 19px;
		}

		.useCaptureSchema {
			margin-bottom: 20px;	
	
			.inputfield__container{
				padding-top: 0;
				padding-bottom: 0;
			}

			.btnCaptureSchema__outer, .btn-cancelSchemaCapture{
				margin-top: 25px;
			}
	
			.btns__captureSchema{
	
				&.moveButtonToLeft{
					padding-top: 0;
					padding-left: 0;
					

					.btnCaptureSchema__outer{
						margin-top: 0;	
					}
					 
				}
			}
		}

		.checkbox__encodeCategFeatures{
			margin-bottom: 18px;

			.optionsList span{
				font-weight: 600;
			}

		}

		.checkboxfield__container{
			margin-bottom: 18px;
			height: 20px;

			.radiofield__inner__container{
				margin-bottom: 0;
			}

			.optionsList span{
				font-weight: 600;
			}
		}

		.balanceMode__radioField{
			margin-bottom: 18px;
		}

		.inner_fields{
			& > .checkboxfield__container{
				margin-bottom: 18px;
				height: 20px;

				.radiofield__inner__container{
					margin-bottom: 0;
				}

				.optionsList span{
					font-weight: 600;
				}
			}

			.checkboxfield__container.checkbox__active{
				margin-bottom: 8px;
			}

			.select-field, .inputfield__container {
				width: 45%;
				padding-top: 0;
				padding-bottom: 0;
			}

			.split_for_test_fields{
				display: flex;
				margin-bottom: 18px;

				.inputfield {
					width: 42px;
					padding: 4px 5px;
    				text-align: center;
				}
		
				.inputfield__container{
					margin-right: 16px;
					padding: 0;
					width: auto;
					padding-bottom: 0;
					margin-bottom: 0;

				}
		
				
			}

			.radiofield__inner__container{
				margin-bottom: 7px;
			}
		}
		
		.split__fields--halfwide > div{
			width: 47%;
		}
	}

	

	.checkboxfield__container{
		padding-bottom: 0;
		padding-top: 0px;
		height: 24px;
		// margin-bottom: 6px;
	}

	.validate_model_fields{
		.validate_model_inner_fields{
			display: flex;
		}

		.select-field{
			margin-bottom: 8px;
			margin-right: 12px;
		}

		.editor_holder{
			min-height: auto !important;
		}
	}

	

	.build_pipeline__box{
		padding-left: 16px;
	}


	.configurePipeline__RHS{
		width: 580px;
		position: fixed;
		right: 24px;

	}

	.configurePipeline__RHS__collapse{
		background-color: #2A2C42 !important;

		.ant-collapse-header{
			padding: 0 14px 0 16px;
			height: 48px;
			font-size: 12px;
			font-weight: 700;
			line-height: 48px;
			letter-spacing: 0.25px;
			color: #FFFFFF;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
		.collapseIcon.ant-collapse-arrow{
			position:initial;
			transform: none;
			display: none;
			&.rotate {
				svg {
					transform: rotate(-180deg);
				}

			}

		}

		&.tensorflowMode{

			.collapseIcon{
				display: block;
			}

			.ant-collapse-header{
				flex-direction: row-reverse;
			}
		}

		.ant-collapse-content-box{
			padding: 0;
		}

		.ant-collapse-item-disabled.configurePipeline__RHS__innerBox .ant-collapse-header {
			cursor: default !important;
		}
	}

	.configurePipeline__RHS__innerBox{
		background-color: rgba(31, 33, 50, 0.32);
		border: 1px solid rgba(102, 106, 141, 0.32);
		border-radius: 2px;

		&.tensorflowParams__box{
			margin-top: 12px;
		}

		.tensorflowParams__innerBox{
			margin: 0 0px 24px 0px;
			background: rgba(102, 106, 141, 0.72);
			mix-blend-mode: normal;
			border-radius: 4px;
			padding: 16px 12px;
		}

		.modelParam__box{
			margin-bottom: 17px;

			.hyperParamsTable__box{
				margin-top: 0;
				color: white;
			}

			.hyperParamsTable__box.tune__disabled .columnsSelector__box {
				left: 193px;
			}

			#btn_delete_modelParamStage {
				position: inherit;
				margin-top: -5px;
				margin-left: 1px;
			}

			&.multiOptimizer__stage{
				padding-left: 24px;

				.hyperParamsTable__box.tune__disabled .columnsSelector__box{
					left: 169px;
				}
			}
		}

		.modelParam__selectField{
			margin-bottom: 13px;
			width: 150px;
			
			.inputfield__label{
				font-weight: 700;
			}
		}

		.columnsList__box{
			z-index: 5;
		}

		.btn_new_modelParamStage{
			margin-top: 17px;
			padding-right: 12px;
			width: auto;
			padding-left: 8px;

			&:hover{
				width: auto;
			}

			span{
				display: block;
			}
		}
	}

	.pipelineStage__box{
		color: #FFFFFF;

		.pipelineStage__upperBorder{
			content: '';
			// position: absolute;
			border-right: 1px dashed #6D707E;
			height: 13px;
			width: 12px;
		}
	}
	// padding: 11px 32px 11px 12px;


	.pipelineInfo__fields{
		display: flex;
		justify-content: space-between;

		& > div {
			width: 47%;
		}

		div:nth-child(odd){
			margin-right: 12px;
			// width: 70%;
		}

		&.datacategory__fields{
			flex-wrap: wrap;

			& > div:nth-child(1){
				width: 38%;
			}

			& > div:nth-child(even){
				width: 58%;
			}

			& > div:nth-child(3){
				margin-right: 0;
			}

			// &:nth-child(odd){
				// width: 30%;
			// }
			// &:nth-child(even){
			// 	width: 100%;
			// }
			&.hideDataCategory{
				& > div {
					width: 100%;
				}
			}
		}
	}

	.pipelineStage__name{
		font-size: 12px;
		letter-spacing: 0.25px;
		line-height: 14px;
		font-weight: 600;
		position: relative;
		margin-bottom: 12px;
		padding-left: 13px;
		padding-top: 13px;

			&::before{
				content: '';
				position: absolute;
				width: 9px;
				top: 20px	;
				left: 0;
				border-bottom: 1px dashed #6D707E;
			}
	}

	#btn_delete_stage{
		img {
			height: 24px;
			width: 24px;
			position: absolute;
			top: 6px;
    		left: 65px;
		}
	}


	.pipelineStages__outer{
		// margin-top: 10px;
		padding: 0 16px 16px;
		height: calc(100vh - 370px);
		overflow-y: auto;

		&.tensorflowMode{
			height: calc(100vh - 430px);

		}
	}

	.pipelineName__box{
		height: 16px;
		margin-top: 16px;
		margin-left: 16px;

		.pipelineName{
			color: #FFFFFF;
		}
	}

	.noPipelineName__msg, .noCategory__msg{
		color: rgba(255, 255, 255, 0.4);
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		height: 15px;
	}


	.pipelineStage__innerBox{
		padding: 0 16px 12px 0;
		margin-left: 12px;
		border-left: 1px dashed #6D707E;
		
	}

	

	.pipelineStage__form{
		background-color: rgba(102, 106, 141, 0.72);
		border-radius: 4px;
		width: 100%;
		padding: 12px 12px 15px;
		position: relative;
		margin-left: 13px;

		& > div:last-child{
			margin-bottom: 0;

			& > div:last-child {
				margin-bottom: 0;
			}
		}

		.select-field, .inputfield__container{
			width: 150px;
			margin-bottom: 12px;
			margin-right: 16px;
		}

		.operation_subType_field{
			position: absolute;
			top: 12px;
			left: 194px;
		}

		.radiofield__container{
			margin-bottom: 12px;

			.radiofield__inner__container{
				margin-bottom: 0;
			}
		}

		.numberInput_field{
			display: inline-block;
			margin-right: 16px;
			width: auto;
		
			.inputfield{
				width: 42px;
			}
		}

		.modelType_field{
			width: 200px;
		}

		.apply_window_fields{
			display: flex;
			justify-content: space-between;

			.inputfield__container{
				margin-right: 56px;
				margin-bottom: 0;
			}
	
			.select-field{
				width: 135px;
				margin-right: 32px;
				margin-bottom: 0;
			}

			.labelCol__field{
				width: 47%;
			}
	
		}

		.labelCol__field{
			.btns__captureSchema{
				margin-top: 24px;

				// top: 23px;
				// right: auto;
				// left: 125px;

				&.moveButtonToLeft{
					padding-left: 0;
					padding-top: 0;
					margin-top: 0;

					.innerCaptureSchemabox{
						margin-top: 12px;
					}

					.captureSchema__Fields{
						margin-left: -12px;
						margin-right: -28px;
					}
				}
			}

			.useCaptureSchema .btnCaptureSchema__outer.btnCaptureSchema__captureSchemaField, .useCaptureSchema.schemaCaptureInProgress .btn-cancelSchemaCapture  {
				margin-top: 0;
			}

			.select-field{
				width: 100%;
			}

			.captureSchema__loadingSkeleton{
				margin-left: -12px;
				margin-right: -28px;
			}
		}
	}

	.tuneField__rangeBox{
		display: flex;
		align-items: center;
		// height: 24px;
		height: 100%;

		.numberInput_field{
			width: auto;
			margin-right: 4px;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			height: 24px;

			.inputfield__label{
				font-size: 12px;
				margin-bottom: 0;
				margin-right: 2px;
			}

			.inputfield{
				background-color: #2A2C42;
				border-radius: 2px;
				width: 31px;
				height: 24px;
				padding: 4px 3px;
				text-align: center;
			}
		}

		.inputfield__errormessage{
			display: none;
		}
	}

	.minMax__fields .numberInput_field{
		margin-bottom: 0;
	}

	.newExperimentFields__box{
		padding-bottom: 24px;
		border-bottom: 1px solid rgba(155, 159, 198, 0.32);
		margin-bottom: 20px;

		
	}

	.inner_fields .checkboxfield__container.checkboxfield__disabled .optionsList span {
		font-weight: 400;
	}

	.btn_new_stage{
		border-radius: 12px;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:disabled{
			opacity: 0.3;
			border-color: #FFFFFF;
			color: #FFFFFF;
			cursor: default;

			&:hover{
				width: 24px;
				box-shadow: none;
				span {
					display: none;
				}
			}
		}
		
		svg{
			width: 12px;
			height: 12px;
		}
	
		span {
			display: none;
			margin-left: 3px;
			color: #FFFFFF;
			font-family: 'DM Sans';
			font-size: 14px;
			font-weight: 600;
			line-height: 24px;
		}
	
		&:hover{
			width: 111px;
			span {
				display: inline;
			}
		}
	}

	.inputTypeFields__box{
		margin-bottom: 18px;

		.inputfield__container{
			margin-bottom: 0;
		}

		
	}

	.filePath__selectorBox{
		display: flex;

		.btn-showFileSelector{
			height: 24px;
			width: 24px;
			margin-left: 8px;
			padding-left: 0;
			margin-top: 23px;
			

			svg {
				transform: rotate(180deg);
				transition: transform 0.25s;
				
				g path {
					fill: #14182a;
				}
			}

			&.rotate svg{
				transform: rotate(0deg);
			}
		}
	}

	.mlPipelineFileBrowser{
		color: white;
		margin-top: 8px;
		height: 217px;
		transition: height 0.25s;

		>div {
			background-color: #14182a;
		}

		.azureBlobStoragePage{
			padding-bottom: 0;

			.ReactVirtualized__List{
				overflow: initial !important;
				// overflow-x: hidden !important;
			}
			.chonky-file-list-entry-name {
				flex-grow: 0 !important;
			}
		}

		.chonky-side-inside.chonky-side-inside-right {
			display: none;
		}

		.chonky-controls{
			padding-left: 8px;
			padding-right: 8px;
			background: rgba(0, 0, 0, 0.24);
			mix-blend-mode: normal;
			border-radius: 2px 2px 0px 0px;
		}

		.chonky-file-list.chonky-file-list-details {
			// padding-right: 14px;
			padding-left: 0;
			padding-right: 0;

			.chonky-file-list-entry-date, .chonky-file-list-entry-size{
				display: none;
			}
		}

		.azureBlobStoragePage{
			height: 100%;
		}


		&.collapse{
			height: 0;
			overflow: hidden;

			.azureBlobStoragePage{
				height: 0;
			}

			.chonky-controls {
				height: 0;
				display: none;
			}

			
		}
	}

	.radiofield__container{
		color: white;
	}

	.stageContextMenuTrigger{
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 4px;
		position: absolute;
		top: 8px;
		right: 0;

		svg {
			height: 14px;
		}


		&.active, &:hover {
			background: #666A8D;
			border-radius: 50%;
		}
	}


	.stored_pipeline_fields{
		display: flex;
		// column-gap: 16px;

		.select-field:nth-of-type(1){
			width: 180px;
		}

		.select-field:nth-of-type(2){
			width: 100px;
		}
	}

	
}
.stageContextMenu{
	width: 125px;
}

.hyperParamsTable__box{
	border-radius: 4px;
	margin-top: 22px;

	&.tune__disabled{
		.columnsSelector__box{
			left: 172px;
		}
	}

	.hyperParamsTable{
		width: 100%;
		letter-spacing: 0.25px;
		line-height: 32px;
	}

	.columnsSelector__box{
		left: 135px;
		top: 4px;

		.columnsList__box{
			right: auto;
    		top: 26px;
		}
	}

	.ReactVirtualized__Table__headerRow.hyperParamsTable__row {
		border-radius: 4px 4px 0 0;
	}

	.ReactVirtualized__Table__headerColumn.hyperParamsTable__header{
		background-color: #1E2038;
		height: 32px;
		color: #FFFFFF;
		font-size: 12px;
		line-height: 32px;
		text-transform: none;
		margin: 0;
		border-right: 1px solid rgba(83, 87, 118, 0.56);;
		padding-left: 8px;

		&:last-of-type{
			border-right: 0;
		}
	}

	.hyperParamsTable__row{
		background-color: #0B0E1C;
		font-size: 12px;

		&:nth-child(even) {
			background-color: #131727;
		}

		&:nth-child(2){
			.hyperParamTypeField .select-field-options-outer{
				top: 64px;
			}
		}

		&:nth-child(4){
			.hyperParamTypeField .select-field-options-outer{
				top: 36px;
			}
		}

		&:last-of-type{
			border-radius: 0 0 4px 4px;
		}
	}

	.ReactVirtualized__Table__rowColumn{
		border-right:  1px solid rgba(83, 87, 118, 0.56);;
		margin: 0 !important;
		padding-left: 8px;
		height: 100%;

		&:last-of-type{
			border-right: 0;
		}

		.toggleTuneField{
			// padding-top: 8px;
			height: 100%;
			align-items: center;

			&.placeholder{
				opacity: 0.30;
			}
		}

		&.hyperParamTypeField{
			padding-left: 0;
			text-align: center;

			.select-field-options-outer{
				position: fixed;
				top: 0;
				left: auto;
				width: 62px;
				top: 32px;
				margin-bottom: 0;
				margin-left: 2px;
				.select-field-option{
					text-align: left;
				}
			}
		}

		.dropdown__master.dropdown--active .select-field-value{
			border-color: transparent;
		}	
		.hyperParamLabel{
			white-space: normal; 
			line-height: 20px; 
			padding-top: 7px; 
			padding-bottom: 7px;

			.hyperParamLabel__textToolTipWrapper {
				// display: flex;
			}

			.info__icon{
				margin-top: 0;
			}
		}


		.hyperParamLabel__placeholder{
			font-size: 12px;
			letter-spacing: 0.25px;
			opacity: 0.32;
			// height: 32px;
    		// justify-content: space-between;
			// padding-right: 8px;

			span:first-of-type {
				display: inline-flex;
				align-items: center;
			}

			// span {
			// 	line-height: 35px;

			// }

			svg {
				height: 16px;
				width: 16px;
				margin-top: -2px;
			}
		}

		.hyperParamBox{
			height: 100%;
			align-items: center;
			display: flex;
			// justify-content: center;

			&.placeholder{
				opacity: 0.3;
			}
		}

		.hyperParamTypeDropdown{
			width: 67px;
			margin-bottom: 0;
			margin-right: 0;

			.inputfield__label{
				display: none;
			}

			.select-field-value{
				background-color: transparent;
			}
		}

		.hyperParamField__dropdown{
			margin-bottom: 0;
			margin-right: 0;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;

			.inputfield__label {
				display: none;
			}

			.select-field-value{
				background-color: transparent;
				// height: 24px;
			}

			.select-field-box.select-field-box--active .select-field-value {
				border-color: transparent;
			}

			.select-field-options-outer{
				position: fixed;
				width: 100px;
				right: auto;
				left: auto;

			}
		}

		.hyperParamField__checkbox{
			margin-bottom: 0;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.hyperParamField__radio{
			margin-bottom: 0;
			padding-top: 2px;
		}

		
	}

	.inputfield__container.hyperParamField__list{
		height: 100%;
		margin-bottom: 0;
		display: flex;
		align-items: center;

		.inputfield__label{
			margin-bottom: 0;
			display: none;
		}

		.inputfield {
			background-color: #2A2C42;
			height: 24px;
		}

		.inputfield__errormessage{
			display: none;
		}
	}
}


.variableMapping__box{
	padding-top: 16px;
	background-color: #494C7A;

	& > span{
		font-size: 12px;
		font-weight: 600;
		padding-left: 16px;
	}

	.select-field{
		padding-top: 12px;
		padding-bottom: 23px;
	}
}

.highlightLabel .inputfield__label{
	font-weight: 600;
}

.repeatV2{
	
	.portTicker__box{
		display: flex;
		padding-top: 10px;
		justify-content: flex-end;
		align-items: center;

		.portTicker__Btnbox {
			margin-left: 15px;
		}

		.portType{
			margin-bottom: 0;
		}
	}
}

.__inputField {
	position: relative;

	.btn-fileBrowser {
		position: absolute;
		top: 39px;
		right: 8px;
		margin-left: 8px;
		width: 25px;
		min-width: auto;
		padding-left: 1px;
		box-shadow: none;
	}

	&.showUpdateComponentNameButton .inputfield{
		padding-right: 85px;
	}

	.btn-update-component-name{
		position: absolute;
		top: 41.5px;
		right: 48px;
	}
}

.asyncSelectField__box{
	&.showSelectWorkflow{
		display: flex;
		align-items: center;
		border-bottom: 1px solid #393e53;
		justify-content: flex-start;
		padding-right: 36px;

		.select-field {
			// width: 90%;
			flex: 1;
			border-bottom: none;
			padding-right: 14px;
	
		}
	}
	
	.btn-selectWorkflow{
		margin-top: 18px;
		border-radius: 2px;
		width: 24px;
		height: 24px;		

		img {
			margin-bottom: 3px;
		}
	}

	span.btn-grey.btn-selectWorkflow {
		// tooltip in disabled state is wrapping span over button
		cursor: default !important;
		box-shadow: none;
		opacity: 0.72;
		border-radius: 2px;
		background-color: rgba(212, 214, 224, 0.32);
		// background-color: #543a00;

		button {
			cursor: default;
			width: 24px;
			padding-top: 2px;
			// background-color: rgba(212, 214, 224, 0.32);
		}
	}
}

.loseUnsavedChangesPortalBox{
	width: 100vw;
	height: 100vh;
	z-index: 8;

	.loseUnsavedFormChangesBox{
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-45%, -50%);
		background: #2A2C42;
		box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.48), inset 0px 0px 4px rgba(255, 255, 255, 0.1);
		border-radius: 8px;
		padding: 24px;
		color: white;
	}
}


.componentFieldSelection{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding-top: 16px;


	.select-field, .radiofield__container{

		padding-top: 0;
		border-bottom: 0;
	}

	.btn__addComponentRow{
		display: flex;
		align-items: center;
		margin-right: 36px;
		margin-bottom: 16px;
		// justify-content: space-between;

		img {
			width: 18px;
			height: 18px;
			margin-right: 4px;
		}
	}

	.componentPropertySelection__row{
		width: 100%;
		padding-top: 16px;
		position: relative;
	}

	.btn__removeComponentRow{
		position: absolute;
		top: 2px;
		right: 8px;

		svg {
			width: 24px;
			height: 24px;
		}
	}
}

.no-padding-left{
	padding-left:0 !important;
}
.no-padding-bottom{
	padding-bottom:0 !important;
}


.configurePipeline__LHS, .property_window {
	.tableComponent{
		width:349px;
		padding:1rem 0px;
	}
	.tableComponent > div:first-child{
		justify-content: space-between!important;
		
	}
	.tableHolder tr {
		td{
			padding:0;
			height: 35px;
		}
		input {
			display: block;
			background-color: #14182a;
			border-radius: 2px;
			height: 28px;
			border: transparent;
			padding: 4px 8px;
			color: #ffffff;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.25px;
			line-height: 18px;
			border: 1px solid transparent;
			border-radius: 2px;
		}
		td:nth-child(1), td:nth-child(1) input {
			width:130px;
		}
		td:nth-child(2), td:nth-child(2) input {
			width: 65px; 
			display: inline-block; 
			padding: 0px 4px 0px 5px;
		}
		td:nth-child(3), td:nth-child(3) input {
			width: 130px;
			padding-right: 4px;
			padding-left: 10px;
		}
	}
}

 .property_window {
	td:nth-child(2) > div:first-child{
		padding: 0px!important;
	}
 }

.workflowConfig__header{
	color: white;
	display: flex;
	align-items: center;
	font-size: 12px;
	// padding: 8px;

	svg{
		width: 16px;
		height: 16px;
		margin-left: 2.5px;
	}
}

.workflowConfig__container{
	margin: 12px 12px 0;
	background-color: #3E4161;

	.ant-collapse-header{
		padding: 7px 8px !important;
		// padding-bottom: 7px;
		// padding-left: 8px;
	}

	.select-field{
		padding: 0 8px; 
		margin-bottom: 12px;

		&:last-child{
			margin-bottom: 0;
		}
	}

	.ant-collapse-content{
		padding-bottom: 8px;
	}
}


.workflowConfigList__insideComponent{
	border-bottom: 1px solid #393e53;

	.validationMessages{
		margin: 16px 16px 0;
		p {
			color: #FF7766;
			font-size: 12px;
			font-weight: 500;
			margin-bottom: 0;
			line-height: 18px;

			&:nth-child(2){
				margin-bottom: 8px;
			}
		}
	}
}

.workflowField{
	&.showWorkflowConfig .workflowSelection__dropdown{
		border-bottom: none;
		padding-bottom: 0;
	}
}

.workflowNArgsModal{
	// width: 864px;
	color: white;

	.modal__header{
		padding: 16px;
		
	}


	.argsIndex__Column{
		height: 42px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 8px;
		padding-top: 10px;
		padding-right: 12px;

		span{
			opacity: 0.54;
		}

		button{
			margin-top: 4px;

			&:disabled{
				opacity: 0.36;
				cursor: default;
			}
		}
	}

	.nArgsContainer{
		background: #14182A;
		padding-bottom: 8px;
		min-height: 360px;
		min-width: 492px;
		// max-width: 832px;
		position: relative;

		.innerTableContent{
			// display: flex;
			// position: relative;
			
			.commentTable{
				position: absolute;
				right: 0;
				overflow: hidden;
				background: #14182A;				
			}

			.argumentHeaderBg{
				position: absolute;
				background: #212233;
				width: 100%;
			}

		}

		.argumentsTable{
			/* Hide scrollbar for IE, Edge and Firefox */					  
			-ms-overflow-style: none;  /* IE and Edge */
			scrollbar-width: none;  /* Firefox */
			// width: 0;

			&::-webkit-scrollbar{
				// display: none;
				// height: 0;X
				width: 0 !important;
			}
		}
	}

	

	.argumentHeaderColumn{
		background: #212233;
		text-transform: none;
		padding-left: 8px;

		.argumentKey{
			color:  #C7C7CD;
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;
			margin-bottom: 2px;
		}

		.argumentType{
			font-weight: 500;
			font-size: 10px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.48)
		}
	}

	

	.btn__addComponentRow{
		height: 36px;
		border: 2px dashed rgba(255, 255, 255, 0.32);
		border-radius: 4px;
		width: 100%;
		margin: 8px;
		width: calc(100% - 16px);
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 8px;

		svg {
			width: 16px;
			height: 16px;
		}
		// margin: 8px;
	}

	.argument__inputFields{
		margin: 8px 8px 0;
		height: 36px;
		position: relative;

		.inputfield__label{
			display: none;
		}

		.inputfield, .select-field-value, .globalValue {
			background-color: rgba(0, 0, 0, 0.32);
			height: 36px;
			margin-bottom: 0;
		}

		.inputfield__errormessage{
			display: none;
		}

		.globalIconBtn{
			position: absolute;
			right: 8px;
			top: 4px;
			padding: 4px;
			width: 28px;
			height: 28px;
			border-radius: 2px;
			z-index: 1;

			&:hover{
				background: rgba(255, 255, 255, 0.078);
			}
		}

		&.isBoolean{
			.globalIconBtn{
				right: 18px;
			}
		}

		&.globalValueMode {
			.inputfield {
				padding-right: 42px;
			}

			&.globallySelectedValue .globalIconBtn{
				right: 8px;
			}
		}

		

		

		.globalValue{
			width: 90px;
			border-radius: 2px;
			padding: 4px 42px 4px 10px;
			line-height: 28px;
			color: #ffffff;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.25px;
			overflow: hidden;
            overflow-wrap: break-word;
		}
	}

	.modalBtns__box{
		margin-top: 16px;
		flex-direction: row;
		justify-content: end;
	}


	.ReactVirtualized__Table__headerColumn:first-of-type {
		margin-left: 0;	
		margin-right: 0;	
	}
	.commentField{
		margin-top: 0;
		margin-left: 0;	
		margin-right: 0;	
	}
}

.radiofield__container.workflowConfig__configurationType{
	border-bottom: 0px;
	padding-bottom: 0;

	.inputfield__label{
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #C7C7CD;
	}
}

#edit-nArgs-Config{
	width: calc(100% - 16px);
	margin: 16px 8px;
	height: 26px;
}

.soapField{
	padding-bottom: 24px;
	border-bottom: 1px solid #393e53;

	.select-field {
		border-bottom: 0;
	}

	.inputType__dropdown{
		padding-top: 0;
	}

	button{
		margin: 0 16px;
		width: calc(100% - 32px);
		// margin-top: 20px;
	}
}

.workspaceField{
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid #393e53;

	.select-field {
		border-bottom: 0;
	}

	.inputType__dropdown{
		padding-top: 0;
	}

	button{
		margin:16px;
		width: calc(100% - 102px);
	}
}
.SoapBodyEditor__modal{
	.modalBtns__box{
		margin-top: 16px;
	}	
}

.componentMetaData{
	padding: 16px;
	border-bottom: 1px solid #393e53;
}

.refreshComponentProperties__message{
	margin-top: 16px;

	.messageContent{
		color: #FF7766;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 8px;
	}

	.btn-refresh-component, .btn-refresh-component button{
		display: flex;
		align-items: center;
		column-gap: 4px;
		padding: 0 12px;
		font-size: 12px;
	
	
		svg {
			height: 16px;
			width: 16px;
		}
	
		rect {
			fill: #0F121F;
		}

		.inPageSpinner__XSmall{
			transform: scale(0.75);
		}
	}

	// this is needed for disabled button styles
	.btn-refresh-component button{
		height: 24px;
		width: 100%;
		padding: 0;
		font-weight: 600;
		letter-spacing: 0.25px;
	}
}

#refreshComponents_btn{
	display: flex;
}

.btnWt {
	min-width: 72px;
}

.repeatV3{
	position: relative;
	.repeatV3__fieldsGroup{
		padding-top: 6px;
		padding-bottom: 12px;
		border-bottom: 1px solid #393e53;
	}

	.fieldsGroup__name{
		padding-left: 16px;
	}

	.select-field, .inputfield__container, .useCaptureSchema{
		border-bottom: none;
		padding-bottom: 6px;
	}

	&.showAddButton{
		padding-top: 18px;

		.repeatV3__fieldsGroup{
			position: relative;		
			padding-top: 20px;
		}
	}

	.add-button{
		top: 12px;
		right: 20px;
		position: absolute;
		width: 24px;
		height: 24px;
		z-index: 1;
	}

	.remove-button{
		position: absolute;
		right: 18px;
		width: 24px;
		height: 24px;
		z-index: 1;

	}
}

.modalNifi {
    width: 45%;
}

.ant-table-tbody > tr.even-row > td{
	background-color: #0b0e1c !important;
	}
		
	.ant-table-tbody > tr.odd-row {
	background-color: #131727 !important;
	}
	
	.ant-table-body {
		width: 99% !important;
	}

	.file-content-modal {
		padding: 20px;
		display: flex;
       flex-direction: column;
       height: 100%;
	  }
	  
	  .file-content-modal-header {
		font-size: 20px;
		font-weight: bold;
		text-align: left;
	  }

	  .file-content-modal .modal-buttons {
		text-align: right;
		margin-top: 20px;
	  }
	  
	  .file-content-modal .ant-btn {
		background-color: #fcad01;
	    border: 2px solid #fcad01;
        color: black;
	  }
	  
	  .file-content-modal .ant-btn:hover {
		border-color: #fec954;
		color: black;
	  }

	  .add-row-btn-container {
		text-align: right;
		margin-bottom: 10px;
	  }

	  .ant-table {
		border-radius: 8px;
		overflow: hidden;
	  }

	  .table-container {
		flex-grow: 1;
		overflow-y: auto; 
		margin-bottom: 20px; 
	  }

	  .loader-container {
		display: flex;
		justify-content: center;  
		align-items: center;     
		height: 100vh;          
		width: 100vw;     
		position: fixed; 
		top: 0;
		left: 0;
		z-index: 9999; 
	}

	.action-btns {
		display: flex;
		justify-content: flex-start; /* Align buttons to the left */
		align-items: center;
		gap: 10px; /* 10px gap between buttons */
		margin-bottom: 20px;
	}
	
	.add-btn,
	.save-btn,
	.delete-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
	}
	
	.add-btn img {
		margin-right: 8px;
	}
	
	.save-btn,
	.delete-btn {
		background-color: #1890ff;
		color: white;
		border-radius: 5px;
	}

	.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
		background-color: transparent;
	}

	.ant-table-hide-scrollbar {
		scrollbar-color: none;
	}

	.ant-table-thead > tr > th:hover {
		background-color: #191f35 !important;
	}

	.ant-table-placeholder {
		background-color: #2A2C42 !important;
		border: none;
	}

	.globalSearch {
		margin-left: auto;
	}

	.highlighted-header {
		background-color: darkblue !important;
	}

	.modal-backdrop-disabled {
		pointer-events: none; 
		opacity: 0.5;
	}
	
	.overlayFile {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none
	}

	.emptyCheckText {
		color: white;
		font-size: 18px;
	}