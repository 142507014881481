.dataexplorer__container {
    margin-left: 0;
    height: calc(100vh - 180px);
    color: #fff;
    display: flex;
    overflow: auto;
    .react-grid-layout {
        position: relative;
        transition: none;
    }
    .grid-graph {
        border-radius: 4px;
        position: relative;
        background-color: #212233;
        margin: 1rem 0;
        padding-bottom: 0.5rem;;
    }
    button{
        font-size: 12px;
        text-decoration: underline;
        height: 21px;
        letter-spacing: 0.25px;
    }
    .workflowCanvas__header {
        width: 100%;
        left: 0;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 16px;
        height: 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        span {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 18px;
        }

        .separator {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            border-top: 1px solid #393e53;
            height: 9px;
            margin-top: 8px;
        }

        img {
            transition: transform 0.25s;
        }
    }

    .innerPlotBox{
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
    }

    .echarts-for-react {
        height: 88%!important;
        margin-bottom: 0.5rem;
    }
    .graphCardIcon{
        margin-right: 12px;
        display: flex;
        align-items: center;

        svg {
            width: 16px;
            height: 16px;
        }
      

        &:hover{
            .hover-graph-icon {
                fill : #D4D6E0;
            }
        }

        &:last-of-type{
            margin-right: 0;
        }

        &.disabled {
            opacity: 0.56;
        }
    }
}

.dataexplorer__model{
    position: absolute;
    top: 10px;
    left: 30%;
    height: 50rem;
    width: 60rem;
    z-index:1001;
    display: none;
    background: rgba(33, 34, 51, 1);
    border-radius: 0.25rem;
    &.show {
        display: block;
    }
    .workflowCanvas__header {
        width: 100%;
        left: 0;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 16px;
        height: 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        span {
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 18px;
        }

        .separator {
            flex: 1;
            margin-left: 16px;
            margin-right: 16px;
            border-top: 1px solid #393e53;
            height: 9px;
            margin-top: 8px;
        }

        img {
            transition: transform 0.25s;
        }
    }
    .innerPlotBox {
        padding: 1rem;
        height: 50rem;
        width:60rem;
        .echarts-for-react  {
            height: 44rem !important;
        }
    }
}
.dataexplorer__backdrop {
    z-index:1000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: rgba(0,0,0,0.5);
    &.show {
        display: block;
    }
}

.history-listing{
    .ant-list-split .ant-list-item {
        border:1px solid #5B608F;
    }

    .ant-list-lg .ant-list-item {
        padding: 0.5rem;
    }

    .ant-avatar > img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        font-size: 0.75rem;
    }

    .ant-avatar-string{
        font-size: 1rem;
    }
}

.editor{
    font-size: 1.5rem;
    .monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
        background: rgba(0, 0, 0, 0);
    };
}
#dataexplorer {
    .iconButton {
        margin-top:5px;
    }
    .iconButton > svg {
        width: 1.5rem;
        height: 1.5rem;
    }
    .ant-tabs {
        background-color: rgb(62, 65, 97);
        width: 100%;
    }
    .ant-tabs-bar {
        padding: 1rem 2rem 0;
    }
    .ant-tabs-tab {
        font-size: 1.2rem;
    }
}
.thinking-color{
    color: #fcad01;
    font-size:1rem;
}

.full {
    width: 80vw;
    .layout, .dataexplorer__container {
        height: 90vh!important;
    }
    > div:not(.dataexplorer__container){
        display: none;
    }
    .grid-graph {
        width: 78vw!important;
    }

    .layout > div {
        display: none;
    }

    .show{
        display: block !important;
        height: 90vh !important;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.centerh {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-col-center{
    display: flex;
    justify-content:center ;
    align-items: center;
}

.download-failed-btn{
    display: flex;
    justify-content:center ;
    align-items: center;
    font-size: 1rem;
    color:#FF7766;
    font-weight: 500;
    cursor:auto
}

.retry-btn{
    display: flex;
    justify-content:center ;
    align-items: center;
    font-size: 1rem;
    color:#C7C7CD;
    cursor:pointer;
    text-decoration: underline;
    font-weight: 700;
}

.radio-field{
    margin-bottom: -30px;
}

.metadata {
    .select-field-box .select-field-value {
        background-color: rgba(0, 0, 0, 0.32);
    }
	.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
		background: rgba(0, 0, 0, 0.32);
		border-radius: 0.25rem;;
	}
}