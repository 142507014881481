
.pageHeader{
    display: flex;
    background-color: #0B0E1C;
    // width: 100%;
    height: 64px;
    position: fixed;
    top: 0;
    right: 0;
    left: 72px;
    z-index: 2;
    padding-right: 18px;
    padding-left: 24px;
    justify-content: space-between;
    align-items: center;

    & * {
      background-color: transparent;
    }

    .user__dropdown{
        top: 15px;
        position: static;
        align-items: center;
    }

    .pageTitle{
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
        opacity: 0.9;
    }

    .user__initials {
        width: 24px;
        height: 24px;
        font-size: 12px;
        line-height: 18px;
    }
}

.other{
  margin-top: 55px;
  background-color: #2A2C42;
}

.title{
  text-shadow: -1px -1px 8px rgba(252, 173, 1, 0.48);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 60px;
  color: #FBAD01;
}

// $base-color: #14182a;
// $shine-color: #2d3554;

@mixin loading-skeleton($base-color, $shine-color) {
	background-image: linear-gradient(
		90deg,
		$base-color 0%,
		$shine-color 50%,
		$base-color 100%
	);
	background-size: 400% 400%;
    animation: shine-lines 1.2s infinite linear;

    @keyframes shine-lines {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -135% 0%;
        }
    }
}

    
@mixin fixed-sidebar-skeleton {
    border-radius: 2px;
    @include loading-skeleton(rgba(42, 44, 66, 0.6), #2a2c42);
}

.fixed-sidebar{
    position: fixed;
    height: 100vh;
    width: var(--sidebar-width);
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    z-index: 1;

    &.fixed-sidebar--left{
        left: 0;
        padding: 28px 0 24px;
        background-color: #0B0E1C;
    }

    &.fixed-sidebar--right{
        right: 0;
        padding-top: 16px;
        // padding: 32px 0 24px;
    }

    &.showSkeleton{
        & > div {
            @include fixed-sidebar-skeleton();

            * {
                display: none;
            }
        }

        #mini_title{
            width: 50px;
            height: 30px;
        }

        .hamburger__icon{
            border-radius: 100%;
            height: 32px;
            width: 32px;
            margin-bottom: 50px;
        }

        .tabs__box{
            &::before{
                height: 8px;
                border-radius: 2px;
                width: 50px;
                left: 0px;
                @include fixed-sidebar-skeleton();
            }

            .tabscategory__name{
                display: none;
            }

            .tab__item__outer{
                width: 50px;
                height: 48px;
                border-radius: 2px;
                margin-bottom: 10px;
                @include fixed-sidebar-skeleton();
                
                & > * {
                    display: none;
                }


            }
        }
    }
    
  }

#mini_title{
    img {
        height: 30px;
        width: 42px;
    }
    margin-bottom: 15px;
}



.tabs__box{
    &::before{
        content: '';
        width: 58px;
        height: 1px;
        background-color: #393E53;
        position: absolute;
        top: 0;
        left: 8px;
    
    }
    position: relative;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;

}

.tabscategory__name{
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 18px;
    opacity: 0.40;
    margin-bottom: 15px;
    display: block;
}

.tab__item{
    height: 48px;
    width: var(--sidebar-width);
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid transparent;
    margin-bottom: 8px;
    background-color: transparent;
    transition: background-color 0.25s;

    .sidebar__icon__hover{
        transition: fill 0.25s;
    }

    &:hover{
        background-color: #2A2C42;

        .sidebar__icon__hover{
            fill: #D4D6E0;
        }
    }


    &.tab__item--active{
        border-left: 2px solid #C78900;;
        background-color: #2A2C42;

        &:hover .sidebar__icon__hover{
            fill: #FCAD01;
        }
        
    }

    &:last-child{
        margin-bottom: 0;
    }
}

.productOfDeepiQ__img{
    // margin-top: 50px;
    height: 28px;
    width: 60px;
    bottom: 20px;
    position: absolute;
}

.hamburger__icon{
    height: 40px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;

    &.disabled{
        opacity: 0.32;
        cursor: default;

        #hamburger__hover:hover{ 
            fill: #9495A0;
        }
    }
}

#hamburger__hover{
    fill: #9495A0;
    transition: fill 0.25s;

    &:hover{    
        fill: #D4D6E0;
    }
}



.fixed-sidebar--right {
    height: 50px !important;
    z-index: 1;

    // .minimize-arrow {
    //     position: absolute;
    //     right: 358px;
    //     transition: right 0.5s;
    //     top: 17px;

    //     img {
    //         height: 22px;
    //         width: 22px;
    //     }

    //     &.minimize-arrow--minimized{
    //         right: 45px;

    //     }
    // }

    
    &.fixed-sidebar--minimized {
        height: 100vh;
    }
}

.user__dropdown{
    display: flex;
    position: absolute;
    right: 21px;
    transition: right 0.5s;
    justify-content: flex-end;

    & > *{
        display: inline-block;
    }


    .dropdown__menu .dropdown__menu--right{
        z-index: 3;
    }

    .user__name{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 32px;
        opacity: 0.7199999690055847;
        margin-right: 9.5px;
        text-transform: capitalize;
    }
    
    .dropdown__master{
        display: inline-block;
        cursor: pointer;
    
        .dropdown__menu{
            top: 34px;
            width: 150px;
            background-color: #2A2C42;
            border-radius: 1px;
            box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48), 2px 4px 8px 0 rgba(0, 0, 0, 0.32);
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.25px;
            line-height: 14px;
        }
    
        .dropdown__menu__item{
            padding-right: 12px;
            text-align: right;
            &:hover{
                background-color: #0B0E1C;
                color: #FFFFFF;
            }
        }
    }
}

.user__initials{
    background-color: #E47327;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    text-align: center;
    display: inline-block;
    padding-top: 4px;
    color: #2A2C42;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 25px;
}

@media screen and (max-height: 900px){
    .hamburger__icon{
        margin-bottom: 12px;
    }

    .tabscategory__name{
        margin-bottom: 8px;
    }

    // .tabs__separator{
    //     margin-top: 12px;
    //     margin-bottom: 8px;
    // }

    .productOfDeepiQ__img{
        margin-top: 46px;
    }

    .fixed-sidebar.fixed-sidebar--left{
        padding-top: 18px;
    }

    .tab__item{
        margin-bottom: 4px;
    }
}