@import url('./assets/fonts.css');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	user-select: none;
	font-family: 'DM Sans', Arial, Helvetica, sans-serif;
	--header-margin: 64px;
	--workflowtabs-height: 36px;
	--outputconsole--height: 135px;
	--sidebar-width: 72px;
    --dataSourceBrowser__treeview__width: 289px;
}
h1, h2, h3, h4, h5, h6, p, span {
	font-family: 'DM Sans', Arial, Helvetica, sans-serif;
}

input, textarea {
	user-select: text;
	-moz-user-select: text;
	-webkit-user-select: text;
}

section {
	margin-left: var(--sidebar-width);
	background-color: #2a2c42;
	// height: calc(100vh - var(--header-margin));
}

.fl-r {
	float: right;
}
.fl-l {
	float: left;
}
.flex{
	display: flex;
}
.justify-between {
	justify-content: space-between;
}
.justify-center {
	justify-content: center;
}
.flex-column {
	flex-direction: column;
}
.align-center {
	align-items: center;
}
.flex-end {
	justify-content: flex-end;
}
.p-0{
	padding: 0 !important;
}
.w-100 {
	width:100%;
}
.h-100 {
	height:100%;
}
.h-auto{
	height: auto !important;
}
::-webkit-scrollbar {
	height: 8px !important;
	width: 8px !important;
}

table::-webkit-scrollbar {
	width: 8px !important;
}
/* Track */
::-webkit-scrollbar-track {
	// background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d8d8d8 !important;
	border-radius: 3px !important;
	background-clip: padding-box !important;
	border-right: 2px solid #2a2c42 !important;
}

::-webkit-scrollbar-thumb:horizontal {
	background: #d8d8d8 !important;
	border-radius: 3px !important;
	background-clip: padding-box !important;
	border-bottom: 2px solid #2a2c42 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555 !important;
}

ul:focus,
li:focus,
div:focus {
	outline: none;
}

button {
	cursor: pointer;
	border: none;
	background-color: transparent;

	&:focus {
		outline: none;
	}
}

.btn {
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.25px;
	border-radius: 2px;
	text-align: center;
	padding: 0 8px;

	&:disabled {
		cursor: default;
	}
}

.btn-sm {
	@extend .btn;
	height: 24px;
	min-width: 72px;
}

.btn-md {
	@extend .btn;
	height: 32px;
	min-width: 96px;
}

.btn-lg {
	@extend .btn;
	height: 40px;
	min-width: 200px;
}

.btn-block {
	@extend .btn;
	width: 100%;
}


.btn-yellow {
	background-color: #fcad01;
	box-shadow: 0 1px 4px 0 rgba(252, 173, 1, 0.72);
	color: #262231;
	transition: background-color 0.25s;

	&:hover {
		background-color: #fec954;
	}

	&::focus {
		background-color: #a87301;
		box-shadow: none;
	}

	&:disabled, &.btn-yellow-disabled {
		cursor: default;
		color: #262231;
		// opacity: 0.239999994635582;
		background-color: #543a00;
		box-shadow: none;
	}
}

.btn-yellow-transparent {
	background-color: transparent;
	border: 2px solid #fcad01;
	color: #fcad01;
	transition: border 0.25s;

	&:hover {
		border-color: #fec954;
		color: #fec954;
	}

	&:focus {
		border-color: #a87301;
		color: #a87301;
	}

	&::disabled, &.btn-yellow-transparent-disabled {
		color: #543a00;
		border-color: #543a00;
	}
}

.btn-grey {
	color: #262231;
	background-color: #d4d6e0;
	transition: background-color 0.25s;

	&:focus {
		background-color: #ffffff;
		border-color: #ffffff;
	}

	&:hover {
		background-color: #ffffff;
		box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.56);
	}

	&:disabled, &.btn-grey-disabled {
		color: #14182a;
		background-color: rgba(212, 214, 224, 0.32);

		&:hover {
			box-shadow: none;
		}
	}
}

.btn-grey-transparent {
	color: #FFFFFF;
	border: 2px solid #6D707E;;
	transition: border 0.25s;

	&:hover {
		border-color: #FFFFFF;
		color: #FFFFFF;
		box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.56);
	}

	&:focus {
		border-color: #FFFFFF;
		color: #C7C7CD;
	}

	&:disabled, &.btn-grey-transparent-disabled {
		color: rgba(255, 255, 255, 0.24);
		border-color: #2A2C42;
	}
}

.btn-red {
	background-color: #B01900;
	transition: background-color 0.25s;;

	&:focus {
		background-color: #c96351;
	}

	&:hover {
		background-color: #bd3d2a;
	}

	&:disabled, &.btn-red-disabled {
		background-color: #9495a0;
	}
}


p {
	font-size: 24px;
}

.hide {
	display: none !important;
}


.hideVisibility {
	visibility: hidden !important;
}

.checkbox__container {
	display: block;
	position: relative;
	width: 16px;
	cursor: pointer;
	font-size: 22px;
	user-select: none;
	height: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.checkbox__gold{
		/* Style the checkmark/indicator */
		input:checked ~ .checkmark {
			background-color: #FCAD01;
			border-color: #FCAD01;
			// background-color: #2A2C42;
		}

		input:checked ~ .checkmark:after {
			border-color: #2A2C42;
		}
	}
}

/* Hide the browser's default checkbox */
.checkbox__container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	background-color: transparent;
	border: 1px solid #c7c7cd;
	border-radius: 2px;
}

.checkmark__partialselected {
	.checkmark {
		background-color: #696a76;
		border: 1px solid #898c9a;
	}

	.checkmark:after {
		display: block;
		left: 2px;
		top: 6px;
		background-color: #c7c7cd;
		width: 10px;
		height: 1.5px;
	}
}

/* On mouse-over, add a grey background color */
.checkbox__container:hover input ~ .checkmark {
	border: 1px solid #ffffff;
	border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.checkbox__container input:checked ~ .checkmark {
    background-color: #14182A;
    border-color: #14182A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox__container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkbox__container input:checked ~ .checkmark:after {
	left: 4.25px;
	top: 0.1px;
	width: 5.5px;
	height: 9.5px;
	border: solid #fff;
	border-width: 0 1.5px 1.5px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.c-w {
	color: white;
}

.switchable__tab {
	padding-bottom: 4px;
	font-weight: 600;
	opacity: 0.72;
	letter-spacing: 0.5px;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	margin-right: 12px;
	font-size: 12px;

	&:hover {
		opacity: 1;
	}

	&:nth-child(1) {
		margin-left: 0;
	}
}

.switchable__tab.switchable__tab--active {
	color: #ffffff;
	border-bottom: 2px solid #ffffff;
	opacity: 1;
}

.switchable__tab.switchable__tab--hidden {
	display: none;
}

.LHS__title {
	color: #ffffff;
	font-size: 24px;
	font-weight: 700;
	opacity: 0.9000000357627869;
	text-align: left;
	padding-top: 27px;
	margin-bottom: 4px;
	padding-left: 20px;
}

.LHS__subtitle {
	color: rgba(255, 255, 255, 0.56);
	font-size: 12px;
	letter-spacing: 0.25px;
	margin-bottom: 4px;
	padding-left: 20px;
}

.search__input__container {
	position: relative;
	padding: 16px;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.2px;
	line-height: 18px;
}

.search_input {
	background-color: #14182a;
	border: 2px solid transparent;
	border-radius: 3px;
	width: 100%;
	height: 40px;
	padding: 0 30px;
	color: #ffffff;
	position: relative;
	background-image: url(./assets/icon_search.svg);
	background-repeat: no-repeat;
	background-size: 18px;
	background-position: 8px;

	&:focus {
		outline: none;
		border: 2px solid #fcad01;
	}
}

.search_reset {
	position: absolute;
	right: 20px;
	top: 21px;
	height: 20px;
	cursor: pointer;
}

.btn__new__action {
	img {
		height: 16px;
		width: 16px;
		margin-bottom: 4px;
		margin-right: 5px;
	}
	span {
		// position: relative;
		top: 0px;
	}
}

.component_action_wrapper {
	display: flex;
	justify-content: flex-end;
	
	.btn__new__action {
		img {
			height: 16px;
			width: 16px;
			margin: 0px;
		}
		span {
			position: relative;
			top: 1px;
		}
	}
}

.btn__import {
	margin: 0 5px 0 0;
	img {
		width: 81%;
		margin-bottom: 0px;
		border: 1px solid rgba(255, 255, 255, 0.2);
		padding: 2px;
		transform: rotate(180deg);
	}
}

.context_menu {
	position: fixed;
	z-index: 9999;
	display: none;
	width: 75px;
	background: #2a2c42;
	text-align: left;
	-webkit-box-shadow: 0px 4px 8px rgb(0 0 0 / 32%), 0px 8px 16px rgb(0 0 0 / 48%);
	-moz-box-shadow:0px 4px 8px rgb(0 0 0 / 32%), 0px 8px 16px rgb(0 0 0 / 48%);
	box-shadow: 0px 4px 8px rgb(0 0 0 / 32%), 0px 8px 16px rgb(0 0 0 / 48%);
	border-radius: 1px;
}

.context_menu_show {
	display: block !important;
}

.context_menu ul {
	padding: 5px 0px;
	font-size: 13px;
	list-style: none;
}

.context_menu ul li {
	color: white;
    font-size: 12px;
    font-family: "DM Sans";
    font-weight: 400;
	height: 24px;
    padding: 0;
    padding-left: 12px;
    line-height: 24px;
}

.context_menu ul li:hover {
	background-color: #14182a;
}

.tree-label {
	padding: 10px 0px 0 10px;
	color: #9495A0;
	font-size: 10px;
	letter-spacing: 0.25px;
	line-height: 16px;
}

@media screen and (max-height: 900px) {
	.LHS__title {
		padding-top: 16px;
	}
}

input:-moz-selection {
	/* Code for Firefox */
	background: #0461d4;
	color: white;
}

input::selection {
	background: #0461d4;
	color: white;
}

textarea::selection {
	background: #0461d4;
	color: white;
}

.compoennt_icon_cls svg {
	margin-right: 6px;
}

.compoennt_icon_cls {
	// display: flex;
	// align-items: center;
}

.icon__hover {
	cursor: pointer;

	&:hover {
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.48);
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.48);
	}
}

.icon__square__hover {
	padding: 3px;

	&:hover {
		border-radius: 25%;
		background-color: rgba(0, 0, 0, 0.48);
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.48);
	}
}

.ant-tooltip.customTooltip {
	white-space: pre-line !important;

	&.hideCaret{
		.ant-tooltip-arrow {
			display: none;
		}
	}

	.ant-tooltip-arrow::before{
		background-color: #14182a;
	}
	

	.ant-tooltip-inner {
		min-height: 20px;
		background-color: #14182a;
		border-radius: 2px;
		box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
			2px 4px 8px 0 rgba(0, 0, 0, 0.32);
		// height: 20px;
		padding: 2px 4px;
		color: #ffffff;
		font-family: 'DM Sans';
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.25px;
		line-height: 16px;
	}
}

.text-underline{
	text-decoration: underline;
}

.c-pointer{
	cursor: pointer;
}
.hljs{
	background-color: transparent !important;
}

.no-padding{
	padding:0 !important;
}

.no-margin{
	margin:0!important;
}

.no-border{
	border:none!important;
}
.text-center{
	text-align: center!important;
}
.p-5{
	padding:5px !important;
}

.p-1{
	padding:1rem !important;
}

.w-full {
	width: 100%;
}

.geo_search .input{
	width:100%;
}
.queryBuilder .ant-select-selection__placeholder{
    font-size: 11px;
    margin-top: -8px;
}
.m-l-5{
	margin-left: 5px;
}
.align-middle{
	display: flex;
	justify-content: center;
	align-items: center;
}
.gap-1 {
	gap: 0.5rem;
}

.width20 { 
	width:20% !important
}
.ListOfargtitle {
	color:white;
	.ant-divider-inner-text {
		color:white;
	}
}
#_dropdowns{
	width: 100vw;
	// height: 100vh;
	position: absolute;
	left: 0;
	top: 0;
    width: 100%;
	z-index: 1006;
}

.justify-content-center{
	justify-content: center !important;
}

.editor_holder .monaco-editor{
	width: 100%;
	min-width: 220px !important;
	max-width: 370px !important;
}

.editor_holder .sqltext .monaco-editor-background {
	padding-left:0.5rem;
	padding-top:0.3rem;
}

body,
#root {
  height: 100%;
  width: 100%;
  background-color: #0b0e1c;
}
.ant-modal-mask {
    z-index: 4;
}

.ant-modal-wrap { 
	z-index: 5;
 }

.d-none{
	display:none!important;
}

.codeEditor{
	width: calc(100vw - 235px - 72px - 400px - 10px);
	height: calc(100vh - 36px);
	border: none;
	background-color: rgb(4, 6, 20);
	position: absolute;
	&.iframeMinized{
		width: calc(100vw - 235px - 72px - 72px);	
	}
	&.iframeMinized_lhs{
		width: calc(100vw - 72px - 72px);	
	}

	.monaco-scrollable-element {
        & > .scrollbar  > .slider {
            background-color: #D8D8D8 !important;
            border-radius: 3px;
        }
    
        &  > .scrollbar.vertical  > .slider {
            left: auto !important;
            right: 4px;
        }


        .editor-scrollable{
            padding-left: 15px;
        }
    }

    .lines-content.monaco-editor-background{
        margin-left: 20px;
        margin-top: 7px;

    }

    .margin{
        background-color: #1E2038 !important;
        // width: 32px !important;
    }   

    .margin-view-overlays{
        margin-top: 7px;

        .line-numbers{
            left: auto !important;
            right: 10px !important;
            color: rgba(255, 255, 255, 0.72) !important;
        }
    }
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row {
    // position: relative !important;
    // top: 0 !important;
	color: white;
}

#iframe_holder{
	width: 100%;
	// height: 100%;
	position: absolute;
}

.disableMlFlow__box{
	width: calc(100vw - var(--sidebar-width));
	margin-left: var(--sidebar-width);
	display: flex;
    align-items: center;
    justify-content: center;
    background: #030613;
	height: 100%;
	overflow: none;

	h2 {
		color: white;
		text-align: center;
	}
}

.ant-table{
    
    .ant-table-header{
        background: #2a2c42;
    }

    .ant-table-thead, .ant-table-tbody {
        
        tr {
            th, td{
                color: #d4d6e0;
                border-color: rgba(11, 14, 20, 0.1);
            }

            td{
                background: #2a2c42;
            }

            th{
                background: rgba(20, 24, 42, 0.9);
            }

            &:hover{
                
                td{
                    background: #14182A!important;
                }
            }
        }
    }
    
    .ant-typography{
        color: #d4d6e0;
    }

    .ant-checkbox-inner{
        background: #2a2c42;
        border: 1px solid #c7c7cd;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background: #fcad01;
        border: 1px solid #fcad01;

        &::after{
            border-color: #262231;
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: #fcad01;
    }
}

.ant-pagination.ant-table-pagination{
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-next{
        border: 1px solid #2a2c42;
        color: #d4d6e0;
        a{
            color: #d4d6e0;
            background-color: #2a2c42;
        }

        .ant-pagination-item-link{
            border: 0px solid #2a2c42;
            .ant-pagination-item-ellipsis{
                color: #d4d6e0;
                
            }
        }
    }
}

.ant-input,
.ant-input-number-input{
    background:#14182a;
    border-color: #14182a;
    color: white;    
}

.ant-input:-internal-autofill-selected{
    background:#14182a!important;
    border-color: #14182a!important;
    color: white!important;
}

.ant-input[disabled]{
    background:#696a76;
    border-color: #898c9a;
    color: #14182a;
}

.ant-card{
    background: #2A2C42;
    border-color: #2A2C42;
    min-height: 400px;

    .ant-card-head{
        border-color: #16192b;

        .ant-card-head-title{
            color: white;
        }
    }

    h4.ant-typography{
        color: white;
    }
}

.ant-form-item-label{

    label{
        color: white;
    }
    
}

.ant-select-selection {
    background-color: #14182a;
    color: white;
    .ant-select-arrow{
        color: #c7c7cd;
    }
}

.ant-select-dropdown{
    background-color: #6d707e;
    
    .ant-select-dropdown-menu-item{
        color: #fff;
        background-color: #6d707e;
    }

    .ant-select-dropdown-menu-item-active{
        background-color: #0b0e1c;
    }

    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #0b0e1c;
    }
}

.ant-input-affix-wrapper {
    .ant-input-suffix,
    .ant-input-password-icon{
        color: #c7c7cd;
    }
}

.ant-divider{
    background-color: #0b0e1c;
}

.ant-modal-content{
    background: #2A2C42;
    border-color: #2A2C42;

    .ant-modal-close{
        color: white;
    }

    .ant-modal-header{
        background: #2A2C42;
        border-color: #2A2C42;

        .ant-modal-title{
            color: white;
        }
    }

    .ant-modal-footer {
        border-color: #0b0e1c;
    }
}

.transform-deleting-text{
    color: white;
}

.ant-steps-item-finish,
.ant-steps-item-wait {
    .ant-steps-item-container{
        .ant-steps-item-content {
            .ant-steps-item-title{
                color: white;
            }
        }

    }
}

.ant-steps-item-title{
    color:white !important;
}

.ant-select-selection--multiple{
    .ant-select-selection__choice{
        border-radius: 10px;
        background: #d4d6e0;
    }
}

.ant-radio-wrapper{
    color: white;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-inner{
    border-color: #fcad01;
    background-color: transparent;
}

.ant-radio-inner::after{
    background-color: white;
}

.killScheduleModal__container{
    width: 400px;

    .modal__body{
        padding: 12px 24px 16px;
    }

    .modalBtns__box{
        padding-right: 0;
    }

    .inputfield__container, .select-field{
        margin-bottom: 20px;
    }
}

.ant-select-dropdown {
	border-radius: 2px;

	.ant-select-dropdown-menu{
		padding-top: 7px;
		padding-bottom: 7px;
		background-color: #14182A;
	}

	.ant-select-dropdown-menu-item {
		background-color: #14182A;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.25px;
		color: #FFFFFF;
		font-family: 'DM Sans';
		// height: 18px;
		// padding: 0 0 0 8px;
	}

}

.btn-bordered {
	font-size: 12px;
    font-weight: bold;
    color: #FFF;
    background-color: transparent;
    border: 1px solid #c7c7cd;
    border-radius: 2px;
    padding: 4px 12px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.btn-yellow-transparent {
	background-color: #fcad01;
	border: 2px solid #fcad01;
	color: #030613;
  }
  
  .btn-yellow-transparent:hover {
	color: #030613;
  }
  
  .btn-yellow-transparent:focus {
	background-color: #fcad01;
	border: 2px solid #fcad01;
	color: #030613;
  }

.ant-collapse, .ant-collapse-content{
	background-color: transparent;
	border: none;	

	& > .ant-collapse-item{
		border: none;

		&.ant-collapse-no-arrow > .ant-collapse-header{
			padding: 0;
		}
	}

	& > .ant-collapse-content-box {
		padding: 0;
		color: white;
	}

	
}

.white{
	color:white;
}

.mb-0 {
	margin-bottom:0 !important;
}
.text-xl{
	font-size: 1.5rem;
}

.mb-1{
	margin-bottom: 1rem;
}
.mb-05{
	margin-bottom: 0.5rem !important;
}

#queryBuilder .ui pre {
	height: 460px;
} 

.hcm-tooltip-base {
	background-color: #423030db;
}

#dataexplorer {
	.monaco-editor, .monaco-editor-background, .monaco-editor .inputarea.ime-input {
		background: rgba(33, 34, 51, 1);
		border-radius: 0.25rem;;
	}
	.codeEditor .lines-content.monaco-editor-background {
		margin-left: 0.25rem;
		margin-top: 0.4375rem;
	}
}

.m-1, .mt-1{
    margin-top: 1rem;
}

.float-right {
	float: right;
}

.clear{
	clear: both;
}

iframe:not(.iframe) {
	display: none;
}

.modal__subtitle {
    color: #C7C7CD;
}

.font-normal {
	font-weight: normal;
}
.overflow {
	overflow: auto;
}
.ml-1{
    margin-left:1rem;
}