
.clusterBoard{
  line-height: 100%;
  background: #3E4161;
  min-height:100vh;

  .clusterBoardContainer {
    position: fixed;
    width:  calc(100vw - 120px);	
    background: #2A2C42;
    border-radius: 6px;
    height: calc(100vh - 48px);
    padding: 24px;
  }

  .clusterPage{
    .pageHeading{
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      .heading-left{
        display: flex;
        align-items: center;
        span{
          font-weight: normal;
          font-size: 24px;
          line-height: 24px;
          color: #FFFFFF;
          margin-left: 6px;
        }
      }
      .user__dropdown{
        align-items: center;
        margin-top: -7px;
        right: 24px;
      }
    }
    .newCluster{
      display: flex;
      justify-content: center;
      align-items: center;
      
      span{
        margin-left: 3px;
      }
    }
    .secoundleft{
      .secoundtext{
        display: flex;
        align-items: center;
        h5{
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #FFFFFF;
          margin:0 16px 0 0;
        }
        button{
          padding: 6px 10px;
          color: #030613;
          border-radius: 2px;
          align-items: center;
          &:nth-child(2){
            background: #FCAD01;
            margin-right: 16px;
           }
          &:nth-child(3){
            background: #D8D8D8;
          }
          &:disabled{
            cursor: not-allowed;
            background-color: rgba(239, 239, 239, 0.3);
            color: rgb(84, 84, 84);
            padding: 6px 10px;
            border-radius: 2px;
          }
          span{
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
    .secoundright{
      display: flex;
      justify-content: flex-end;
      .btn-Created{
        border: 1px solid #D4D6E0;
        border-radius: 12px;
        margin-right: 24px;
        color: #D4D6E0;
        button{
          padding: 3px 17px;
          span{
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
          }
          &:nth-child(1){
            border-radius: 12px 0 0 12px;
          }
          &:nth-child(2){
            border-radius: 0 12px 12px 0;
          }
        }
      }
      .selected {
        background: #fff;
        span {
          color: #030613;
        }
      }
      .filterinput{
        background: #14182A;
        border-radius: 3px;
        height: 26px;
        position: relative;
        width: 290px;
        padding: 6px 8px;
        display: flex;
        input{
          background: transparent;
          border: none;
          margin-left: 6px;
          color: #FFF;
          width: 100%;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .componentdesign{
      margin-top: 24px;
      overflow: auto;
      height: calc(100vh - 156px);
      padding-bottom: 32px;
      
      .card-wrapper{
        display: grid;
        column-gap: 16px;
        row-gap: 32px;
        grid-template-columns: repeat(auto-fit, 340px);        
      }

      .card-layout{
        width: 340px;
        display: flex;
        flex-direction: column;
        
        // margin-bottom: 16px;
        .cardbg{
          background: #1E2038;
          border: 1px solid #666A8D;
          border-radius: 8px 8px 0px 0px;
          padding: 18px 16px 10px 16px;
          flex: 1;

          .carttext{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            
            .cart-contact{
              margin-bottom: 11px;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              column-gap: 16px;
              
              h4{
                // width: 186px;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 0;
                color: #fff;
                letter-spacing: 0.25px;
                word-break: break-all;
              }
              .badge-section{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                
                .default-badge{
                  width: 60px;
                  height: 26px;
                  border-radius: 13px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  background-color: #C9CDEC;
                  margin-right: 8px;
                  padding: 4px 8px;
                  .label{
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.25px;
                    text-transform: capitalize;
                    color: #1E2038;
                  }
                }
                .status-badge{
                  height: 26px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  border-radius: 13px;
                  padding: 3px 2px 3px 6px;
                  .label{
                    color: #fff;
                    text-transform: capitalize;
                    margin-left: 20px;
                    margin-right: 10px;
                    font-size: 12px;
                    font-weight: 700;
                  }
                  .info__icon {
                    margin: 0;
                    margin-top: 1px;
                  }
                }
                .resizing{
                  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMSAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNS41IiBjeT0iNSIgcj0iNSIgZmlsbD0iI0M4RjlGQiIvPgo8L3N2Zz4K) #006198 no-repeat;
                  background-origin:padding-box; 
                  background-position:left 10px top 8px;
                }
                .running{
                  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiM4RkZGNzMiLz4KPC9zdmc+Cg==) #125900 no-repeat;
                  background-origin:padding-box; 
                  background-position:left 9px top 8px;
                  padding-left: 5%;
                }
                .restarting{
                  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiM2RDcwN0UiLz4KPC9zdmc+Cg==) #D4D6E0 no-repeat;
                  background-origin:padding-box; 
                  background-position:left 9px top 8px;
                  padding-left: 5%;
                  span{
                    color: #2A2C42 !important;
                  }
                }
                .pending{
                  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiNGRjc5NUQiLz4KPC9zdmc+Cg==) #FFE2D8 no-repeat;
                  background-origin:padding-box; 
                  background-position:left 9px top 8px;
                  padding-left: 5%;
                  span{
                    color: #2A2C42 !important;
                  }
                }
                .terminating, .terminated{
                  background:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNSIgY3k9IjUiIHI9IjUiIGZpbGw9IiNGRkJGQjEiLz4KPC9zdmc+Cg==) #970900 no-repeat;
                  background-origin:padding-box; 
                  background-position:left 10px top 8px;
                }
              }
            }
            .cart-contact-border{
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 0;
              border-bottom: 1px solid  #2A2C42;
              h5{
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 18px;
                color: #fff;
                margin-bottom: 0;
              }
              h6{
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #fff;
                margin-bottom: 0;
              }
              &:last-child{
                border: none;
              }
            }
          }
        }
        .cardfooter{
          background: #14182A;
          border: 1px solid #666A8D;
          border-radius: 0px 0px 8px 8px;
          border-top: transparent;
          padding: 12px 16px;
          .cardftext{
            display: flex;
            align-items: center;
            justify-content: space-between;
            button{
              border-radius: 6px;
              padding: 6px;
              display: flex;
              align-items: center;
              span{
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 14px;
                margin-left: 6px;
                color: #Fff;
              }
            }
            .svgfooter{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              svg{
                margin-left: 8px;
                margin-bottom: -5px;
                cursor: pointer;
                &:last-child{
                  margin-right: 0px;
                }
              }
            }
            .footer-btn-wrapper{
              display: flex;
              column-gap: 8px;
              .btn-yellow{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 69px;
                height: 32px;
                border-radius: 4px;
                box-shadow: none;
                // margin-right: 8px;
                span{
                  color: #000;
                }
              }

              // .btn-grey{
              //   color: #1E2038;
              // }
              // .btn-grey-transparent{
              //   display: flex;
              //   justify-content: center;
              //   align-items: center;
              //   border-radius: 4px;

              //   span{
              //     color: #D4D6E0;
              //     margin: 0px 2px;
              //   }
              // }

              .stop-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 69px;
                height: 32px;
                background: #B01900;
                border-radius: 4px;
                // margin-right: 8px;
              }
            }
          }
        }
      }


    }
  }
}
.clusterBoard .clusterPage .secoundleft .secoundtext button:nth-child(3):hover{
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(255, 255, 255, 0.56);
  border-radius: 2px;

}
.clusterBoard .clusterPage .secoundleft .secoundtext button:nth-child(2):hover{
  background: #FEC954;
  box-shadow: 0px 1px 4px rgba(252, 173, 1, 0.72);
  border-radius: 2px;
}

.addedit-cluster-Modal {
  width: 850px;

  .modal__header{
    background: rgba(0, 0, 0, 0.24);
    mix-blend-mode: normal;
    padding-left: 16px;
    padding-bottom: 12px;
  }

  .modal__subtitle{
    display: block;
    margin-top: 8px;
    opacity: 0.72;
    font-size: 12px;
    color: #fff;
  }

  .modal__body{
    padding: 0;
  }

  .addedit-cluster-tabs > .ant-tabs-bar.ant-tabs-top-bar{
    border-bottom: none;
    margin: 0;
    background: rgba(0, 0, 0, 0.24);
    mix-blend-mode: normal;
    padding-left: 16px;
  }
  
  .ant-tabs-nav{
    padding-left: 0px !important;
  }

  .ant-tabs-tab{
    padding: 0;
    padding-bottom: 4px;
  }

  

  .addedit-cluster-innertab{
    padding: 1.2rem;
    &.addedit-cluster-tabs {
      padding-right: 4px;
    }
  }
}

.ant-tabs-ink-bar{
  margin-left: 0;
  height: 2px;
  background: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.9;
}

.ant-tabs{
  color: white;
  .ant-tabs-nav {
    .ant-tabs-tab {
      color: #93949e;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.25px;
      padding: 5px 10px;
      padding-left: 8px;
      padding-top: 0;
  
      &:hover {
        color: #fff;
      }
    }
  
    .ant-tabs-tab-active {
      color: #fff;
    }
  
    .ant-tabs-ink-bar{
      height: 2px;
    }
  }

  .ant-tabs-bar{
    border-bottom: 1px solid #393E53;
  }
}

.editClusterInnerTab{
  padding: 16px 24px 24px 24px;
  // height: 630px;
  &.editClusterTab {
    padding-right: 4px;
  }


  &.librariesTab {
    padding-left: 0;  
    padding-right: 0;
    height: 652px;
    max-height: 75vh;


    .pagination__main {
      padding-left: 14px;
      padding-right: 16px;


      .countText{
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #FFFFFF;
        opacity: 0.72;
      }
    }
  }
}

.libraries__upperHalf{
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;

  &.active{
    height: 100%;
  }

  .btn-goBackWithArrow{
    margin-bottom: 16px;
    width: fit-content;
  }
}

.libraryTypeForUpload{
  margin-bottom: 20px;
}

.actionBtns__box{
 

  button:first-child {
    margin-right: 16px;
  }
}

.libraries__installationOptions{
  // border: 1px solid #666A8D;
  // border-radius: 8px;
  // padding: 12px 16px 16px;
  position: relative;
  // height: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;


  .libraries__installationOptions__title{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #FFFFFF;

  }
}

.librarySource__outer{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fileUpload__placeholder{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  span {
    font-size: 12px;
    line-height: 18px;
  }

  .fileUpload__placeholder__subTitle{
    color: #D4D6E0;
    margin-top: 4px;
  }
}

.fileUpload__outerBox{
  flex: 1;
  border: 1px dashed #666A8D;
  border-radius: 8px;
  padding: 16px 45px 16px 28px;
  display: flex;
  cursor: pointer;
  position: relative;
  // justify-content: space-between;

  &.showPlaceholder {
    justify-content: center;
  }

  .fileUpload__discardChanges{
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(13, 14, 38, 0.6);
    left: 0;
    border-radius: 8px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label{
      margin-left: -22px;
      font-weight: 700;
    }


    & > div{
      margin-top: 12px;
    }
  }
}

.deleteCluster__modal {
  width: 396px;
  background-color: #2A2C42;
  color: white;

  * {
    user-select: text;
  }

  .modal__body{
    padding-top: 0;
  }

  .modal__title{
    line-height: 21px;
  }

  p{
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;

    span{
      font-weight: bold;
    }
  }
  .warning__msg{
    color: #FF7761;
    margin-bottom: 16px;
  }

  .btns__outer{
    display: flex;
    justify-content: flex-end; 
    margin-top: 24px;
  }
}

.clusterLibrariesTable{
  margin-bottom: 25px;
  margin-top: 25px;

  .libraryStatus__box{
    padding-top: 6px;    
    padding-bottom: 6px;    
    height: 32px;
  }

  .libraryStatus__tag{
    text-transform: capitalize;
    background: #2A2C42;
    border-radius: 6px;
    height: 20px;
    line-height: initial;
    padding: 0px 6px 0px 8px;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    align-items: center;

    div {
      border: 4px solid green;
      border-radius: 50%;
      margin-right: 4px;
    }

  }
  

  .clusterLibrariesTable__row{
    background: #131727;
    border: 1px solid transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #FFFFFF;
    
    
    &:nth-child(odd){
      background: #0B0E1C;
    }

    .checkbox__container{
      transform: scale(0.67);
    }

    .checkbox__outerContainer{
      height: 32px;
      padding-top: 8px;
    }

    
    &.clusterLibrariesTable__row__selected{
      border: 1px solid #FCAD01;
      background: #1E2038;
    }

    &.clusterLibrariesTable__row__disabled{
      color: rgba(255,255,255,0.32);

      .libraryStatus__box{
        color: #fff;
      }

      .checkboxfield__disabled{
        cursor:default;
        background: #696A76;

        .checkmark{
          border-color: #898C9A;
          &:hover{
            border-color: #898C9A;
          }
  
        }
      }
    } 
  }

  .clusterLibrariesTable__header, .ReactVirtualized__Table__headerRow {
    background: #1E2038 !important;
    height: 32px;

    .ReactVirtualized__Table__headerTruncatedText{
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .ReactVirtualized__Table__rowColumn, .ReactVirtualized__Table__headerColumn {
    border-right: 1px solid #2A2C42;
    height: 32px;
    line-height: 32px;
    &:first-child, &:last-of-type{
      border: none;
    }
  }
}

.fileUpload__innerBox{
  width: 45%;
  height: 31px;
}

.filesSeparator{
  margin-left: 3.5%;
  margin-right: 3.5%;
  height: 100%;
  width: 1px;
  background: rgba(102, 106, 141, 0.72);
}

.fileUploadedStatus__box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-left: -12px;

  .fileUploadedStatus__innerBox {
    display: flex;
    align-items: center;
    position: relative;

    & > svg {
      width: 54px;
      height: 54px;
    }
  }
  .fileUploadStatus__icon{
    position: absolute;
    top: 10px;
    left: 31px;
  }

  .fileInfo__box{
    font-size: 10px;
    line-height: 18px;
    color: #C7C7CD;
    .fileName{
      color: #fff;
    } 
  }

  .progressCircle{
    width: 36px;
    height: 36px;
    
  }

  .ant-progress-text{
    font-size: 10px;
    color: #fff;
  }

  
}
.ant-radio-disabled + span {
  color: #ffffff;
  cursor: not-allowed;
}
.uploadedFilesType{
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: #C7C7CD;
}

.librarySource__upper{
//  display: flex; 
 margin-bottom: 17px;
}

.librarySource__fields {
  width: 85%;

  .inputfield__container {
    width: 48%;
    margin-right: 32px;
    margin-bottom: 16px;

    &:nth-child(even){
      margin-right: 0;
    }

  }

  &.maven__fields{
  display: flex; 
  flex-wrap: wrap;

  }
}

.libraries__installationOptions__buttons{
  margin-top: 16px;
  align-self: flex-end;

  button:first-of-type {
    margin-right: 16px;
  }
}

.installLibrary__form{
  margin-top: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.librarySource__dropdown{
  width: 150px;
  margin-right: 30px;
  margin-bottom: 0px;
}

.compute__container {
	display: flex;
	color: #ffffff;
	font-size: 12px;
	height: 100vh;
	overflow: hidden;
	.left_container {
		width: 345px;
		padding: 32px 12px;
		background-color: #212233;
	}
	.right_container {
		width: 100%;
		padding: 24px;
		background-color: #3e4161;
    margin: 0;
	}
  .page_left_title {
		color: #ffffff;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin-bottom: 4px;
	}

  .page_right_title_header {
		display: flex;
	}

  .page_right_title_icon_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
		align-items: center;
		
	}

	.page_right_title {
		@extend .page_left_title;
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
	}
	.subtitle {
		color: #c7c7cd;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}
  .newBtn__box {
		display: flex;
		flex-direction: row-reverse;
		padding-top: 16px;
	}
  .infoMsgContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }
  .infoMsg{
    text-align: center;
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #C7C7CD;
  }

  .list{
    margin-top: 20px;
  }
	.list_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
    margin-left: -38px;
	}
  .desContainer{
    display: flex;
    width: 180px;
  }
  .npipWorkspace{
    margin-left: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #C7C7CD;
  }
  .list_item_avatar {
		width: 48px;
		display: flex;
		justify-content: center;
	}

  .create-new-connection-text {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 2em;
		text-decoration: underline;
		color: #fcad01;
	}
	.no-active-connection-text {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 2em;
		color: #fcad01;
	}
  
  .more_icon {
		margin-left: 4.5px;
		width: 21px;
		height: 24px;
		&:hover {
			background: #3e4161;
			border-radius: 2px;
		}
	}

  a {
    margin-left: 4px;
    margin-right: 6px;
    color: #C7C7CD;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
  }

  .filter_div_container{
    width:321px;
    height:44px;
    background-color:#3E4161;
    border-radius:32px;
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding:4px;
    overflow: hidden;
    position: relative;
  }

  .filter_buttons {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px;
    width: 100%;
    margin-left: 10px;
    margin-right: 25px;
  }

  .filterButtonSelectedItem{
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    background:  rgba(255, 255, 255, 0.9);
    height: 36px;
    border-radius: 24px;
  }

  .arrow {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    z-index: 1;
  }
  
  .arrow.left {
    left: 0;
  }
  
  .arrow.right {
    right: 0;
  }
  
  .filterButtonItem {
    background-color:#3E4161;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;;
    border-radius: 24px;
    &:focus {
      color: #14182a;
      background-color: #ffffff;
      border-color: #ffffff;
    }
    
    &:hover {
      color: #14182a;
      margin: 4px;
      background-color: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(255, 255, 255, 0.56);
    }
  
    &:disabled{
      color: #14182a;
      cursor: auto;
      background-color:#3E4161;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .modalBtnsContainer{
    padding:24px;
    background-color:#3E4161;
    display:flex;
    justify-content:flex-end;
  }
}
