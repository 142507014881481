.dataexplorer__container {
    display: flex;
    color: #ffffff;
    font-size: 0.75rem;
    height: 100vh;

    ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
    }
}

.left_container {
    border: 1px solid #212233;
    height: 100vh;
    padding: 0.75rem;
    padding-bottom: 0rem;
    cursor: auto;
    overflow: hidden;
    background-color: #3a3b46;
    min-width: 400px;
    overflow-x: hidden;
    border: 1px solid #212233;
}

.heading {
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
    gap: 8px;
    padding-left: 0.5rem;
}

.falied-txt {
    font-size: 1rem;
    color: #C0C0C6
}

.select-txt {
    font-size: 1rem;
    font-weight: 400;
    color: #C0C0C6
}

.right_container {
    padding: 0.4rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    // background-color: #030613;
    border-radius: 0.35rem;
}


.middle_container {
    padding: 0.5rem 2rem 2rem;
    background: #030613;
    flex-grow: 3;
    width: 100%;
    height: 100vh;
}

.database_name {
    color: #c7c7cd;
    font-size: 1rem;
    font-weight: 500;
}

.page_title {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    position: relative;
}

.DataStudio-AI-text {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 1.6rem;
    margin-top: 0.5rem;
}

.page_title_info {
    color: #C7C7CD;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.subtitle {
    color: #C0C0C6;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 2rem;
    text-transform: capitalize;
}

.title {
    color: white;
    font-size: 1rem;
}

.list_container {
    padding-top: 0.75rem;
    width: 22.0625rem;
}

.bottom {
    position: absolute;
    bottom: 15px;
    z-index: 1000;

    >button {
        width: 28rem;
    }
}

.filter_div_container {
    width: 100%;
    height: 2.75rem;
    background-color: #3E4161;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem;
}

.filterButtonSelectedItem {
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000000;
    background: rgba(255, 255, 255, 0.9);
    height: 2.25rem;
    border-radius: 1.5rem;
}

.filterButtonItem {
    background-color: #3E4161;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    border-radius: 1.5rem;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.panel {
    // border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 0.5rem;
    padding: 0.75rem;
    width: 100%;
}

.ml-1 {
    margin-left: 1rem;
}

.m-1,
.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem !important;
}

.m-5 {
    margin-top: 5rem;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-half {
    margin-bottom: 0.5rem;
}

.grow-1 {
    flex-grow: 1;
}

.animate {
    transition: 0.5s all;
}

.rotate-180 {
    transform: rotate(-180deg);
}

.rotate-90 {
    transform: rotate(-90deg);
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    height: 2.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
    background-color: white;
    color: rgba(15, 18, 31, 1);
    border-radius: 0.25rem;
    margin-top: 0.5rem;

    &:hover {
        font-weight: bold;
    }

    &:active {
        font-weight: initial;
    }
}

.text {
    font-size: 0.9rem;
}

.textarea {
    background: rgba(33, 34, 51, 1);
    border-radius: 0.5rem;
    color: #FFF;
    border: 0 none white;
    overflow-y: auto;
    padding: 0;
    outline: none;
    resize: none;
    padding: 0.5rem;
    font-size: 0.875rem;
    width: 100%;
    margin: 0;
    margin-top: 0.2rem;
    height: 150px;
}

.input-error {
    border: 1px solid #FF6C59;
}

.w-full {
    width: 100%;
}

.panel-container {
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;

    >div {
        width: 100%;
    }
}

.right {
    display: flex;
    justify-content: space-between;
}

.error-text {
    color: #FF7766;
    font-size: 1rem;
    font-weight: 500;
}

.u-button {
    text-decoration: underline;
    font-size: 0.875rem;
}

.table-holder {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    // width:50vw;
    gap: 2rem;
    min-height: 100px;
    height: auto;
}

.spinner {
    animation: spin infinite 1s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.tab-content-height {
    height: 77vh;
    overflow-y: auto;
}

.left-list {
    list-style: none;

    ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
    }

    >li {
        >div {
            margin: 0.5rem 0;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            font-size: 1.4rem;
            cursor: context-menu;

            &:hover {
                font-weight: bold;
            }
        }

        >ul {
            display: block;
            background-color: #030613;
            border-radius: 0.35rem;
            padding: 0.5rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
            margin-top: 0.3rem;
            list-style: none;

            >li {
                >div {
                    margin: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: nowrap;
                    font-size: 0.875rem;
                    cursor: context-menu;

                    &:hover {
                        font-weight: bold;
                        background-color: #212233;
                    }

                    &.selected {
                        font-weight: bold;
                        background-color: #212233;
                    }
                }

                >ul {
                    list-style: none;
                    margin-left: 0.2rem;

                    >li {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: nowrap;

                        >div {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: nowrap;
                            cursor: context-menu;
                            padding: 1px 0rem;
                            border-radius: 0.25rem;

                            &:hover {
                                font-weight: bold;
                                background-color: #212233;
                            }
                        }

                        >ul {
                            list-style: none;
                            margin-left: 1.5rem;

                            >li {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-wrap: nowrap;
                                cursor: context-menu;

                                &:hover {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    text-overflow: ellipsis;
    width: 10px;
    font-size: 0.8rem;
}

.icon-holder {
    >svg {
        width: 32px;
        height: 32px;
    }

    overflow: hidden;
}

.bold {
    font-weight: bold;
}

div.selected {
    background-color: #212233;
}

.overflow-y {
    overflow-y: hidden;
}

.overflow-x {
    overflow-x: auto;
    height: calc(100vh - 90px);
    overflow-x: hidden;
}

.moreInfoModal {
    :global(.modal__header) {
        background-color: #3E4161;
        padding: 2rem;
    }

    :global(.modal__body) {
        background-color: #3e4161;
        padding: 0rem 2rem 2rem;
        font-weight: 500;
        font-size: 0.875rem;
    }

    :global(.modal__title) {
        font-weight: 700;
        font-size: 1.25rem;
    }

    :global(.modal__subtitle) {
        display: flex;
        font-weight: 500;
        font-size: 0.875rem;
        text-align: left;
    }
}

.moreInfoModal_row {
    background: rgba(0, 0, 0, 0.32);
    padding: 1.5rem;
    max-height: 28rem;
    overflow-y: auto;
    border-radius: 0.5rem;
}

.historyModal {
    background: rgba(0, 0, 0, 0.32);
    padding: 1.5rem;
    overflow-y: auto;
    border-radius: 0.5rem;
    width: 47.5rem;
    max-height: 43.375rem;
}

.moreInfoModal_col_title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.moreInfoModal_col_title {
    color: #C7C7CD;
    margin-top: 0.4rem;
    font-weight: 500;
    font-size: 1rem;
}

.moreInfoModal_col_content {
    padding: 0.5rem;
    width: 12.375rem;
    margin-top: 1.5rem;
    border: 0.063rem solid #3E4161;
    color: #FFFFFF;
    text-align: center;
    border-radius: 0.25rem;
}

.flex {
    display: flex;
}

.list_item_container {
    margin-top: 0.75rem;
    border-radius: 0.25rem;
}

.list_item_avatar {
    background: #7F85C7;
    color: #080613;
    font-weight: 500;
}

.list_item_title {
    color: #FFFFFF;
    font-weight: 500;
    font-size: 1rem;
}

.date-text {
    padding-top: 0.2rem;
    text-align: right;
    font-weight: 700;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.48);
}

.error-text {
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: #FF7766;
    margin-top: 5rem;
}

.warning-text {
    text-align: right;
    font-weight: 500;
    font-size: 1rem;
    color: #FBC441;
    text-transform: capitalize;
}

.logo {
    color: #FBC441;
    background: #212233;
    font-weight: 700;
    margin-left: 1rem;
}

.editor_container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.button_expand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 1rem;
    width: 10.5rem;
    bottom: 2.5rem;
    background: #3E4161;
    border-radius: 0.75rem;
    align-items: center;
    color: #FFFFFF;
}

.pagination {
    color: #ffffff;
    margin-top: 2rem
}

.showHistory-btn {
    text-decoration-line: underline;
    color: #C7C7CD;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 1.8rem;
}

.error-container {
    display: flex;
    height: 2rem;
    justify-content: space-between;
}

.btn-margin {
    margin-top: 0.85rem;
}

.ruleConfig {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.writeCsv-modal-subtitle {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    color: #c7c7cd;
}

.addEditWriteCsvModal_ {
    :global(.modal__header) {
        padding: 1.2rem;
        background-color: #3e4161;
        text-align: start;
        position: relative;
    }

    :global(.modal__body) {
        padding: 1.2rem;
        background-color: #3e4161;
        font-weight: 500;
        font-size: 0.875rem;
    }

    :global(.modal__title) {
        font-weight: 700;
        font-size: 1.25rem;
    }

    :global(.modal__subtitle) {
        display: flex;
        font-weight: 500;
        font-size: 0.875rem;
        text-align: left;
    }
}

.addEditWriteCsvModal_innerContainer {
    color: white;
    width: 538px;
}

.addEditWriteCsvModal_viewLogContainer {
    color: white;
    width: 900px;
}

.addEditWriteCsvModal_actionBtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 1rem;
}

.wcsvForm {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.write-csv-edit-btn {
    min-width: 96px;
    height: 26px;
}

.write-csv-cancel-btn {
    min-width: 96px;
    height: 26px;
}

.config-type-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #C7C7CD;
}

.row-center {
    display: flex;
    justify-content: center;
}

.file-picker-btn {
    margin-left: 1rem;
    margin-top: 1.7rem;
}

.alphaSlider {
    width: 95%;
    margin-bottom: 1rem;

    >span {
        color: #fff;
    }
}

.downloadCsvButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    height: 2.25rem;
    font-size: 1rem;
    line-height: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.32);
    border-color: rgba(255, 255, 255, 0.32);
    color: white;
    border-radius: 0.25rem;
    margin-top: 0.5rem;

    &:hover {
        font-weight: bold;
    }

    &:active {
        font-weight: initial;
    }
}

.downloadCSV__radio {
    margin-bottom: 0px;
    padding: 0px;
}

.beta {
    position: absolute;
    color: #FCAD01;
    font-weight: 700;
    left: 130px;
    top: -12px;
    font-size: 1rem;
}

.w-full {
    width: 100%;
}



.selectDatabase {
    border-radius: 5px;
    border: 1px solid #898C9A;
    height: 36px;
}

.select-field-value {
    background-color: #898C9A !important;
    color: #030613;
}

.group {
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    margin-bottom: 0.4rem;
}

.group_key_text {
    color: #C7C7CD;
    font-weight: 500;
    font-size: 1rem;
}

.queryChartsContainer {
    color: white;
    font-size: 1rem;
    min-width: 1000px;
    min-height: 80vh;
}

.divider {
    border-bottom: 2px dotted #ffffff;
    height: 4px;
    width: 100px;
}

.verticalDivider {
    border: 1px dotted #ffffff;
    height: 100px;
}

.queryGraphContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: #212233;
}

.queryGraphDiv {
    width: 100%;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
    }
}

.queryGraphWrapper {
    width: 100%;
    display: flex;
    overflow-x: auto;

    ::-webkit-scrollbar {
        width: 13px;
        height: 13px;
    }
}

.queryGraphWrapperAllEvents {
    width: 100%;
    display: flex;
    overflow-x: auto;
}

.plotMainContainer {
    height: 86vh;
    width: 100%;
    overflow-y: auto;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh,
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container {
    display: flex;
    align-items: center;
}

.icon-container>div {
    margin-right: 10px;
}

.database_name {
    color: #6D707E;
    font-size: 14px;
    font-weight: 400;
}

.dropdown-close {
    display: none !important;
}

.dropdown-open {
    display: block !important;
}