.tagSyncBrowser__container{
    display: flex;
    color: #ffffff;
    font-size: 12px;
}

.tagSyncBrowser__LHS{
    width: 289px;
    height: 100vh;

    .clientSitesList{
        padding: 0 16px;
        font-size: 16px;
        list-style-type: none;
    }

    .treeitem__heading{
        font-size: 16px;
        line-height: 20px;
    }

    .treeitem__info{
        padding-left: 10px;
        font-size: 14px;

        &.activeItem{
            font-weight: 600;
            color: #fec954;
        }
    }

    .treeitem__syncset{
        li{
            padding-left: 70px !important;
        }

        &.activeItem{
            font-weight: 600;
            color: #fec954;
        }
    }

    .treeview__list {
        position: relative;
    }

    .btn__syncSet{
        margin-bottom: 0;
        margin-left: 15px;
        // btn{

        // }
    }

    .canvasContextMenu{
        width: 100px;

        & > * {
            text-align: left;
        }
    }
}

.tagSyncBrowser__RHS{
    // width: 289px;
    background-color: #030613;
    height: 100vh;
    padding: 24px; 
    flex: 1;

    .syncSetCreation__options{
        display: flex;
        width: 700px;
        justify-content: space-between;
        align-items: center;

        .btns__box{
            button:first-child{
                margin-right: 10px;
            }
        }
    }

    .dataBrowser__RHS__header{
        display: flex;
        width: 700px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .search_input{
            width: 50%;
            height: 24px;
            background-size: 14px;
            background-position: 6px;
        }
    }

    

    

    

    .noTagsMsg{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}
.dataBrowser__table__title{
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 19px;
    margin-bottom: 0;
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.syncset__title{
        font-size: 20px;
    }
}

.paginationContainer{
    margin-top: 18px;
    width: 700px;
    position: relative;

    // form {
    //     position: absolute;
    // }

    .numberOfItemsDropdown{
        position: absolute;
        width: 120px;
        right: 150px;
        top: -6px;
        display: flex;
        justify-content: space-between;

        .select-field-box{
            width: 45%;
        }

        .inputfield__label {
            line-height: 25px;
        }
    }
}


.dataBrowser__table{
    // border: 1px solid #2A2C42;
    user-select: text;
    margin-bottom: 45px;

    .dataBrowser__table__header {
        border: 1px solid #2A2C42;
        font-weight: 600;
        margin: 0px;
        line-height: 32px;
        text-align: center;
        height: 100%;
        border-bottom: 0;
        border-left: 0;
        border-radius: 4px 4px 0 0;
        background-color: #1E2038;
        
       

        .dataBrowser__table__cell.dataBrowser__table__cell_even {
            background-color: #1E2038;
            // height: ;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
    }

    .dataBrowser__table__dataBox{
        background-color: #0b0e1c;
        .dataBrowser__table__cell {
            text-align: left;

        }
    }

    .dataBrowser__table__row{
        border-bottom: 1px solid #2A2C42;
        border-left: 1px solid #2A2C42;
        

        &:hover{
            background-color: rgba(252, 218, 1, 0.3);
        }

        &:nth-child(even){
            background-color: #14182A;
        }
    }

    .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
        margin-left: 0;
    }

    .ReactVirtualized__Table__rowColumn, .dataBrowser__table__cell {
        border-right: 1px solid #2A2C42;
        color: #FFFFFF;
        font-family: 'DM Sans';
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 30px;
        text-align: center;
        height: 100%;
        margin: 0px;
    }

    

    .checkBoxTableField{
        // Change to padding
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .dataBrowser__table__cell {
        padding: 0 7px;
        text-align: left;
        background-color: #0B0E1C;
        position: relative;

        &.dataBrowser__table__cell_even{
            background-color: #131727;
        }

        &.dataBrowser__table__cell--hover{
            background-color: #666A8D;
            border-bottom: 1.5px solid #FFFFFF;
            border-top: 1.5px solid #FFFFFF;
            border-right-color: transparent;
            font-weight: 600;
        }

        &.dataBrowser__table__cell--hover--firstCell{
            border-left: 1.5px solid #FFFFFF;
        }

        &.dataBrowser__table__cell--hover--lastCell{
            border-right: 1.5px solid #FFFFFF;
        }

        &.dataBrowser__table__cell--headerHover{
            color: #FEC954;
            border-left: 1px solid #494C6E;
            border-right: 1px solid #494C6E;
        }

        &.dataBrowser__table__cell_even.dataBrowser__table__cell--headerHover--firstCell{
            background-color: #666A8D;
            border: 1.5px solid #FCAD01;
            // border-bottom-width: 4px;
            border-radius: 1px;
            color: #ffffff;
            height: 30px !important;
            line-height: 26px;
            // margin-top: -4px;
            // display: flex;
            // align-items: center;
        }
    }
    
}

.newEditPlantSite__modal{
    width: 396px;

    .inputfield__container{
        margin-bottom: 20px;
    }

    .newEditPlantSite__form{
        height: 396px;
    }

    .inPageSpinner{
        height: 350px;
        align-items: center;
    }

}


