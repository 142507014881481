.inPageSpinner {
	display: flex;
	justify-content: center;

	&.inPageSpinner__small .lds-ring {
		width: 40px;
		height: 40px;

		div {
			border-width: 3px;
			width: 28px;
			height: 28px;
		}
	}

	&.inPageSpinner__XSmall .lds-ring {
		width: 30px;
		height: 30px;

		div {
			border-width: 2px;
			width: 18px;
			height: 18px;
		}
	}


	&.inPageSpinner__black .lds-ring div {
		border-color: black transparent transparent transparent;
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 55px;
	height: 55px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	margin: 6px;
	border: 6px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
