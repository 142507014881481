.inputfield__container {
    position: relative;
    width: 100%;
  // border-bottom: 2px solid #393E53;
  // padding-left: 15px;
  // padding-right: 35px;

  &.textarea__container textarea {
    height: auto;
  }



  #errorCrossIcon{
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  .inputfield,
  textarea {
    width: 100%;
    display: block;
    background-color: #14182a;
    border-radius: 2px;
    height: 28px;
    border: transparent;
    padding: 4px 8px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 18px;
    border: 1px solid transparent;
    border-radius: 2px;

    &:focus {
      border-color: #fcad01;
    }

    &:read-only {
      color: #14182a;
      background-color: #696a76;
      border-color: #898c9a;
    }

    &:disabled {
      background-color: #696a76;
      color: #14182a;
    }
  }

  textarea {
    min-height: 24px !important;
    height: auto;
    resize: none;
    max-height: 120px;
  }

  .inputfield:focus {
    outline-offset: 0px;
    outline: none;
  }

  input[readOnly]:focus {
    outline: none;
    border: none;
  }
}

.inputfield__label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 18px;
  text-align: left;
  display: block;
  margin-bottom: 4px;
  // text-transform: capitalize;
  position: relative;
}

.inputfield__errormessage {
  margin-top: 6px;
  color: #ff7761;
  font-size: 10px;
  letter-spacing: 0.25px;
  line-height: 16px;
  display: block;
}

.inputfield--error {
  box-shadow: none !important;
  border: 1px solid #b01900 !important;
  outline: none;

  input {
    background-color: #fce6e5 !important;
  }

  &.showCrossIcon {
    background-image: url(./assets/cross.png);
    background-size: 14px 14px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: calc(100% - 4px);
  }
}

.red-star::after {
  content: " *";
  color: #e20c00;
}

._form--halfwide {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 45%;
  }
}

.split__fields--halfwide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    width: 45%;
  }
}

.select-field__box {
  display: block;
}

.select-field {
  margin-bottom: 30px;
}

.multi-select-field-search {
  background-color: #14182a;
  width: 95%;
  border-color: transparent;

  &:focus {
    outline: transparent;
  }
}

.select-field-box {
  vertical-align: middle;
  // background-color: white;
  position: relative;
  border-radius: 1px;
  width: 100%;
  -webkit-font-smoothing: antialiased;

  &.select-field-box--active .select-field-value {
    background-image: url(./assets/down-arrow-select-2-rev.png);
    border-color: #fcad01;
  }

  &.select-field-error .select-field-value{
    border-color: #b01900;
  }

  .select-field-value {
    background-color: #14182a;
    background-image: url(./assets/down-arrow-select-2.png);
    background-size: 16px 16px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: calc(100% - 4px);
    border: 1px solid transparent;
    border-radius: 2px;
    height: 26px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 18px;
    width: 100%;
    cursor: pointer;
    padding: 4px 8px;
    transition: border-color 0.25s;
    transition: background-image 0.25s;
    user-select: none;

    &:focus {
      outline: none;
      // background-color: transparent;
    }

    &:disabled {
      background-color: #696a76;
      color: #14182a;
      background-image: none;
      cursor: default;
      font-weight: 400;
    }
  }

  &.select-field-box--active .multiple-select-values::after {
    background-image: url(./assets/down-arrow-select-2-rev.png);
    border-color: #fcad01;
  }

  .multiple-select-values {
    list-style-type: none;
    padding-top: 3px;
    padding-bottom: 0;
    padding-left: 4px;
    position: relative;
    height: auto;
    min-height: 26px;
    display: flex;
    flex-wrap: wrap;

    &::after:not(.no-wrap) {
      background-image: url(./assets/down-arrow-select-2.png);
      // linear-gradient(to right, rgba(0, 0, 0, 0.01) , rgba(0, 0, 0, 0.01));
      border-color: #fcad01;
      content: "";
      position: absolute;
      right: 0px;
      top: 0;
      height: 26px;
      background-color: #14182a;
      width: 25px;
      transition: background-image 0.25s;
      background-size: 16px 16px, 10px 26px;
      background-repeat: no-repeat;
      background-position: 5px 4px, 0 -1px;
    }

    &.no-wrap {
      white-space: initial;
      overflow: initial;
      height: auto;
      min-height: 27px;
    }

    .multiple-select-tags-list {
      max-height: 92px;
      overflow: auto;
      margin-right: 10px;
    }

    &.select-field-disabled{
      background-image: none;
      cursor: default;
    }
  }
}

.multiple-select-tags {
  background-color: #d4d6e0;
  border-radius: 12px;
  padding: 0 8px;
  color: #2a2c42;
  font-weight: 600;
  margin-right: 5px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  word-break: break-all;
  margin-bottom: 5px;
  font-family: 'DM Sans';
}
.closeIcon {
  background-color: #14182a;
  width: 18px;
  height: 18px;
  color: white;
  padding: 1px;
  transform: scale(0.9);
  font-weight: 600;
  font-size: 10px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  margin-left: 4px;
  flex-shrink: 0;
  
  &:hover {
    transform: scale(1);
  }
}

.select-field-options-outer {
  padding: 4px 0;
  position: absolute;
  top: 30px;
  left: 0px;
  max-height: 120px;
  box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
    2px 4px 8px 0 rgba(0, 0, 0, 0.32);
  border: 1px solid #6d707e;
  border-radius: 2px;
  overflow-y: auto;
  z-index: 3;
  width: 100%;
  background-color: #2a2c42;

  &.select-field-bottom-fixed-option{
    max-height: none;
    overflow: initial;

    .select-field-options{
      max-height: 118px;
      overflow: auto;
      margin-bottom: 24px;
    }



  }

  .select-field-options {
    list-style: none;
    display: block;
    margin: 0;
    width: 100%;

    &.select-field-options--top {
      top: 0px;
      left: 0px;
      border: none;
      border-top: 1px solid #e6e6e6;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    &.select-field-options--left,
    &.select-field-options--right {
      padding: 5px;
      // border: 1px solid #252525;
      min-width: 80px;
      color: #e6e6e6;
      margin-top: 15px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-bottom: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        top: -5px;
      }

      .select-field-option {
        padding: 5px 15px;
        background-color: transparent;
        height: 30px;
      }

      .select-field-option:hover {
        background-color: #006cff;
        padding: 5px 15px;
      }
    }

    &.select-field-options--left {
      left: 0;
      right: auto !important;

      &::before {
        left: 10px;
      }
    }

    &.select-field-options--right {
      left: auto !important;
      right: 0px;
      color: #252525;
      color: red;

      &::before {
        z-index: 20;
        // right: 10px;
        margin-left: 100%;
      }
    }
  }

  .select-field-option {
    width: 100%;
    min-height: 24px;
    padding: 3px 7px;
    cursor: pointer;
    z-index: 1;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 18px;
    word-break: break-all;

    &:hover {
      background-color: #666a8d;
      font-weight: 600;
    }

    &.select-field-option-multiple {
      display: flex;
      align-items: center;

      .checkbox__container {
        transform: scale(0.68);
        margin-right: 7px;
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.3199999928474426;
    }

    &.select-field-option--selected {
      // background-color: #2a2c42;
      font-weight: 600;
    }

    &.select-field-option-bottom-fixed {
      position: absolute;
      bottom: 2px;
      background-color: #2a2c42;

      &:hover{
        background-color: #666a8d;;

      }
    }
  }
}

.input-tag-field {
  margin-bottom: 30px;
}

.input-tag-field__innerbox {
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 6px 12px;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;

  input {
    border: none;
    padding: 0px;
    height: 33px;
    flex-grow: 1;

    &:focus {
      background-color: transparent;
    }
  }

  .input__tag {
    background-color: #e8e8e8;
    border-radius: 1px;
    color: #333;
    // display: inline-block;
    font-family: "Proxima Nova";
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px 4px;
    line-height: 30px;
    text-align: center;
    user-select: none;
    height: 33px;

    .close-icon {
      cursor: pointer;
      margin-left: 3px;
      padding-left: 5px;
      font-size: 0.8em;
    }
  }

  .input-tag-field__suggestions {
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    list-style-type: none;
    border: 1px solid #e6e6e6;
    border-top: none;
    display: block;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    max-height: 205px;
    overflow-y: auto;

    li {
      padding: 11px 15px;
      cursor: pointer;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}

.radiofield__inner__container {
  margin-bottom: 30px;

  .inputfield__label {
    // margin-right: 15px;
  }

  input[type="radio"] {
    display: none;
  }

  .radiofield {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    letter-spacing: 0.25px;
    font-size: 12px;
    margin-right: 16px;
    height: 18px;
  }

  .radiofield span.label {
    position: relative;
    display: inline-block;
    margin-top: 8px;
    margin-right: 8px;
    border: 2px solid #fcad01;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    transform: scale(1);
    transition: transform 0.2s ease;
    pointer-events: none;
    -webkit-tap-highlight-color: transparent;
  }

  .radiofield:hover span.label {
    transform: scale(1.1);
  }
  input[type="radio"]:checked + .label {
    position: relative;
    border: 2px solid #fcad01;
  }

  input[type="radio"]:checked + .label:after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 8px;
    top: 2px;
    left: 2px;
    position: absolute;
    background-color: rgba(250, 251, 252, 0.72);
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
    opacity: 1;
  }
  &.readonly-radio {
    .radiofield span.label{
      border-color: #c8ccd4;
      background-color: #696a76;
      transform: none;
    }

    .option__label, .inputfield__label, .checkbox__label{
      opacity: 0.32;
    }


    input[type="radio"]:checked + .label {
      border-color: #c8ccd4;

      &::after{
        background-color: #c8ccd4;
      }
    }

    input[type="radio"]:checked ~ .option__label {
      opacity: 0.56;
    }
    .radiofield {
      cursor: default;
    }

    
  }

  .option__label {
    margin-top: 9px;
  }

  &.inline {
    display: flex;
    align-items: center;

    .inputfield__label {
      margin-bottom: 0px;
      margin-right: 10px;
    }

    .radiofield {
      .option__label, .label{
        margin-top: 0;
      }
    }
  }
}

.dropdown__master {
  position: relative;
  height: 100%;

  .select-field-options {
    top: 50px;
  }

  .dropdown__menu {
    cursor: pointer;
    list-style-type: none;
    position: absolute;
    top: 24px;
    left: 0px;
    right: auto;
    background-color: #14182a;
    border-radius: 1px;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.32),
      2px 4px 8px 0 rgba(0, 0, 0, 0.16);
    width: 95px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    z-index: 1004;

    &.dropdown__menu--right {
      right: 0px;
      left: auto;
    }
  }

  .dropdown__menu__item {
    height: 24px;
    padding: 6px 0 6px 12px;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: #fcad01;
      color: #262231;
    }

    &.disabled {
      opacity: 0.31;
      cursor: default;
      background-color: transparent;

      &:hover {
        background-color: #14182a;
        color: white;
      }
    }
  }

  .dropdown__items__separator {
    height: 1px;
    background-color: #393e53;
    margin: 6px 0;
  }
}

.hide__formfield {
  display: none !important;
}

.toggleField {
  border: 1px solid rgba(255, 255, 255, 0.56);
  border-radius: 8px;
  width: 32px;
  height: 16px;
  position: relative;
  cursor: pointer;
  display: inline-block;

  .toggleDot {
    background-color: #ffffff;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 1px;
    left: 2px;
    transition: left 0.25s;
  }

  &.active {
    background-color: #fcad01;
    border: 1px solid #ffa900;
    opacity: 1;

    .toggleDot {
      background-color: #262231;
      left: 16.5px;
    }
  }

  
}

.toggleField__container {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &.disabled{
    opacity: 0.32;

    .toggleField {
      cursor: default;
    }
  }
}
.toggleField__label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 18px;

  &.toggleField__label__active {
    font-weight: 700;
  }
}

.fileBrowserModal {
  .modal__header {
    height: 0;
    padding: 0;
  }

  .modal__body {
    background-color: #2a2c42;
    border-radius: 4px;
    box-shadow: 4px 8px 32px 0 rgba(0, 0, 0, 0.48),
      inset 0 0 4px 0 rgba(255, 255, 255, 0.1);
    width: 550px;
    height: 425px;
    position: relative;
    color: #ffffff;
    padding: 0;
  }

  .chonky-folder-chain {
    letter-spacing: 0.25px;
  }

  .chonky-side.chonky-side-left,
  .chonky-side.chonky-side-right {
    margin-bottom: 0;
  }

  .chonky-file-list-details .chonky-file-list-entry {
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 14px;
    min-height: 33px !important;
    height: auto !important;
    border: 1px solid transparent;
    border-bottom-color: #393e53;

    &:hover {
      background-color: #14182a;
      border-color: #fcad01;
      border-radius: 2px;
    }

    &.chonky-selected,
    &.chonky-selected:hover {
      background-color: rgba(0, 153, 255, 0.07);
      background: repeating-linear-gradient(
        -45deg,
        rgba(20, 24, 42, 1),
        rgba(20, 24, 42, 1) 10px,
        rgba(252, 173, 1, 0.32) 10px,
        rgba(252, 173, 1, 0.32) 20px
      );
      border: 1px solid #fcad01 !important;
    }

    &.chonky-file-list-entry:focus {
      box-shadow: none;
    }
  }

  .chonky-controls {
    background-color: rgba(0, 0, 0, 0.24);
    padding: 7px 16px;
    font-size: 12px;
  }

  .chonky-file-list-entry-date {
    width: 20%;
    text-align: right;
  }

  .chonky-file-list-details .chonky-file-list-entry > div {
    flex-grow: 0;
  }
  .chonky-file-list-entry-size {
    width: 15%;
    text-align: right;
  }

  .selectedFileName {
    flex: 1;
    margin-right: 40px;
  }

  .chonky-folder-chain-entry:hover,
  .chonky-icon-button:hover {
    background-color: #2a2c42;
    border-radius: 1px;
  }

  .chonky-dropdown-contents-wrapper .chonky-dropdown-contents {
    background-color: #2a2c42;
    border-radius: 1px;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48),
      2px 4px 8px 0 rgba(0, 0, 0, 0.32);
    border-color: transparent;
    padding-top: 4px;
    padding-right: 0;
    padding-left: 0;
    width: 115px;

    .chonky-dropdown-button {
      border-bottom: none;
      height: 24px;
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.25px;
      line-height: 24px;
      padding: 0 !important;
      padding-left: 22px !important;
      position: relative;

      // &:first-child{
      //     padding-left: 8px !important;
      // }

      &:hover {
        background-color: #0b0e1c;
      }

      .chonky-text-active {
        color: #fcad01;
        position: absolute;
        left: 5px;
      }

      .svg-inline--fa.fa-arrow-up,
      .svg-inline--fa.fa-arrow-down {
        font-size: 12px;
      }

      .chonky-text-subtle {
        display: none;
      }
    }
  }

  .chonky-file-list.chonky-file-list-details {
    max-height: 325px;
    padding: 0 16px;
    overflow-y: auto !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    // max-height: 330px !important;
  }

  .fileSelectionOptions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
    padding: 19px 16px 16px;

    button:first-child {
      margin-right: 12px;
      color: #c7c7cd;
    }
  }

  .chonky-side-inside.chonky-side-inside-right .chonky-dropdown:nth-child(2) {
    display: none;
  }
}

.maximize-img {
  float: right;
  width: 20px;
  span {
    &:hover{
      color:white;
    }
  }
}

.info__icon {
  margin-left: 5px;
  margin-top: -2px;
  // margin-bottom: 5px;
}

.dynamicKeyValueGenBox {
  .addButton {
    color: white;
    // text-decoration: underline;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 18px;
  }

  .fieldsRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    & > div {
      width: 42%;
    }

    button {
      margin-top: 15px;
    }
  }

  &.disabled {
    opacity: 0.32;

    .addButton{
      cursor: default;
    }
  }
}

.checkboxfield__container {
  .optionsList {
    list-style-type: none;
    display: flex;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      color: white;
      font-size: 12px;
      margin-right: 16px;
      height: 16px;
      letter-spacing: 0.25px;
    }
  }

  &.checkboxfield__disabled .checkmark{
    background-color: #696A76;
    border-color: #898C9A;;

    &:hover{
      border-color: #898C9A;;
      cursor: default;
    }
  }

  &.checkboxfield__disabled {
    cursor: default;
    .checkbox__gold{
      opacity: 0.32;

      & ~ span {
        opacity: 0.32;
      }
    }
  }



  .checkbox__container {
    margin-right: 8px;
    display: inline-block;
    
    &.checkbox__sm{
      transform: scale(0.66);
    }
  }
}

.dynamicKeyValueGenBoxCluster {
  padding-bottom: 40px;
  padding-left: 20px;

  .section-wrapper{
    display: flex;

    .dashed-connector{
      width: 20px;
      height: 152px;
      border: 1px dashed #6D707E;
      border-top: 0;
      border-right: 0;
      margin-top: -80px;
    }

    .first-child{
      height: 122px !important;
      margin-top: -50px !important;
    }
    
    .fieldsRow{
      display: flex;
      background: #666A8D;
      mix-blend-mode: normal;
      width: 363px;
      opacity: 0.72;
      border-radius: 2px;
      padding: 10px;
      margin-bottom: 15px;
  
      .field_wrapper{
        display: flex;
        flex-direction: column;
        width: 85%;

        .select-field, .inputfield__container {
          margin-bottom: 10px;
        }
      }
  
      button{
        margin-top: 0;
      }
  
      .UrlInput { 
        position: relative; 
        height: 50px;
        margin-bottom: 10px;
  
        .prefix{
          position: absolute;
          left: 10px;
          top: 28px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.25px;
        }
  
        input { 
          position: absolute;
          top: 23px;
          padding-left: 40px;
        }
      }
    }
  }
  
  .addButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    font-style: normal;
    font-weight: bold;
    padding: 2px 6px 2px 2px;
    width: 121px;
    height: 24px;
    background: #D4D6E0;
    color: #2A2C42;
    border-radius: 12px;
    letter-spacing: 0.25px;
    margin-left: 20px;

    .add-icon{
      background: #2A2C42;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      color: #fff;
    }
  }
}

.dynamicKeyValueGenBoxCluster .fieldsRow {
  justify-content: space-around;
  align-items: flex-start;
}

.chonky-file-list-notification-empty .chonky-file-list-notification-content {
  font-size: 0px;
  padding: 12px 0 0 0;
}

.chonky-file-list-notification-empty .chonky-file-list-notification-content svg {
  font-size: 16px;
  margin: 0px 12px 0 0;
}

.chonky-file-list-notification-empty .chonky-file-list-notification-content:after {
  content: 'No files available.';
  font-size: 16px;
}

.tagField__outerBox{
  margin-bottom: 12px;

}


.tagField__innerBox{
	// background-color: #14182a;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 2px;
  min-height: 26px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 18px;
  width: 100%;
  // cursor: pointer;
  padding: 4px 8px 4px 0;

  &.active{
    // border-color: #fcad01;
  }

  & > * {
    display: inline-block;
  }

  .multiple-select-tags {
    height: 18px;
  }

  .closeIcon {
    line-height: 17.5px;
  }

  input{
    outline: none;
    border-color: transparent !important;
    box-shadow: none !important;
    font-family: 'DM Sans';
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    min-width: 90px;
  }


}

.tagFieldWithSuggestions__container{
  width: 100%;

  

  .tagFieldWithSuggestions__tagsOuterBox{
    background: #14182A;
    
    // overflow-y: auto;
    position: relative;


    input {
      background-color: transparent;
      border: transparent;
      color: #fff;
      font-size: 12px;
      flex: 1;
      height: 22px;
      // margin-top: 4px;
      // width: 100%;

      &:focus{
        outline: none;
      }
    }
  }

  .tagsBox{
    display: flex;
    flex-wrap: wrap;
    max-height: 120px;
    overflow: auto;
    border: 1px solid transparent;
    padding: 6px 8px;
    min-height: 30px;
    border-radius: 2px;
    row-gap: 6px;

    &:focus-within{
      border-color:#FCAD01;
    }
  }

  .suggestions__outer{
    position: absolute;
    width: 100%;
    left: 0;

    ul {
      background: #14182A;
      border-radius: 2px;
      padding-top: 8px;
      padding-bottom: 8px;
      max-height: 130px;
      overflow: auto;
      // padding-right: 8px;
    }


    li {
      list-style-type: none;
      color: white;
      // height: 22px;
      font-size: 12px;
      line-height: 22px;
      letter-spacing: 0.25px;
      padding-left: 8px;
      padding-right: 8px;
      margin-bottom: 6px;

      &:last-of-type{
        margin-bottom: 0px;
      }

      &.activeItem {
        background-color: #666a8d;
        font-weight: 500;
      }
    }
  }
}

.customDatePicker{
  z-index: 10;
  .rc-picker-panel{
    border: 1px solid #FCAD01;
    border-radius: 1px;
  }

  &.rc-picker-dropdown-range{
    padding-top: 0;
  }


  & > * {
    font-family: "DM Sans";
  }

   .rc-picker-datetime-panel, .rc-picker-date-panel, .rc-picker-year-panel, .rc-picker-month-panel {
    background: #2A2C42;
  }

  .rc-picker-date-panel{
    padding-top: 4px;
  }
  
  .rc-picker-datetime-panel .rc-picker-time-panel, .rc-picker-time-panel-column{
    border-left: 1px solid rgba(102, 106, 141, 0.56);
    
    .rc-picker-content{
      padding-left: 4px;
      padding-right: 0;
    }

    .rc-picker-time-panel-column{
      width: 35px;
      padding-bottom: 170px;

      &:first-of-type{
        border-left: none;
      }
    }
  }

  .rc-picker-time-panel{
    padding-top: 4px;
    // Hides Time in time panel
    .rc-picker-header{
      display: none;
    }

    .rc-picker-content {
      max-height: 195px;
    }
  } 

  .rc-picker-cell {
    border: 2px solid transparent;
  }

  // Resting state of date and time
  .rc-picker-cell .rc-picker-cell-inner, .rc-picker-cell.rc-picker-cell-in-view .rc-picker-cell-inner, .rc-picker-time-panel-column > li .rc-picker-time-panel-cell-inner{
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 12px;
    color: white;
    padding: 0;
    text-align: center;

    &:hover{
      background: #FCAD01;
      color: #030613;
    }
  }
  
  // Selected Date and Time
  .rc-picker-cell.rc-picker-cell-selected .rc-picker-cell-inner, .rc-picker-time-panel-cell.rc-picker-time-panel-cell-selected .rc-picker-time-panel-cell-inner{
    border-radius: 12px;
    background: #FCAD01;
    color: #030613;
  }



  // Disabled dates
  .rc-picker-cell.rc-picker-cell-disabled, .rc-picker-cell.rc-picker-cell-disabled.rc-picker-cell-selected {
    opacity: 1;
    
    .rc-picker-cell-inner{
      color: rgba(256, 256, 256, 0.25); 
      border: none;
      
      &:hover{
        background: transparent;
        color: rgba(256, 256, 256, 0.25); 
      }
    }
    
  }


  .rc-picker-header{
    padding: 0 4px;

    & > * {
      color: white;
      font-size: 12px;
    }

    .rc-picker-year-btn::before{
      content: ' ';
      width: 5px;
      height: 5px;
      display: inline-block;
    }
  }

  // Week day names in Date panel
  .rc-picker-body thead{
    color: white;
    font-weight: 400;
  }


  .rc-picker-header-next-btn, .rc-picker-header-super-next-btn, .rc-picker-header-super-prev-btn, .rc-picker-header-prev-btn{
    width: 20px;
    height: 20px;
    font-size: 14px;
    
  }

  .rc-picker-footer{
    display: none;
  }

  .rc-picker-cell.rc-picker-cell-range-start ,  .rc-picker-cell.rc-picker-cell-range-start-end, .rc-picker-cell.rc-picker-cell-range-end, .rc-picker-cell.rc-picker-cell-range-hover-end {
    position: relative;
    
    .rc-picker-cell-inner {
      background: #FCAD01;
      border-radius: 12px;
      color: #000000;
    }

    &.rc-picker-cell-range-hover:after{
      border: none;
    }

    &::after{
      border-color: transparent;
    }

  }

  // Adds extra background for range end date
  .rc-picker-cell.rc-picker-cell-range-end, .rc-picker-cell.rc-picker-cell-range-hover-end, .rc-picker-cell.rc-picker-cell-range-end.rc-picker-cell-selected {
    .rc-picker-cell-inner{
      z-index: 1;
      position: relative;
    }
    

    &::after{
      width: 20px;
      height: 100%;
      background: #c2882a;
      position: absolute;
      content: '';
      display: block;
      left: -4px;
      top: 0;

    }
  }

  // Adds extra background for range start date
  .rc-picker-cell.rc-picker-cell-range-start, .rc-picker-cell.rc-picker-cell-range-hover-start, .rc-picker-cell.rc-picker-cell-range-start.rc-picker-cell-selected {
    .rc-picker-cell-inner{
      z-index: 1;
      position: relative;
    }
  
    
    &::after{
      width: 16px;
      height: 100%;
      background: #c2882a;
      position: absolute;
      content: '';
      display: block;
      left: auto;
      right: 0px;
      top: 0;

    }

    // when start date is selected
    &.rc-picker-cell-range-start-single::after {
      display: none;
    }

    // when start date is selected and end date is not selected yet
    &.rc-picker-cell-range-start-single.rc-picker-cell-range-hover-start::after{
      display: block;
    }
  }


  // start date and end date are same
  .rc-picker-cell-range-start.rc-picker-cell-range-end.rc-picker-cell-selected{
    &::after{
      content: none;
    }
  }


  // to show range bar
  .rc-picker-cell.rc-picker-cell-in-range, .rc-picker-cell.rc-picker-cell-range-hover{
    background: #c2882a;
    position: relative;
    border-color: transparent;

    .rc-picker-cell-inner {
      border-radius: 0;
      border-color: transparent;

      &:hover{
        border-radius: 12px;
      }
    }

    // to hide the gap between dates
    &::after{
      display: block;
      content: '';
      border: none;
      position: absolute;
      height: 100%;
      width: 2px;
      left: -2px;
      // right: -10px;
      top: 0;
      bottom: 0;
      // background: rgba(252, 173, 1, 0.72);
      background: #c2882a;

      border-color: transparent;
    // background: rgba(252, 173, 1, 0.72);

    }

    
  } 

  .rc-picker-year-panel {
    .rc-picker-header{
      padding-top: 4px;
    }

    // year cells width
    .rc-picker-cell.rc-picker-cell-in-view .rc-picker-cell-inner{
      width: 50px;
    }
  }

  // hides blue arrow of active date
  .rc-picker-range-arrow::after, .rc-picker-range-arrow::before{
    display: none;
  }


  .rc-picker-cell-today > .rc-picker-cell-inner{
    border-color: transparent;
  }

  .rc-picker-range-wrapper.rc-picker-date-range-wrapper {
    .rc-picker-panel{
      border: none;
    }

    .rc-picker-panel-container{
    border: 1px solid #FCAD01;
    background: #2A2C42;
      
    }
  }

  #btn-clear-range{
    font-size: 12px;
    color: white;
    text-decoration: underline;
    padding: 8px 10px;
    float: right;
  }
  
}


// sets width of month cells in month panel
.rc-picker-month-panel {
  .rc-picker-cell.rc-picker-cell-in-view .rc-picker-cell-inner{
    width: 30px;
  }
}


$range-input-field-width: 110px;
$range-input-field-gap: 2px;
.customDatePicker__input {
  .rc-picker-input{
    margin-right: $range-input-field-gap;

      input{
        width: $range-input-field-width;
        background-image: url(./assets/Calendar.svg);
        background-position: right 2.5px top 2px;
        background-repeat: no-repeat;
        position: relative;
      } 

       
  }
  &.showTime .rc-picker-input input{
    width: 160px;
  }



  .rc-picker-active-bar{
    display: none;
  }

  &.rc-picker-focused .rc-picker-input-active input{
    background-image: url(./assets/Calendar-Active.svg);
  }

  

}

.customDatePicker__input {
  &.rc-picker-focused{
    border: none;
  }

  input{
    width: 100%;
    display: block;
    background-color: #14182a;
    border-radius: 2px;
    height: 28px;
    border: transparent;
    padding: 4px 8px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 18px;
    border: 1px solid transparent;
    border-radius: 2px;

    &:focus{
      border-color: #fcad01;
    }

    &:focus-visible{
      outline: none;
    } 
  }
} 

.rangePicker__labels{
  display: flex;
  column-gap: $range-input-field-gap;
  label {
    width: $range-input-field-width;
  }
}

.ant-slider.custom-slider{
  .ant-slider-handle{
    width: 12px;
    background: #FCAD01;
    border-radius: 2px;
    height: 26px;
    border: none;
    margin-top: -12px;
    box-shadow: none;
  }

  .ant-slider-rail{
    height: 2px;
    background-color: #666A8D;
  }

  .ant-slider-track{
    height: 2px;
    background: #FCAD01;
  }

}

.slider__container{
  display: flex;
  align-items: center;
  column-gap: 12px;

  .custom-slider{
    flex: 1;
  }
}

.select-field-option--disabled {
  pointer-events: auto;
  opacity: 0.5;
}

.privateKeyy {
  height: 60px !important;
}

.plainText {
  color: white;
  margin-right: 25px;
  font-size: 12px;
  font-weight: 400;
}