.spark-config-field{
    textarea{
        height: 149px !important;
        width: 420px !important;
    }
}

.addedit-cluster-ui-form {
  .addedit-cluster-inner{
    padding-right: 21px;

    max-height: 755px;
    height: 75vh;
    overflow: auto;
  }

  .modalBtns__box{
    margin-top: 0.5rem;
    padding-right: 0.5rem;

  }

  .select-field, .inputfield__container {
    margin-bottom: 18px;
  }
}

.addedit-cluster-tabs{
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    .checkboxfield__spotinstance{
        margin-bottom: 15px;
    }
    
    .terminate-group{
        margin-top: 18px;
    }

    .autoScaling__checkbox{
        margin-top: 10px;
    }

    .form-right-content {
      border-left: 1px solid  #393E53;;
      margin-left: 35px;
      padding: 0px 0px 0px 30px;
      height: 450px;
    
      .advancedOptions__label{
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.25px;
        margin-bottom: 18px;
        font-weight: 700;
        color: #FFFFFF;
        display: block;
        opacity: 0.32;
    
        &.enabled{
          opacity: 1;
        }
      }

      .advancedOptions__tabs{
        .ant-tabs-bar.ant-tabs-top-bar{
          border-color: #393E53;;
          padding-left: 30px;
          width: 50%;
        }

      }
    }

    .radiofield__inner__container {
      margin-bottom: 10px;
    }

    .form-left-content .details {
        border-bottom: 1px solid #393E53;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .terminate-group {
      display: flex;
      width: 100%;
    
      .radiofield__inner__container{
        margin-bottom: 0;
      }
    
      .inputfield__container {
        margin-bottom: 0;
      }
      .terminate-checkbox {
        width: 53%;
    
        .optionsList li {
          margin-right: 0;
        }
      }
      .terminate-inputfield {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 350px;
        flex-wrap: wrap;

        .inputfield {
          width: 20%;
          margin: -5px 10px 0 0;
          text-align: center;
        }
      }
    }


    .workers-input-group {
      display: flex;
      .min-workers {
        width: 90px;
      }
      .max-workers {
        width: 90px;
        margin-left: 24px;

        .inputfield__errormessage{
          width: 200px;
        }
      }
    }
}

.config-type-text{
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #C7C7CD;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color:#FCAD01;
}
.alphaSlider{
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-bottom: 12px;
}