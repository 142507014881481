.bottomPanelWrapper {
	width: 100%;
	background-color: #14182a;
	position: absolute;
	left: 0;
	z-index: 1;
	bottom: 0;
}

.bottomPanelHeader {
	display: flex;
	padding: 0 24px;
}

.headerTabs {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	height: var(--bottom-panel-height);
	position: relative;
	flex: 1;

	li {
		padding: 0 12px;
		height: var(--bottom-panel-height);
		line-height: var(--bottom-panel-height);
		font-size: 12px;
		text-transform: uppercase;
		color: #c7c7cd;
		font-weight: 700;

		&.selected {
			border-bottom: 2px solid #c7c7cd;
		}
	}
}

.headerActions {
	display: flex;
	gap: 4px;

	button {
		width: var(--bottom-panel-height);
		height: var(--bottom-panel-height);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s;

		&:hover {
			opacity: 0.6;
		}

		&:disabled {
			opacity: 0.32;
			cursor: not-allowed;
		}
	}
}

.tabWrapper {
	padding: 16px;
}

.helpText {
	font-size: 14px;
	color: #c7c7cd;
}
