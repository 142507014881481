.errorPage{
    height: 100vh;
    width: 100vw;
    display: flex;
    margin-left: 0;
    align-items: center;
    justify-content: center;
    background: #030613;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3000;
    overflow: hidden;

    .title{
        margin-top: 16px;
        line-height: 60px;
        font-size: 40px;
        font-weight: 600;
    }

    .subtitle {
        font-size: 21px;
        line-height: 24px;
        letter-spacing: 0.33px;
        opacity: 0.72;
    }

    .title, .subtitle{
        color: #FFFFFF;
        margin-bottom: 12px;

    }

    & ~ * {
        display: none;
    }

    &.underConstructionPage{
        z-index: 0;
    }
}