.model {
    position: absolute;
    bottom: 2rem;
    right: 4rem;
    background-color: #212233;
    width: 20rem;
    height: 20rem;
    padding: 1rem;
    border-radius: 0.3rem;
    z-index: 5001;
    border: 1px solid rgba(0,0,0,0.3);
    &.mini {
        height: 5rem;
    }
}

.label {
    color: white;
    display: flex;
    justify-content: space-between;
}

.getStartedBtn {
    margin-top: 1rem;
    height: 1.6rem;
    color: black;
    left: 0px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.3rem;
    transition: 0.5s background;
    &:hover {
        background: rgba(255, 255, 255, 1);
    }
}

.completed {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
        margin-left: 0.5rem;
    }
}

.rows{
    color: white;
    display: flex;
    justify-content: flex-start;
}
.number_label{
    padding: 1rem;
}

.round{
    border: 1px solid white;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.completed {
        border: 1px solid green;
    }
}

.content{
    padding-top:1.3rem;
}

.text1 {
    font-size: 1rem;
}
.text2 {
    font-size: 0.875rem;
    color: rgba(199, 199, 205, 1)
}

.rotate {
    transform: rotate(180deg);
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .blockview {
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 5rem;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 5000;
  }

  .alignspace {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }