.ReactVirtualized__Grid.dataBrowser__table__dataBox{
    min-height: 70px;
}
.dataSourceBrowser__container{
    display: flex;
    color: #ffffff;
    font-size: 12px;
    height: 100vh;
    overflow: hidden;

    --bottom-panel-height: 36px;

   
    .treeview__container{
        width: var(--dataSourceBrowser__treeview__width);
        .paginationContainer
        &.treeview--minimized {
            width: 0;
        }
    }

    .btn__queryBuilder{
        font-size: 16px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 9px;
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;

        svg{
            width: 14px;
            height: 14px;
            margin-left: 6px;
            transform: rotate(-90deg);
        }
    }
}

.dataSourceBrowser__outer{
    background-color: #030613;
    position: relative;

    .workflow__tabs__container__outer{
        background-color: #2a2c42;
    }
}

.dataSourceBrowser__canvas{
    padding: 24px; 
    flex-grow: 1;
    height: 100vh;

    &.dataSourceBrowser__canvas--with-bottom-tab {
        height: calc(100vh - 36px);
    }

    &.hidePadding{
        padding: 0;
    }
    .innerCanvas{
        background-color: #3E4161;
        border-radius: 8px;
        padding: 16px 24px;
        height: 100%;
    }

    .switchable__tabs__outer{
        width: 146px;
        margin-bottom: 12px;
    }

    .paginationContainer{
        width: auto;
        margin-top: 24px;
    }

    .dataSourceBrowser__header{
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        margin-bottom: 20px;


        .btn-openQueryModal{
            margin-left: 26px;
        }

        p{
            margin-bottom: 0;
        }
    }

    .noStatus__table__title {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.25px;
        position: relative;
        padding-left: 24px;
        display: flex;
        align-items: center;
    }

    .dataSourceBrowser__table__title{
        color: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0.25px;
        position: relative;
        padding-left: 24px;
        display: flex;
        align-items: center;

        --heartbeat-color: #25B100;
        --heartbeat-border-color: #125900;

        &.connection-offline {
            --heartbeat-color: #B01900;
            --heartbeat-border-color: #660009;
        }


        span {
            font-weight: 600;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            border: 4px solid var(--heartbeat-border-color);
            border-radius: 50%;
            background-color: var(--heartbeat-color);
            animation: glow 3s infinite ease-in-out;
            box-sizing: content-box;
        }

        @keyframes glow {
            from {
                border-color: var(--heartbeat-border-color);
            }
            50% {
                border-color: #3E4161;
            }
            to {
                border-color: var(--heartbeat-border-color);
            }
        }
       
    }

    .dataSourceBrowser__header__connectionName{
        color: #FFFFFF;
        font-size: 14px;
        letter-spacing: 0.22px;
        opacity: 0.72; 
        span {
            font-weight: 600;;
        }
    }   
}

.switchable__tabs__outer{
    margin-top: 20px;
    display: flex;
    list-style-type: none;
    padding: 0 12px;
    border-bottom: 1px solid #393E53;
    justify-content: space-between;
    margin-bottom: 0;

    .switchable__tab{
        font-size: 12px;
        // font-weight: 400;
        letter-spacing: 0.25px;
        margin-right: 0;
        margin-left: 0px;
        text-transform: uppercase;

        &:last-of-type{
            margin-right: 0;
        }
    }
}

.connections__treeview{
    .btn__copyConnectionIcon img{
        padding: 5px;
    }
    
    .btn__editConnectionIcon img{
        padding: 2px;
    }

    .btn__deleteConnectionIcon img {
        padding: 5px;
    }

    .search__input__container{
        padding: 12px;
    }
    .newBtn__box{
        display: flex;
        flex-direction: row-reverse;
        padding: 0 12px 12px;
    }

    .treeitem__container{
        padding: 6px 0;
        line-height: 18px;

        &:hover,  &.treeitem__activeContextMenu{
            background-color: #666A8D;
        }

        &.treeitem__connection:hover {
            font-weight: 600;

            .treeviewItem__label{
                width: 115px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        
        &:hover .hoverIcons{
            display: block;
        }

        .hoverIcons{
            position: absolute;
            right: 12px;
            display: none;
            // top: 3px;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            
        }

        &.treeitem__loading{
            padding: 0;
        }


    }

    .checkmark{
        height: 16px;
    }

    .checkbox__container{
        transform: scale(0.66875);
        margin-right: 6px;
    }

    .rstm-tree-item{
        align-items: center;
        position: relative;
        flex-wrap: wrap;

        .nodeStatus{
            width: 10px;
            height: 10px;
            border-radius: 100%;
            margin-right: 8px;

            &.nodeStatus__online{
                background-color: green;
            }

            &.nodeStatus__offline{
                background-color: red;
            }
        }
    }
    .arrow{
        margin-right: 4px;
    }
    .treeviewItem__labels{
        // text-transform: capitalize;
        word-break: break-all;
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
    }


    .treeviewItem__label{
        text-transform: capitalize;
        word-break: break-all;
        width: 75%;
    }


    .connectionsContextMenu{
        background-color: #2A2C42;
        box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.48), 2px 4px 8px 0 rgba(0, 0, 0, 0.32);
        width: 216px;
        
        li, button {
            text-align: left;
            padding-left: 12px;
            padding-right: 12px;
            padding: 6px;
		    height: 24px;

            &:hover{
                background-color: #666A8D;
                color: #FFFFFF;
                font-weight: 600;
            }
        }
    }

    .showRightArrow{
        position: relative;

        &:after{
            display: block;
            content: '\25B6';
            position: absolute;
            color: #FFFFFF;
            top: 7px;
            right: 10%;
        }

        &.active{
            background-color: #666A8D;
            color: #FFFFFF;
            font-weight: 600;
        }
    }


}

.newConnection__modal{
    width: 396px;

    .modal__body{
        padding: 12px 24px 16px;
        padding: 0;
    }


    .newConnection__form{
        & > div:first-of-type {
            padding: 12px 24px 0;

        }
    }

    &.inEditMode{
        .modal__header{
            background-color: rgba(0, 0, 0,  0.239);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px 16px 20px;
    
            img{
                width: 16px;
                height: 16px;
                margin-bottom: 5px;
                margin-right: 4px;
            }
        }

    }

    .innerConnectionForm{
        max-height: 50vh;
        overflow: auto;
        padding: 12px 24px 0;

        .toggleField__container{
            margin-bottom: 18px;
        }

        .configurationSchedule__box{
            margin-left: 0;
            margin-right: 0;
            color: white;

            .configurationSchedule__fields{
                border: 0;
                padding: 0;
            }
        }

    }


    .credentials__box{
        border: 1px solid #6D707E;
        padding: 10px 10px 15px;
        margin-bottom: 20px;
        border-radius: 4px;

        .credentials__label {
            font-weight: 700;
            // font-size: 16px;
            margin-bottom: 10px;
        }
        
        .inputfield__container{
            margin-bottom: 0;
        }
    }
    

    .modal__subtitle {
        color: rgba(255, 255, 255, 0.719);
        font-size: 12px;
    }

    .connectionType{
        margin-bottom: 16px;
        margin-left: 24px;
        margin-right: 24px;
    }

    &.newConnection__modal__old .connectionType{
        margin-left: 0;
        margin-right: 0;
    }

    .horizontalSeparator{
        background-color: #6D707E;
        width: 364px;
        height: 1px;
        margin-bottom: 16px;
        margin-left: 16px;
    }

    .inputfield__container, .select-field {
        margin-bottom: 20px;
    }

    .modalBtns__box{
        margin: 24px 24px 16px;
    }

    .showSpinner{
        .inPageSpinner{
            align-items: center;
            height: 485px;
        }

    }
}

.dataSource__table{
    border: 1px solid #2A2C42;
    user-select: text;

    thead tr {
        background-color: #14182A;
    }

    tr:nth-child(even){
        background-color: #14182A;
    }

    tr {
        border-bottom: 1px solid #2A2C42;

        &:hover{
            background-color: rgba(252, 218, 1, 0.3);
        }
    }

    td, th {
        border-right: 1px solid #2A2C42;
        padding: 6px 8px;
        color: #FFFFFF;
        font-family: 'DM Sans';
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.25px;
        line-height: 14px;
        text-align: left;
        height: 32px;
    }

    th{
        font-weight: 600;
    }
}

.dataBrowser__activeTableInfo{
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search_input{
        height: 34px;
        background-size: 16px;
        background-position: 6px;
    }
}
.queryEditorMaster{
    .editorHeader{
        background-color: #1E2038;
        border-bottom: 2px solid #2A2C42;
        width: 100%;
        height: 27px;
        border-radius: 4px 4px 0 0;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #9495A0;

        button {
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            svg {
                width: 14px;
                height: 14px;
                margin-right: 6px;
            }

            &:first-of-type {
                margin-right: 24px;
            }
        }
        
    }
}


.codeEditor {
    .monaco-scrollable-element {
        & > .scrollbar  > .slider {
            background-color: #D8D8D8 !important;
            border-radius: 3px;
        }
    
        &  > .scrollbar.vertical  > .slider {
            left: auto !important;
            right: 4px;
        }


        .editor-scrollable{
            padding-left: 15px;
        }
    }

    .lines-content.monaco-editor-background{
        margin-left: 20px;
        margin-top: 7px;

    }

    .margin{
        background-color: #1E2038 !important;
        // width: 32px !important;
    }   

    .margin-view-overlays{
        margin-top: 7px;
        width: 38px !important;

        .line-numbers{
            left: auto !important;
            right: 10px !important;
            color: rgba(255, 255, 255, 0.72) !important;
        }
    }

}

.queryBuilderModal__container{
    // height: 580px;
    width: 55vw;
    height: 748px;

    .switchable__tabs__outer{
        width: 220px;
        color: white;
        margin-top: 0px;
        padding-left: 24px;
    }

    .modal__header{
        padding-bottom: 12px;
    }

    .modal__header, .tabs__outer{
        background-color: rgba(0, 0, 0, 0.239);
    }

    .modal__body{
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0;
    }

    .queryBuilderModal__inner{
        padding: 13px 16px 16px;
    }

    .modalBtns__box{
        margin-top: 16px;
    }

    .actionButtons__box{
        margin-top: 16px;
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;

        button:first-child{
            margin-right: 16px;
        }
    }

     

    .queryList__box{
        border: 1px solid #666A8D;
        border-radius: 8px;
        padding: 16px 16px 16px 0;
        height: 270px;

        .switchable__tabs__outer{
            margin-top: 0;
            padding: 0 16px;
            width: 315px;;
        }
    }

    .connectionSelectionForm .select-field{
        margin-bottom: 0;
        padding-bottom: 20px;
        background-color: rgba(0, 0, 0, 0.239);
        padding-left: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
        letter-spacing: 0.22px;
        // line-height: 24px;

        .inputfield__label{
            margin-bottom: 0;
            color: rgba(255, 255, 255, 0.72);
        }

        .select-field-value{
            height: 24px;
            background-color: rgb(32, 34, 50);
            width: auto;
            color: rgba(255, 255, 255, 0.72);
            font-weight: 600;
            border-color: transparent !important;
            text-transform: capitalize;
        }

        .select-field-box {
            width: auto;
        }

    }
}

.uploadFileBox{
    color: white;
}

.databrowserEmptyCanvas{
    height: 100%;
    font-size: 2em;
    background-color: #030613;
	// background-image: url(../../assets/background-pattern.png);
	// background-image: url(../../assets/BG-Dot-Pattern.png);
	background-image: linear-gradient(
			0deg,
			transparent 24%,
			rgba(255, 255, 255, 0.05) 25%,
			rgba(255, 255, 255, 0.05) 26%,
			transparent 27%,
			transparent 74%,
			rgba(255, 255, 255, 0.05) 75%,
			rgba(255, 255, 255, 0.05) 76%,
			transparent 77%,
			transparent
		),
		linear-gradient(
			90deg,
			transparent 24%,
			rgba(255, 255, 255, 0.05) 25%,
			rgba(255, 255, 255, 0.05) 26%,
			transparent 27%,
			transparent 74%,
			rgba(255, 255, 255, 0.05) 75%,
			rgba(255, 255, 255, 0.05) 76%,
			transparent 77%,
			transparent
		);
	background-size: 50px 50px;
}

.newPiRequestModal__container{
    height: auto;

    .modal__body{
        padding: 20px 16px;
    }

    .modal__subtitle{
        margin-top: 12px;
        font-size: 14px;
        letter-spacing: 0.21875px;
        color: #FFFFFF;    
        opacity: 0.72;
        display: block;
    }

    .actionButtons__box{
        margin-bottom: 0;
    }

}

.dataBrowser__table__options{
    display: flex;
    
}

#refreshPiRequests{
    padding-top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .inPageSpinner__XSmall{
        transform: scale(0.8);
    }
}

.ant-empty-description {
	color: white;
}