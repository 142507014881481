.dataexplorer__container {
	margin-left: 0;
	height: calc(100vh - 180px);
	color: #fff;
	display: flex;
	overflow: auto;
	.react-grid-layout {
		position: relative;
		transition: none;
	}
	.grid-graph {
		border-radius: 4px;
		position: relative;
		background-color: #212233;
		margin: 1rem 0;
		width: 28rem;
		height: 20rem;
		padding-bottom: 0.5rem;
		&.full-screen {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 9999;
		}
	}
	button {
		font-size: 12px;
		text-decoration: underline;
		height: 21px;
		letter-spacing: 0.25px;
	}
	.workflowCanvas__header {
		width: 100%;
		left: 0;
		padding-top: 0.5rem;
		padding-left: 0.5rem;
		padding-right: 16px;
		height: 18px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		span {
			color: #ffffff;
			font-size: 12px;
			font-weight: 600;
			letter-spacing: 0.25px;
			line-height: 18px;
		}

		.separator {
			flex: 1;
			margin-left: 16px;
			margin-right: 16px;
			border-top: 1px solid #393e53;
			height: 9px;
			margin-top: 8px;
		}

		img {
			transition: transform 0.25s;
		}
	}

	.innerPlotBox {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.echarts-for-react {
		height: 88% !important;
		margin-bottom: 0.5rem;
	}
	.graphCardIcon {
		margin-right: 12px;
		display: flex;
		align-items: center;

		svg {
			width: 16px;
			height: 16px;
		}

		&:hover {
			.hover-graph-icon {
				fill: #d4d6e0;
			}
		}

		&:last-of-type {
			margin-right: 0;
		}

		&.disabled {
			opacity: 0.56;
		}
	}
}

.right_container {
	// width: 30rem;
	// padding: 2rem 0.75rem;
	background-color: #030613;
	// margin: -3rem;
}

.m-1,
.mt-1 {
	margin-top: 1rem;
}

.page_title {
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 2rem;
	margin-bottom: 0.25rem;
	position: relative;
}
.subtitle {
	color: #c7c7cd;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.5rem;
}

.text {
	font-size: 0.9rem;
}

.bottom {
	position: absolute;
	bottom: 15px;
	z-index: 1000;
	> button {
		width: 28rem;
	}
}

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1.5rem;
	height: 2.25rem;
	font-size: 1rem;
	line-height: 1.25rem;
	background-color: white;
	color: rgba(15, 18, 31, 1);
	border-radius: 0.25rem;
	margin-top: 0.5rem;
	&:hover {
		font-weight: bold;
	}
	&:active {
		font-weight: initial;
	}
}
