.container {
	padding: 16px;
	padding-right: 36px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.title {
		font-size: 12px;
		color: #fff;
	}

	.actions {
		display: flex;
		gap: 8px;

		.action {
			cursor: pointer;
		}
	}
}

.content {
	margin-top: 8px;

	.noteText {
		cursor: pointer;
		font-size: 12px;
		color: #fff;
		opacity: 0.72;
		min-height: 252px;
		transition: 0.3s;
		white-space: break-spaces;

		&:hover {
			opacity: 1;
		}
	}

	.hint {
		color: #fff;
		opacity: 0.56;
		font-size: 12px;
	}
}

.textArea {
	width: 100%;
	background-color: #14182a;
	font-size: 12px;
	resize: none;
	color: #fff;
	border: 1px solid #14182a;
	border-radius: 2px;
	padding: 4px 8px;
	letter-spacing: 0.25px;
	line-height: 18px;
	height: 252px;

	&:focus {
		border-color: #fcad01;
		outline: none;
	}
}

.noteEditorActions {
	display: flex;
	gap: 16px;
	justify-content: flex-end;
	margin-top: 8px;
	align-items: center;
}

.lastSaved {
	font-size: 10px;
	color: rgba(255, 255, 255, .48);

	&.topRight {
		height: 16px;
		float: right;
		margin-bottom: 2px;
		margin-right: 2px;
	}
}

.modal {
	width: 900px;

	.textArea {
		height: 400px;
	}

	.modalSubtitle {
		color: #fff;
		font-size: 10px;
		font-weight: bold;
		opacity: 0.56;
		margin-left: 12px;
	}

	.modalTitle {
		margin-left: 12px;
	}
}

.cancelBtn {
	color: white;
	font-size: 12px;

	&:disabled {
		color: rgba(255, 255, 255, 0.48);
	}
}

.workflowNoteWrapper {
	padding: 16px;
	height: calc(100% - 40px);
	display: none;

	&.visible {
		display: block;
	}

	.noteEditorWrapper {
		height: 100%;

		.textArea {
			height: calc(100% - 40px);
		}
	}

	.noteEditorActions {
		flex-direction: row-reverse;
		justify-content: flex-start;
	}
}