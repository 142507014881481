.piRequest__buttonsContainer {
	display: flex;
	padding: 0 4px;
	align-items: center;
	justify-content: center;
	column-gap: 16px;
}

:global {
	.piRequest_actionBtnIcons {
		gap: 8px;
		display: flex;

		button {
			&:disabled {
				opacity: 0.3;
				cursor: not-allowed;
			}
		}
	}

	.show-on-hover {
		display: none;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	.ReactVirtualized__Table__row:hover {
		.show-on-hover {
			display: flex;
			opacity: 1;
		}
	}
}

.download__btn {
	display: flex;
	align-items: center;
}

@keyframes glowingDot {
	from {
		background-color: rgb(143, 255, 115);
	}

	to {
		background-color: rgba(143, 255, 115, 0.5);
	}
}

.showRunningDot {
	position: relative;
	display: flex;
	align-items: center;

	&::before {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		content: "";
		display: inline-block;
		// position: absolute;
		left: 0;
		top: 8px;
		background-color: #8fff73;
		margin-right: 8px;
		animation: glowingDot 2s infinite forwards;
	}
}

.modalAction__btns {
	display: flex;
	justify-content: flex-end;
	column-gap: 16px;
}

.requestName__field {
	margin-bottom: 16px;
}

.piManaged__filters {
	display: flex;
	justify-content: space-between;
}

.piManaged__actionBtns {
	display: flex;
	column-gap: 10px;
}

.sinkLabel{
	padding: 0 12px;
	height: 24px;
	line-height: 24px;
	padding-left: 6.5px;
	font-weight: bold;
}

.requestState {
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 20px;
	padding: 0 12px;
	height: 24px;
	line-height: 24px;
	padding-left: 28px;
	position: relative;
	font-weight: bold;

	:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 12px;
		transform: translateY(-50%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: currentColor;
	}

	&.NEW {
		color: #ffffff;
	}
	&.SUBMITTED {
		color: #3dbef7;
	}
	&.QUEUED {
		color: #0099f2;
	}
	&.RUNNING {
		color: #0fc981;
	}
	&.FAILED {
		color: #ff9700;
	}
	&.STOPPING {
		color: #ff9a82;
	}
	&.STOPPED {
		color: #ff7766;
	}
	&.SUCCESS {
		color: #15ea83;
	}
	&.RETRYING {
		color: #08c46a;
	}
}

.piTableBtn {
	display: flex;
	width: 86px;
	justify-content: center;
	align-items: center;
	gap: 4px;
}

.piRequestMainActions {
	display: flex;
	margin-bottom: 32px;

	.resultsCount {
		display: flex;
		gap: 8px;
		// font-weight: 700;
		font-size: 14px;
		flex: 1;
		img {
			width: 24px;
			height: 20px;
		}
	}

	.actions {
		display: flex;
		gap: 12px;
		align-items: center;

		button {
			display: flex;
			gap: 4px;
			justify-content: center;
			align-items: center;
		}
	}

	.timeAgo {
		width: 112px;
	}
}

.piRequestFilters {
	display: flex;
	border: 1.5px solid rgba(255, 255, 255, 0.32);
	border-radius: 8px;
	gap: 32px;
	padding: 16px;
	margin-bottom: 16px;

	& > div:first-child {
		flex: 1;
		display: flex;
		flex-direction: column;

		input {
			max-width: 340px;
		}
	}

	.piSelect {
		display: flex;
		flex-direction: column;
		width: 180px;
	}

	.piRequestCustomDateRange {
		display: none;

		&.show {
			display: flex;
		}

		input {
			height: 40px;
			background-position: right 2.5px top 8px;
			border-radius: 4px;
		}
	}

	label {
		margin-bottom: 4px;
	}
}

.piRequestHierarchyForm {
	display: flex;
	border: 1.5px solid rgba(255, 255, 255, 0.32);
	border-radius: 8px;
	gap: 32px;
	padding: 16px;
	align-items: center;
	margin-bottom: 16px;
}


.inputContainerHierarchy {
    display: flex;
    flex-direction: column;
    width: 100%; 
}

.buttonContainerHierarchy {
    display: flex;
	align-items: center;
    width: 100%;
	margin-top: 1rem;
}

.treeContainer{
	width: 100%;
	padding: 1rem;
	height: 74vh;
	padding-bottom: 0rem;
	background: #2a2d43;
	border-radius: 8px;
}
.piSelectStateWrapper {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	.label {
		position: absolute;
		left: 0;
		color: white;
		z-index: 1;
		left: 12px;
	}
}

:global {
	.piRangeSelectItem {
		font-size: 12px;
		align-items: center;
		justify-content: center;
		display: flex;
		height: 40px;
		line-height: 40px;
		padding-top: 0;
		padding-bottom: 0;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(255, 255, 255, 0.08);
		}

		&:last-child {
			&::after {
				display: none;
			}
		}

		&:hover:not(.ant-select-dropdown-menu-item-disabled) {
			background-color: #3e4161;
		}

		&.ant-select-dropdown-menu-item-selected {
			color: #fcad01;
		}
	}


		.hierarchyWorflowSelect:hover .ant-select-selection,
		.hierarchyWorflowSelect .ant-select-selection:focus,
		.hierarchyWorflowSelect .ant-select-selection:active {
			border-color: #fcad01 !important;
		}

	.assetStateSelect {
		.ant-select-selection,
		.ant-select-selection--multiple {
			border: 2px solid #14182a;
			height: 40px;
			font-size: 12px;
			box-shadow: none;

			&:hover,
			&:focus,
			&:active {
				outline: none;
				box-shadow: none;
				border-color: #fcad01;
			}

			.ant-select-arrow {
				transform: translateY(-50%);
				margin-top: 0;
			}
		}
	}

	.assetStateSelect {
		.ant-select-selection,
		.ant-select-selection--multiple {
			border: 2px solid #14182a;
			height: 40px;
			font-size: 18px;
			box-shadow: none;
            align-items: center;
			justify-content: center;
			padding: 3px 15px;
			&:hover,
			&:focus,
			&:active {
				outline: none;
				box-shadow: none;
				border-color: #fcad01;
			}

			.ant-select-arrow {
				transform: translateY(-50%);
				margin-top: 0;
			}
		}
	}

	.assetStateSelectItem,
.ant-select-item {
    font-size: 18px !important;
}

	.piRangeSelect,
	.piStateSelect {
		.ant-select-selection,
		.ant-select-selection--multiple {
			border: 2px solid #14182a;
			height: 40px;
			font-size: 12px;
			box-shadow: none;

			&:hover,
			&:focus,
			&:active {
				outline: none;
				box-shadow: none;
				border-color: #fcad01;
			}

			.ant-select-arrow {
				transform: translateY(-50%);
				margin-top: 0;
			}
		}
	}

	.ant-select-selection {
		.ant-select-selection__rendered {
			display: flex;
		}
	}

	.piStateSelect {
		.ant-select-selection--multiple {
			.ant-select-selection__rendered {
				overflow: scroll;
				margin-left: 0;
				margin-right: 34px;
				padding-left: 4px;

				&::-webkit-scrollbar {
					display: none;
				}

				ul {
					display: flex;

					li {
						height: 28px;
						line-height: 28px;
						font-size: 12px;
						color: white;
						background-color: #3e4161;
						border: none;
						margin-top: 4px;

						i {
							color: white;
						}

						&.ant-select-search--inline {
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	.piStateSelectItem {
		display: flex;
		padding-right: 0;
		padding-left: 32px;

		.ant-select-selected-icon {
			border: 1px solid #c7c7cd;
			border-radius: 2px;
			right: unset !important;
			left: 12px;
			color: #14182a !important;
		}

		&.ant-select-dropdown-menu-item-selected {
			.ant-select-selected-icon {
				background-color: #fcad01;
				border-color: #fcad01;
			}
		}
	}
}

.piRequestTableOuter {
	height: calc(100vh - 425px);
	background-color: #14182A;
}

.dataBrowserHelp {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: white;
	gap: 4px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		opacity: 0.6;
	}
}
.piSelectSelectAll {
	background-color: #14182A;
	display: flex;
	padding: 12px;
	font-weight: bold;
	align-items: center;
	font-size: 12px;
	color: white;
	gap: 8px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);
	cursor: pointer;

	&:hover {
		background-color: #0b0e1c;
	}
}

.piConnectionErrorButton {
	padding-left: 4px;

	&.read {
		filter: grayscale(100%);
	}
}

.labelHierarchyView{
	padding: 4px 4px;
	color: #030613;
	display: flex;
	align-items: center;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	gap: 8px;
	background: grey;
}

.boxHierarchyView{
	border: 1.5px solid rgba(255, 255, 255, 0.32);
	border-radius: 8px;
	width: 67%;
	height: 72vh;
	margin-bottom: 12px;
}

.boxHierarchyTreeView{
	border: 1.5px solid rgba(255, 255, 255, 0.32);
	border-radius: 8px;
	width: 33%;
	height: 72vh;
	margin-bottom: 12px;
}

.dataTableHierarchy {
	:global {
		.ant-table {
			height: 62vh;
			border: none;
			.ant-table-footer {
				position: relative;
				padding: 16px 16px;
				color: rgba(0, 0, 0, 0.85);
				background: #212233;
				border-top: 1px solid #e8e8e8;
				border-radius: 0 0 4px 4px;
			}
			.ant-table-thead > tr > th {
				background: #14182a !important;
				color: #fff;
				border-bottom: 1px solid grey;
				font-weight: 600;
				padding: 12px 16px;
				.ant-table-column-sorter {
					.ant-table-column-sorter-inner {
						.ant-table-column-sorter-up.on,
						.ant-table-column-sorter-down.on {
							color: #fcad01;
						}
					}
				}
				.ant-table-filter-trigger {
					color: #fcad01 !important;
				}
				
			}
			.ant-table-tbody > tr {
				td {
					padding: 12px 16px;
					color: #E1E1E6;
					transition: all 0.3s ease;
				}
				&:hover > td {
					background: #363859 !important;
				}
				&.ant-table-row-selected > td {
					background: #404266;
				}
			}
		}
	}
}

.hierarchyTableFooter{
	display: flex;
	justify-content: flex-end;
	padding: 16px 16px;
	gap:10px;
	color: rgba(0, 0, 0, 0.85);
	background: #212233;
	border-top: 1px solid grey;
	border-radius: 0 0 4px 4px;
}
.hierarchyFormFooter{
	display: flex;
	justify-content: flex-end;
	padding: 16px 16px;
	gap:10px;
	color: rgba(0, 0, 0, 0.85);
	background: #212233;
	border-top: 1px solid grey;
	border-radius: 0 0 4px 4px;
}

.jsonHierarchyForm{
	display: flex;
	flex-direction: column;
	height: 62vh;
	overflow-y: auto;
}

.filter_input__field {
	width: 180px;
	margin-bottom: 16px;
}

.filter_container{
	height: 360px;
	overflow-y: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	padding:10px;
	border:1px solid grey;
}

.tag_container{
	margin-top: 10px;
	margin-bottom: 10px;
	padding:10px;
	border:1px solid grey;
}

.tag_checks_container{
	height: 160px;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 10px;
	margin-bottom: 10px;
}

.checkbox-tag {
	margin-top: 10px;
	:global {
	  .ant-checkbox-wrapper {
		color: #ffffff;
	  }
  
	  .ant-checkbox-inner {
		background: #2a2c42;
		border: 1px solid #c7c7cd;
	  }
  
	  .ant-checkbox-checked .ant-checkbox-inner {
		background: #fcad01;
		border: 1px solid #fcad01;
  
		&::after {
		  border-color: #262231;
		}
	  }
  
	  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
		background-color: #fcad01;
	  }

	  .ant-checkbox + span {
		color: #ffffff !important;
	  }
	}
}

.tag_input {
	background-color: #14182a;
	border: 1px solid transparent;
	border-radius: 3px;
	width: 100%;
	height: 30px;
	padding: 0 10px;
	color: #ffffff;
	&:focus {
		outline: none;
		border: 1px solid #fcad01;
	}
}