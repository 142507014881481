.columnsSelector__box{
    position: absolute;
    // z-index: 1;
    // height: 20px;
    // width: 20px;
    // background-color: #2A2C42;

    .columnsSelector__icon{
        // background-color: #d4d6e0;

        svg {
            width: 20px;
            height: 20px;
            border-radius: 2px;

        }
        width: 20px;
        height: 20px;
        // color: #2A2C42;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // font-size: 20px;
        border-radius: 2px;
    }

    .columnSelector__title{
        font-weight: 600;
        margin-bottom: 7px;
        padding: 0 12px;
        text-transform: uppercase;
    }

    .innerColumnsList__box{
         overflow-y: auto;
        max-height: 300px;
    }

    .columnsList__box {
        list-style-type: none;
        font-size: 12px;
        padding: 7px 0;
        position: absolute;
        right: 16px;
        z-index: 1;
        background-color: #2A2C42;
        width: max-content;
    }

    .columnName__box{
        // margin-bottom: 6px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 24px;
        padding-left: 12px;
        padding-right: 12px;

        &:hover{
            background-color: #666A8D;
            font-weight: 600;
        }


        &.columnName__disabled{
            opacity: 0.5;

            &:hover{
                font-weight: 400;
            }
        }
        // justify-content: center;
    }

    .checkbox__container {
        transform: scale(0.75);
        margin-right: 7px;
    }
}

